import React from "react";

export class DefaultButton extends React.Component {
  noButtonStyle() {
    return {
      backgroundColor: this.props.bgColor,
      borderRadius: "25px",
      fontSize: this.props.fontSize ? this.props.fontSize : "18px",
      fontStyle: "normal",
      fontWeight: "bold",
      height: "46px",
      letterSpacing: "0.9px",
      textTransform: "uppercase",
      width: this.props.width ? this.props.width : "99px",
      color: this.props.bgColor !== "var(--green)" ? "black" : "var(--white_200)",
    };
  }

  yesButtonStyle() {
    return {
      backgroundColor: this.props.bgColor,
      fontSize: this.props.fontSize ? this.props.fontSize : "18px",
      fontStyle: "normal",
      fontWeight: "bold",
      height: "46px",
      letterSpacing: "0.9px",
      textTransform: "uppercase",
      borderRadius: "25px",
      color: this.props.bgColor === "var(--green)" ? "var(--white_200)" : "black",
      width: this.props.width ? this.props.width : "99px",
    };
  }
  render() {
    return (
      <button
        className={this.props.btnText === "YES" ? "btn mt-3" : "btn ml-2 mt-3"}
        onClick={this.props.onClick}
        value={this.props.value ? this.props.value : null}
        name={this.props.name ? this.props.name : null}
        style={this.props.btnText === "YES" ? this.yesButtonStyle() : this.noButtonStyle()}
      >
        {this.props.btnText}
      </button>
    );
  }
}
