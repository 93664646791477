const RiskMaster = [
  {
    key: 'Depression', Low: { min: 0, max:  25}, Moderate: { min: 26, max: 54 }, High: { min: 55, max: 101 }
  },
  {
    key: 'Mania/Hypomania', Low: { min: 0, max: 22 }, Moderate: { min: 23, max: 38 }, High: { min: 39, max: 101 }
  },
  {
    key: 'Alcohol and Substance Abuse', Low: { min: 0, max: 8 }, Moderate: { min: 9, max: 17 }, High: { min: 18, max: 101 }
  },
  {
    key: 'Panic Disorder', Low: { min: 0, max: 6 }, Moderate: { min: 7, max: 22 }, High: { min: 23, max: 101 }
  },
  {
    key: 'Social Anxiety', Low: { min: 0, max: 22 }, Moderate: { min: 23, max: 38 }, High: { min: 39, max: 101 }
  },
  {
    key: 'PTSD', Low: { min: 0, max: 22 }, Moderate: { min: 23, max: 39 }, High: { min: 40, max: 101 }
  },
  {
    key: 'OCD', Low: { min: 0, max: 18 }, Moderate: { min: 19, max: 43 }, High: { min: 44, max: 101 }
  },
  {
    key: 'General Anxiety Disorder', Low: { min: 0, max: 25 }, Moderate: { min: 26, max: 46 }, High: { min: 47, max: 101 }
  },
  {
    key: 'Eating Disorder', Low: { min: 0, max: 19 }, Moderate: { min: 20, max: 29 }, High: { min: 30, max: 101 }
  },
  {
    key: 'ADHD', Low: { min: 0, max: 23 }, Moderate: { min: 24, max: 38 }, High: { min: 39, max: 101 }
  },
  {
    key: 'Anger Problems', Low: { min: 0, max: 8 }, Moderate: { min: 9, max: 29 }, High: { min: 30, max: 101 }
  },
  {
    key: 'Adjustment Disorder', Low: { min: 0, max: 33 }, Moderate: { min: 34, max: 75 }, High: { min: 76, max: 101 }
  },
  {
    key: 'Sleep Disorder (Insomnia)', Low: { min: 0, max: 18 }, Moderate: { min: 19, max: 46 }, High: { min: 47, max: 101 }
  }
];
Number.prototype.between = function (a, b) {
  const min = Math.min.apply(Math, [a, b]);
  const max = Math.max.apply(Math, [a, b]);
  return this >= min && this <= max;
};
const getRiskStatus = (diseasName, score) => {
  score = Math.round(score)
  const { Low, Moderate, High } = (RiskMaster.find((p) => p.key === diseasName) || {})
  if (score.between((Low && Low.min) || 0, (Low && Low.max) || 0)) { 
    return "Low Risk"
  } else if (score.between((Moderate && Moderate.min) || 0, (Moderate && Moderate.max) || 0)) {
    return "Moderate Risk"
  } else if (score.between((High && High.min) || 0, (High && High.max) || 0)) {
    return "High Risk"
  } else {
    return 'Low Risk';
  }
};
export { getRiskStatus }
