import { jsx, css } from "@emotion/react"
import logo from "../../../assets/images/FeelingBetterNowLogo.svg"
import closeX from "../../../assets/images/closeX.png"
// import { withTranslation } from "react-i18next"
import React, { useContext, useState } from "react"
import OutsideClickHandler from "react-outside-click-handler"
import { FormInput } from "../notificationScheduler/NotificationScheduler"
import "../../../css/NotificationScheduler.scss"
import { emailDetails } from "../../printable/PrintableDetails"
import { emailAssessment } from '../../../components/printable/PrintablePAP';
import { Link } from "react-router-dom"
import { StateContext } from "../../../store/state/contexts"
import generatePassword from "../../../utils/generatePassword"
import { makepdf } from "../../../Modules/PAP/PAPMake"
import { makeassessmentpdf } from "../../../Modules/PAP/PAPMakeAssessment"
import logClickEvent from "../../../utils/logClickEvent"
export const ShareAssessment = ({
  onClose,
  userData,
  detailsData,
  assessment_id,
  assessmentDisorders,
  fullHistory,
  shareAssessmentHandler,
  t,
  i18n
}) => {
  const [email, setEmail] = useState("")
  const { state, dispatch } = useContext(StateContext)

  // const t = t;

  const [isEmailValid, setEmailIsValid] = useState(undefined)
  const [isDoctorEmailValid, setIsDoctorEmailValid] = useState(undefined)
  const [showPassword, setshowPassword] = useState(false)
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleShareSend = async () => {
    if (!email || !validateEmail(email)) {
      return setEmailIsValid(false)
    }
    emailAssessment({
      userData,
      email,
      assessment_id,
      detailsData,
    })
    // emailDetails({ userData: userData, email: email, assessment_id })
    onClose()
  }

  return (
    <>
      <div className="modal-background" />

      <div className="modal-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => {
            onClose()
          }}
        >
          <div
            className={"modal-form"}

            style={{
              border:
                isEmailValid === false || isDoctorEmailValid === false
                  ? "2px solid red"
                  : "none",
            }}
          >
            <div className="logo-line">
              <img src={logo} className="logo" alt="" />
              <img
                src={closeX}
                className="close-btn-send-action-plan"
                onClick={onClose}
                alt=""
              />
            </div>
            <div className="modal-scroll">
              {/* <img src={shareActionPlan} className="share-action-plan"/> */}
              <h2 className="share-action-plan">
                {/* Share Assessment Details */}
                {t("summaryPage.shareAssessmentModal.title")}
              </h2>
                {/* You can share the individual responses to your assessment with your doctor or mental health care practioner */}
              {/* <p className="words-above-box">
                {t("summaryPage.shareAssessmentModal.text")}
              </p> */}

              {/* <form className="modal-form-wrapper" onSubmit={(e) => {
                e.preventDefault();
                handleShareSend();
              }}>
                <FormInput
                  type="text"
                  caption={t("summaryPage.shareAssessmentModal.placeHolder")}
                  value={email}
                  onChange={setEmail}
                />

                <button
                  className="send-button-top"
                  style={{}}
                  type="submit"
                  onClick={() => {
                    handleShareSend()
                  }}
                >
                  {t("summaryPage.shareAssessmentModal.sendButton")}
                </button>
              </form> */}

              {isEmailValid === false && (
                <p style={{ color: 'red' }}>
                  {t("summaryPage.shareAssessmentModal.validationMsg")}
                </p>
              )}

              <div className="mt-3">
                <p style={{ fontWeight: '600' }} dangerouslySetInnerHTML={{ __html: t("shareActionPlanPopup.passwordNote1") }}>
                  {/* {t("shareActionPlanPopup.passwordNote1")}<Link style={{ color: '#33ca8f' }} to="/profile">{t("shareActionPlanPopup.profileLink")}</Link>{t("shareActionPlanPopup.passwordNote2")} */}
                </p>
              </div>
              {detailsData && detailsData.user && detailsData.user.pdfPassword &&
                <div className="password-part d-flex align-items-center">
                  <p style={{ fontWeight: '600' }} dangerouslySetInnerHTML={{ __html: t("shareActionPlanPopup.actionPlanDescription")(detailsData && detailsData.user && (showPassword ? detailsData.user.pdfPassword : '*******')) }} />
                  <i className={`fal ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} style={{ fontWeight: "600", marginLeft: 8, marginTop: -6 }} onClick={() => setshowPassword(showPassword => !showPassword)}></i>
                  {/* <button className="submit-btn" style={{ marginLeft: '1rem' }} onClick={() => setshowPassword(showPassword => !showPassword)} >{showPassword ? 'Hide password' : 'Show Password'}</button> */}
                </div>
              }
              <div>
                <button className="submit-btn" style={{ marginRight: 12 }} onClick={() => {
                  let passwordChanged = false;
                  if (!detailsData.user || !detailsData.user.pdfPassword) {
                    let autoGeneratedPassword = generatePassword();
                    detailsData.user.pdfPassword = autoGeneratedPassword;
                    passwordChanged = true;
                  }
                  logClickEvent('Total # Clicks on Print', 10, '')
                  makepdf(
                    detailsData,
                    assessmentDisorders,
                    fullHistory,
                    "download",
                    state,
                    passwordChanged
                  )
                }} >
                  {t("summaryPage.shareAssessmentModal.btn1")}
                </button>
                <button className="submit-btn" style={{ marginRight: 12 }} onClick={async () => {
                  let passwordChanged = false;
                  if(!detailsData.user || !detailsData.user.pdfPassword) {
                    let autoGeneratedPassword = generatePassword();
                    detailsData.user.pdfPassword = autoGeneratedPassword;
                    passwordChanged = true;
                  }
                  console.log(passwordChanged, "<=== this is password changed")
                  const blob = await makeassessmentpdf(detailsData, // needed
                    'assessmentDisorders', // <=== not needed
                    'fullHistory', // <=== not needed
                    "getBlob",  // <=== needed
                    'state', // <== not needed
                    passwordChanged, // <=== needed
                    false // needed but optional
                  );
                  const link = document.createElement("a")
                  link.href = URL.createObjectURL(blob)
                  link.download = "AssessmentDetails.pdf"
                  link.click()
                }} >
                  {t("summaryPage.shareAssessmentModal.btn2")}
                </button>
                <button className="submit-btn" onClick={() => setshowPassword(showPassword => !showPassword)} >
                  {t("summaryPage.shareAssessmentModal.btn3")}
                </button>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

const styles = {
  form: {
    paddingTop: 100,
    paddingBottom: 120,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 15,
  },
  button: {
    padding: "2 10",
  },
}

export default ShareAssessment
