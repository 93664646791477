import React, { useContext, useState, useEffect } from "react"
import { Indepth } from "../Indepth"
import { StateContext } from "../../../store/state/contexts"
import _get from "lodash/get"
const store = require('store');

export default function InDepthTab(props) {
  const { state, answerInDepth } = useContext(StateContext)

  const [inDepthQuestions,setQuestions] = useState(null)
  const [disorder,setDisorder] = useState(null)
  const [prompt, setPrompt] = useState((state?.assessment?.questions?.in_depth_questions?.find(d => d.disorder === disorder)?.prompt || {})[props.lang])

  useEffect(()=> {
    console.log(props.indepthTab)
    try {
      let _inDepthQuestions = state.questionsToShow.questions.in_depth_questions.questions[props.indepthTab-1].questions
      setDisorder(state.questionsToShow.questions.in_depth_questions.questions[props.indepthTab-1].name)
      setQuestions(_inDepthQuestions)
    } catch (err) {}
  },[JSON.stringify(state.questionsToShow),props.indepthTab])
  useEffect(() => {
    // alert('lang changed...')
    let rawPrompt = (state?.assessment?.questions?.in_depth_questions?.find(d => d.disorder === disorder)?.prompt || {})[props.lang]
    setPrompt(rawPrompt)
  }, [props, props.lang, state, JSON.stringify(state.questionsToShow),props.indepthTab, disorder, inDepthQuestions])
  return (
    <div>
      <p className="question mt-3" style={{ fontSize: 18 }}>{prompt}</p>
      {inDepthQuestions &&
        inDepthQuestions.map((data, index) => {
          return (
            <Indepth
              answerInDepth={(choice) => answerInDepth(props.indepthTab - 1, index, choice)}
              disorder={disorder}
              key={data.id}
              id={data.id}
              index={index + 1}
              response={data.response}
              isAnswered={data.isAnswered}
              en={data.en.text}
              fr={data.fr.text}
              lang={props.lang}
              progressBarMethod={props.progressBarMethod}
              onQuestionIsNotPending={props.onQuestionIsNotPending}
              onQuestionIsPending={props.onQuestionIsPending}
              firstUnansweredQuestion={state.firstUnansweredQuestion}
              highlightFirstUnansweredQuestion={
                state.highlightFirstUnansweredQuestion
              }
            />
          )
        })}
    </div>
  )
}
