import { baseUrl } from "../lib/constants";
import axios from "axios";
import { logEvent } from "../index"

const options = {
  withCredentials: true,
  mode: "cors",
};

export const Notification = {
  get() {
    logEvent("Notification get /notifications called");
    return axios.get(`${baseUrl}/notifications`, options);
  },
  post(obj) {
    logEvent("Notification post /notifications called");
    return axios.post(`${baseUrl}/notifications`, obj, options);
  },
  patch(obj) {
    logEvent("Notification patch /notifications called");
    return axios.patch(`${baseUrl}/notifications`, obj, options);
  },
};
