import React from "react"
import BgImg from "../../assets/images/banner1.png"
import fbnLogo from "../../assets/images/FeelingBetterNowLogo.svg"
import { css } from "@emotion/react"

export const ErrorScreen = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <img
        src={BgImg}
        style={{ width: "100%", height: "100%", position: "relative" }}
      />
      <img
        src={fbnLogo}
        style={{ position: "absolute", top: "40px", left: "70px" }}
      />
      <div style={{ position: "absolute", top: "150px", left: "250px" }}>
        <header
          style={{ fontSize: "48", fontWeight: "bold", color: "#23265E" }}
        >
          Something went wrong...
        </header>
        <div>{error.message}</div>
        <button
          style={{
            marginTop: "10px",
            borderColor: "transparent",
            backgroundColor: "transparent",
            fontSize: "20",
            color: "#2db37e",
            fontWeight: 500,
          }}
          onClick={resetErrorBoundary}
        >
          Try again
        </button>
      </div>
    </div>
  )
}
