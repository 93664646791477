import axios from "axios"
import { baseUrl } from "../lib/constants"

export default async (name, category_id, value, type) => {
    try {
        await axios.post(`${baseUrl}/events/click`, {
            name,
            category_id,
            value,
            type
        }, {
            withCredentials: true
        })
    } catch(error) {
        console.error(error)
    }
}