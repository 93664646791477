import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SlidingPane from 'react-sliding-pane';

import 'react-sliding-pane/dist/react-sliding-pane.css';

class ReactSlidingPane extends Component {

constructor(props) {
  super(props);
  this.state = {
    isPaneOpen: false,
  };
}

setIsOpen = (val) => {
  this.setState({
     isPaneOpen: val,
 });
}

render() {
    const { isPaneOpen } = this.state;

    return (
      <div>
        <button
          type="button"
          onClick={() => this.setIsOpen(true)}
          style={{
 display: 'flex', flex: ' 0 0 64px', 'align-items': ' center', background: ' #ebebeb', height: ' 64px', 'border-bottom': ' 1px solid rgba(0, 0, 0, 0.1)', position: 'fixed', bottom: '0px', width: '100%',
}}
        >
          here is open panel
        </button>
        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={isPaneOpen}
          title="Hey, it is title."
          subtitle="Optional subtitle."
          from="bottom"
          width="100%"
          onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                this.setIsOpen(false);
                }}
        >
          <div>And I am pane content. BTW, what rocks?</div>
          <br />
        </SlidingPane>
      </div>
    );
}
}
export default withTranslation()(ReactSlidingPane);
