import React from "react"
import _get from "lodash/get"
import {
  SharedSummaryResponsesList,
  SharedSummaryResponsesBlock,
} from "../../utils/shared"
import { withTranslation } from "react-i18next"


const SummaryHealthHistoryEle = ({ health_history_responses, lang, t }) => (
  <SharedSummaryResponsesList>
    {health_history_responses.map((i, index) => (
      <SharedSummaryResponsesBlock key={i.question[lang].text}>
        <h3>{i.question[lang].text}</h3>

        <p>
          {i.response.length > 0 ? (
            <>
              {i.response
                ?.map((res) => _get(res, [lang]))
                .filter((i) => !!i)
                .join(", ")}
            </>
          ) : (
            t("papPage.none")
          )}
        </p>
      </SharedSummaryResponsesBlock>
    ))}
  </SharedSummaryResponsesList>
)

export const SummaryHealthHistory = withTranslation()(SummaryHealthHistoryEle);
