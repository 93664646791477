import React from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import GreenCheckmarkSVG from "../../assets/images/green-checkmark.svg"
import {
  SharedListElement,
  SharedDisorderBackground,
  SharedDisorderSimpleText,
  SharedDisorderHeaderText,
} from "../../utils/shared"
import { withTranslation } from "react-i18next"
import ReactLinkify from "react-linkify"

export const DescriptionModule = ({
  lang,
  description,
  riskStatus,
  t,
  caption = t('summaryPageDisorder.description'),
}) => {
  return (
  <SharedDisorderBackground id="descripbox">
    <SharedDisorderHeaderText>{caption}</SharedDisorderHeaderText>
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 0.6em;
      `}
    >
      {riskStatus === "High Risk" ? (
        <ReactLinkify>
          <SharedDisorderSimpleText>
            {Array.isArray(description.out.high) ? description.out.high[0].text[lang] : description.out.high.text[lang]}
          </SharedDisorderSimpleText>
        </ReactLinkify>
      ) : (
        <ReactLinkify>
          <SharedDisorderSimpleText>
            {Array.isArray(description.out.med) ? description.out.med[0].text[lang] : description.out.med.text[lang]}
          </SharedDisorderSimpleText> 
        </ReactLinkify>
      )}

      {/* (Array.isArray(description.out.high)) ?
        description.out.high.map((innerList, innerIndex) => (
            (innerList['object']) ? 
              <>
                <div style={{backgroundColor: "white"}}>
                  <div className="descriptionList">
                    <strong>{innerList['object']['title'][lang]}</strong>
                    {innerList['object']['list'].map((innerListParagraph, innerParaIndex) => (
                      <SharedDisorderSimpleText key={`${innerListParagraph.text[lang].substring(0, 8)}d2${innerParaIndex}`}>  
                          {innerListParagraph.text[lang]}
                      </SharedDisorderSimpleText>
                    ))}
                  </div>
                </div>
              </>
            :
              <SharedDisorderSimpleText key={`${innerList.text[lang].substring(0, 8)}d2${innerIndex}`}>
                {innerList.text[lang]}
              </SharedDisorderSimpleText>
          ))
        :
          (description.out.high['object']) ? 
            <SharedDisorderSimpleText key={`${description.out.high.text[lang].substring(0, 8)}`}>
            </SharedDisorderSimpleText>
          :
            <SharedDisorderSimpleText key={`${description.out.med.text[lang].substring(0, 8)}`}>
              {(description.out.high.text) ? description.out.high.text[lang]: ''}
            </SharedDisorderSimpleText>
      ) : (
        
        (Array.isArray(description.out.med)) ?
        description.out.med.map((innerList, innerIndex) => (
            (innerList['object']) ? 
              <>
                <div style={{backgroundColor: "white"}}>
                  <div className="descriptionList">
                    <strong>{innerList['object']['title'][lang]}</strong>
                    {innerList['object']['list'].map((innerListParagraph, innerParaIndex) => (
                      <SharedDisorderSimpleText key={`${innerListParagraph.text[lang].substring(0, 8)}d2${innerParaIndex}`}>  
                          {innerListParagraph.text[lang]}
                      </SharedDisorderSimpleText>
                    ))}
                  </div>
                </div>
              </>
            :
              <SharedDisorderSimpleText key={`${innerList.text[lang].substring(0, 8)}d2${innerIndex}`}>
                {innerList.text[lang]}
              </SharedDisorderSimpleText>
          ))
        :
          (description.out.med['object']) ? 
            <SharedDisorderSimpleText key={`${description.out.med.text[lang].substring(0, 8)}`}>
            </SharedDisorderSimpleText>
          :
            <SharedDisorderSimpleText key={`${description.out.med.text[lang].substring(0, 8)}`}>
              {(description.out.med.text) ? description.out.med.text[lang]: ''}
            </SharedDisorderSimpleText>
      ) */}
    </div>
  </SharedDisorderBackground>
  )
}

export const Description = withTranslation()(DescriptionModule);
