import React, { useState, useRef } from "react"

import { useOutsideClick } from "../../../utils/useOutSideClick"

import { CloseIcon } from "../../../assets/images/close"

import "../../../css/endSessionNotification.scss"

export const EndSessionNotification = ({ closeEndSessionNotification }) => {
  const [isHovered, setHovered] = useState(false)
  const outsideNotification = useRef(null)

  useOutsideClick(outsideNotification, () => {
    closeEndSessionNotification()
  })
  return (
    <div className="end-session-notification" ref={outsideNotification}>
      <button
        className="close_button"
        onClick={() => closeEndSessionNotification()}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <CloseIcon isHovered={isHovered} placement="floating_button" />
      </button>
      <h3 className="end-session-notification__header">
        Unfortunately, your last assessment progress expired. You might start a
        new one.
      </h3>
      <button
        className="end-session-notification__submit"
        onClick={() => closeEndSessionNotification()}
      >
        Okay!
      </button>
    </div>
  )
}
