import React, { Component } from 'react';
import clients from './clientRedirects.json';
import axios from 'axios';
import { baseUrl } from '../lib/constants';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

const recordHits = async (type) => {
  await axios.post(`${baseUrl}/web-visit`, { type });
};

const getConfig = async (env, history) => {
  try {
    let response = await axios.get(`${baseUrl}/config`, { headers: { env } })
    return response.data
  } catch (error) {
    console.log(error)
    toast.error(error?.response?.data?.message || error.message)
    history('/')
  }
}

class OldSiteRedirect extends Component {

  async componentDidMount() {
    var url = this.props.match.params.client;
    localStorage.setItem('tempEnv', url);

    if (url === 'facebook' || url === 'instagram' || url === 'linkedin') {
      await recordHits(url);
    }
    getConfig(url, this.props.navigate)
      .then(data => {
        console.log(data)
        if (data.redirect === true) {
          return window.open(data.url, '_self');
        }

        localStorage.setItem('envConfig', JSON.stringify(data))
        localStorage.setItem('env', url)
        // this.props.navigate('/')
        window.open('/', '_self');
      })
      .catch(error => {
        console.log(error, ' <=== error in function catch')
      })
  }

  render() {
    const { t } = this.props
    return (
      <div className="text-center" style={{ display: 'flex', height: '100vh', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <div
          className="spinner-border assessmentSpinner"
          role="status"
          style={{
            width: '2rem',
            height: '2rem',
            border: '0.25em solid currentColor',
            borderRightColor: 'transparent'
          }}
        ></div>
        <span className="h1 ml-2">{t("summaryPage.loading")}</span>
      </div>
    );
  }
}

export default withTranslation()(OldSiteRedirect)