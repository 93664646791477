import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment';
import Emark from '../../assets/images/emark.png'
import AddChipsIcon from '../../assets/images/add-chips-btn.svg';
import MinusIcon from '../../assets/images/minus.svg';
import Menu from '../../assets/images/menu.png'
import classnames from 'classnames';
import DatePicker from "react-datepicker";
import axios from 'axios'
import { useGoogleLogin } from '@react-oauth/google';
import { baseUrl } from '../../lib/constants';
import ReactLinkify from 'react-linkify';
import RefreshWhite from '../../assets/images/refresh-white.svg'

// const Task = ({ task, goal, goalIndex, taskIndex, planKey, i18n, updateTaskStatus, updateTaskDays, updateGoalLoading, updateTaskType, getTaskDueDate, onDateChange, updateWeekDays, deleteTask, isActive}) => {
const Task = ({ googleLogIn, validateToken, getAllEventsIn, setUpdateGoalLoading, updateTaskEventId, task, allEvents, googleLoggedin, setGoogleLoggedin, goal, goalIndex, taskIndex, planKey, i18n, updateTaskStatus, updateTaskDays, updateGoalLoading, updateTaskTypeNonGoogle, getTaskDueDate, onDateChange, updateWeekDays, deleteTask, isActive, t, LOAD_GOOGLE_CALENDAR }) => {

    const lang = i18n.language;
    const [tooltip, setTooltip] = useState(false);
    const [checked, setChecked] = useState(false);
    const [idList, setIdList] = useState({});
    const [hh, setHH] = useState('')
    const [mm, setMM] = useState('')
    const [ss, setSS] = useState('')
    const [eventSyncInprogress, setEventSyncInprogress] = useState(false)
    const [updateTaskLoading, setUpdateTaskLoading] = useState(false)

    let syncNowBtnRef = useRef()
    let syncNowHourRef = useRef()
    let syncNowMinRef = useRef()
    let syncNowSecRef = useRef()

    let syncNowWeekHourRef = useRef()
    let syncNowWeekMinRef = useRef()
    let syncNowWeekSecRef = useRef()

    const addTooltip = event => {
        setTooltip(current => !current);
    }

    const handleSync = (e, task) => {
        e.preventDefault()
        console.log(task)
        if (googleLoggedin) {
            if (!task.mode || task.mode === "scheduleable") {
                handleScheduleableTask(task)
            }
            if (task.mode === "recurring") {
                handleRecurringTask(task)
            }
        } else {
            googleLogIn()
        }
    }


    useEffect(() => {
        if (goalIndex === 0 && planKey === 'crisisCenter') {
            console.log('task debug: ', planKey, ' ===> ', task, ' =====> ', taskIndex)
        }
    }, [taskIndex])

    useEffect(() => {
        let list = {}
        allEvents.forEach((ele) => {
            list[ele.id] = ele
            // list.push(ele.id)
        })
        setIdList(list)
    }, [allEvents])

    const handleScheduleableTask = (task) => {
        if (task.due_date && hh && mm && ss) {
            let startDate = new Date(task.due_date)
            let endDate = new Date(task.due_date)

            startDate.setHours(parseInt(hh))
            startDate.setMinutes(parseInt(mm))
            startDate.setSeconds(parseInt(ss))

            // endDate.setDate(startDate.getDate())
            endDate.setHours(startDate.getHours() + 1)
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            let event = {
                // "id": task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase(),
                'summary': task.title.en,
                'start': {
                    'dateTime': startDate.toISOString(),
                    'timeZone': timezone
                },
                'end': {
                    'dateTime': endDate.toISOString(),
                    'timeZone': timezone
                }
            }

            console.log(event)
            addEventIn(event)
        } else {
            console.log(`due date not defined`)
        }
    }

    useEffect(() => {
        console.log('hh mm ss debug', task.mode, hh.length)
        if (ss.length > 1) {
            return
        } else if (mm.length > 1) {
            if(!task.mode || task.mode === 'scheduleable') {
                syncNowSecRef && syncNowSecRef.current && syncNowSecRef.current.focus();
            } else {
                syncNowWeekSecRef && syncNowWeekSecRef.current && syncNowWeekSecRef.current.focus();
            }
        } else if (hh.length > 1) {
            if(!task.mode || task.mode === 'scheduleable') {
                syncNowMinRef && syncNowMinRef.current && syncNowMinRef.current.focus();
            } else {
                syncNowWeekMinRef && syncNowWeekMinRef.current && syncNowWeekMinRef.current.focus();
            }
        }
    }, [hh, mm, ss])

    useEffect(() => {
        if (parseInt(hh) > 23) {
            setHH(23)
        }
    }, [hh])

    useEffect(() => {
        if (parseInt(mm) > 59) {
            setMM(59)
        }
    }, [mm])

    useEffect(() => {
        if (parseInt(ss) > 59) {
            setSS(59)
        }
    }, [ss])

    const handleRecurringTask = (task) => {
        if (task.due_date) {
            if (task.week_days.length) {
                console.log('week days present')
                // console.log(task.week_days)
                let startDate = new Date()
                let endDate = new Date()
                let startHours = hh
                let startMinutes = mm
                let startSeconds = ss
                startHours && startDate.setHours(parseInt(startHours))
                startMinutes && startDate.setMinutes(parseInt(startMinutes))
                startSeconds && startDate.setSeconds(parseInt(startSeconds))
                endDate.setDate(startDate.getDate())
                endDate.setHours(startDate.getHours() + 1)
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

                // let until_date = task.due_date
                let BYDAY = [];
                let mapping = {
                    monday: "MO",
                    tuesday: "TU",
                    wednesday: "WE",
                    thursday: "TH",
                    friday: "FR",
                    saturday: "SA",
                    sunday: "SU"
                }
                task.week_days.forEach((day) => {
                    BYDAY.push(mapping[day])
                })

                let event = {
                    // "icalUID": task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase(),
                    'summary': task.title.en,
                    'start': {
                        'dateTime': startDate.toISOString(),
                        'timeZone': timezone
                    },
                    'end': {
                        'dateTime': endDate.toISOString(),
                        'timeZone': timezone
                    },
                    'recurrence': [
                        `RRULE:FREQ=WEEKLY;BYDAY=${BYDAY.join(',')};UNTIL=${moment(task.due_date).format('YYYYMMDD')}`
                    ]
                }
                console.log(event)
                addEventIn(event)
                // console.log(localStorage.getItem('google_access_token'))
            } else {
                console.log('week days not present')
            }
            console.log("due date present")
        } else {
            console.log("due date and task week days not found")
        }
    }

    const addEventIn = async (event) => {
        setUpdateGoalLoading(true)
        setUpdateTaskLoading(true)
        try {
            let res = await axios.post(`https://www.googleapis.com/calendar/v3/calendars/primary/events?access_token=${localStorage.getItem('google_access_token')}`, event)
            await validateToken()
            console.log(res)
            await updateTaskEventId(goalIndex, planKey, taskIndex, res.data.id)
            await getAllEventsIn();
            // onDateChange(new Date(res.data.start.dateTime), goalIndex, planKey, taskIndex)
        } catch (e) {
            console.log(e)
        }
        finally {
            setUpdateGoalLoading(false)
            setUpdateTaskLoading(false)
        }
    }
    const addEventInOne = async () => {
        let event = {
            // "id": task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase(),
            'summary': task.title.en,
            'start': {
                'dateTime': "2022-08-22T09:00:00-07:00",
                'timeZone': 'Asia/Kolkata'
            },
            'end': {
                'dateTime': '2022-08-22T17:00:00-07:00',
                'timeZone': 'Asia/Kolkata'
            }
        }
        // setEventSyncInprogress(true)
        try {
            let res = await axios.post(`https://www.googleapis.com/calendar/v3/calendars/primary/events?access_token=${localStorage.getItem('google_access_token')}`, event)
            // validateToken()
            console.log(res)
        } catch (e) {
            console.log(e)
        }
        finally {
            // setEventSyncInprogress(false)
        }
    }

    const deleteEventIn = async (eventId) => {
        // e.preventDefault()
        try {
            const response = await axios.delete(`https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}?access_token=${localStorage.getItem('google_access_token')}`)
            console.log("event delete : ", response)
        } catch (error) {
            console.log("event delete fail :- ", error)
        }
    }

    const updateEventIn = async (newEvent, eventId) => {
        try {
            const response = await axios.patch(`https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}?access_token=${localStorage.getItem('google_access_token')}`, newEvent)
        } catch (error) {
            console.log('update event error :- ', error)
        }
    }

    const getEvent = async (e, eventId) => {
        e.preventDefault()
        // const eventId = '5improvediet3'
        try {
            const response = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}?access_token=${localStorage.getItem('google_access_token')}`)
            console.log('get event :- ', response)
        } catch (e) {
            console.log('get event error :- ', e)
        }
    }

    const isError = () => {
        if (task.mode === 'scheduleable' && !task.due_date) {
            return true;
        }
        if (task.mode === 'habit' && !task.days) {
            return true;
        }

        if (task.mode === 'recurring' && (!task.week_days || task.week_days.length === 0)) {
            return true;
        }
        return false;
    };

    // const getAllEventsIn = async () => {
    //     try {
    //         await axios.get(`https://www.googleapis.com/calendar/v3/calendars/primary/events?access_token=${localStorage.getItem('google_access_token')}`).then((response) => {
    //             console.log(response)
    //             if (response.status !== 401) {
    //                 console.log(response.data.items)
    //                 console.log(allEvents)
    //                 console.log("get all events success!")
    //             } else {
    //                 console.log("get all events failed! :- ", response)
    //             }
    //         })
    //     } catch (e) {
    //         console.log(e)
    //         alert(e)
    //     }
    // }

    const isTaskValid = () => {
        if (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()]) {
            return true
        }
        switch (task.mode) {
            case 'habit':
                return false
            case 'scheduleable':
                {
                    if (!task.due_date) {
                        return false
                    }
                    if (!hh || !mm || !ss) {
                        return false
                    }
                    return true
                }
            case 'recurring':
                {
                    if (!task.week_days || !task.week_days.length) {
                        return false
                    }
                    if (!task.due_date) {
                        return false
                    }
                    if (!hh || !mm || !ss) {
                        return false
                    }
                    return true
                }
                default: {
                    if (!task.due_date) {
                        console.log("debug task date : due date", task, hh, mm, ss)
                        return false
                    }
                    if (!hh || !mm || !ss) {
                        console.log("debug task date : hh mm ss", task, hh, mm, ss)
                        return false
                    }
                    return true
                }
        }
    }

    const updateTaskType = async (...props) => {
        setUpdateTaskLoading(true)
        let elems = document.getElementsByClassName('inner-lock');
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
        if (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()]) {
            // DELETE TASK FROM GOOGLE
            // await deleteTask()
            setUpdateGoalLoading(true)
            await deleteEventIn(task.eventId)
            await getAllEventsIn()
        }
        await updateTaskTypeNonGoogle(...props)
        setUpdateTaskLoading(false)
    }

    const onDateChangeBKP = onDateChange
    onDateChange = async (...props) => {
        setUpdateTaskLoading(true)
        if (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()]) {
            setUpdateGoalLoading(true)

            let due_date = new Date(task.due_date)
            let startDate = new Date(props[0])
            let endDate = new Date(props[0])

            let newDueDate = moment(task.due_date)
            let newDate = moment(props[0])
            let newStartDate = moment(newDate.format('DD/MM/YYYY') + newDueDate.format(' HH:mm:SS'), 'DD/MM/YYYY HH:mm:SS')
            console.log(newStartDate.format('DD/MM/YYYY HH:mm:SS'))
            // console.log(startDate, due_date.getTime())
            // console.log(due_date)
            // startDate.setTime(due_date.getTime())
            let newEndDate = newStartDate.add(1, 'hour')
            newStartDate.subtract(1, 'hour')
            // endDate.setHours(startDate.getHours() + 1)
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            let patchedEvent = {
                'start': {
                    'dateTime': newStartDate.toDate().toISOString(),
                    'timeZone': timezone
                },
                'end': {
                    'dateTime': newEndDate.toDate().toISOString(),
                    'timeZone': timezone
                }
            }

            await updateEventIn(patchedEvent, task.eventId)
        }
        await onDateChangeBKP(...props)
        setUpdateTaskLoading(false)
    }

    const deleteTaskBKP = deleteTask
    deleteTask = async (...props) => {
        setUpdateTaskLoading(true)
        if (window.confirm('Are you sure you want to delete this task?')) {
            if (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()]) {
                setUpdateGoalLoading(true)
                await deleteEventIn(task.eventId)
            }
            deleteTaskBKP(...props)
            setUpdateTaskLoading(false)
        }
    }

    const updateTaskStatusBKP = updateTaskStatus
    updateTaskStatus = async (...props) => {
        setUpdateTaskLoading(true)
        await updateTaskStatusBKP(...props)
        setUpdateTaskLoading(false)
    }

    const updateWeekDaysBKP = updateWeekDays
    updateWeekDays = async (...props) => {
        setUpdateTaskLoading(true)
        if (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()]) {
            // DELETE TASK FROM GOOGLE AND CREATE NEW USING NEW CONFIG
            // GET DATE FROM THE EXISTING TASK
            // let existingGoogleEvent = idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()]
            setUpdateGoalLoading(true)
            let taskBKP = { ...task }
            if (taskBKP.week_days.includes(props[props.length - 1])) {
                taskBKP.week_days = taskBKP.week_days.filter((d) => d != props[props.length - 1])
            } else {
                taskBKP.week_days = [...taskBKP.week_days, props[props.length - 1]]
                console.log('add flow used')
            }
            if (taskBKP.week_days.length) {
                let BYDAY = [];
                let mapping = {
                    monday: "MO",
                    tuesday: "TU",
                    wednesday: "WE",
                    thursday: "TH",
                    friday: "FR",
                    saturday: "SA",
                    sunday: "SU"
                }
                taskBKP.week_days.forEach((day) => {
                    BYDAY.push(mapping[day])
                })
                let patchedEvent = {
                    'recurrence': [
                        `RRULE:FREQ=WEEKLY;BYDAY=${BYDAY.join(',')};UNTIL=${moment(taskBKP.due_date).format('YYYYMMDD')}`
                    ]
                }
                updateEventIn(patchedEvent, taskBKP.eventId)
            } else {
                await deleteEventIn(taskBKP.eventId)
                getAllEventsIn()
            }
        }

        await updateWeekDaysBKP(...props)
        setUpdateTaskLoading(false)
    }
    

    return (

        <div className='task-emark-part'>
            <div className={`goal-main-content
                ${isError() ? 'error-content-part' : ''}`}>
                <div className={`emark-part ${isActive ? 'tooltip-click-show' : ''}`}>
                    <img src={Emark} className="emark-icon" />
                    <div className='emark-tooltip tooltip-left'>
                        {task.mode !== 'scheduleable' ?
                            t('goalPage.goalTask.emarkTooltip1')
                            :
                            t('goalPage.goalTask.emarkTooltyp2')
                        }
                    </div>
                </div>
                <div className="switch-goal-part">
                    {task.mode !== 'habit' ?
                        <div className={`three-way-toggle  ${isActive ? 'tooltip-click-show' : ''} `}>
                            <div className="toggle-container" data-tut="manage-state-part">
                                <input type="radio" value="theme" name={`checkbox_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} className={`checkbox1 ${task.status == 0 ? 'checkpart' : 'no-check-part'}`} onClick={() => (updateTaskStatus(goalIndex, planKey, taskIndex, 0))} id={`checkbox1_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} checked={task.status == 0} />
                                <label htmlFor={`checkbox1_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} className="checkbox1 pending-label" />
                                <input type="radio" value="theme" name={`checkbox_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`}  className={`checkbox2 ${task.status == 1 ? 'checkpart' : 'no-check-part'}`} onClick={() => updateTaskStatus(goalIndex, planKey, taskIndex, 1)} id={`checkbox2_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} checked={task.status == 1} />
                                <label htmlFor={`checkbox2_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} className="checkbox2" />
                                <input type="radio" value="theme" name={`checkbox_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} className={`checkbox3 ${task.status == 2 ? 'checkpart' : 'no-check-part'}`} onClick={() => updateTaskStatus(goalIndex, planKey, taskIndex, 2)} id={`checkbox3_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} checked={task.status == 2} />
                                <label htmlFor={`checkbox3_${goalIndex}_${planKey}_${taskIndex}_${task.title[lang]}`} className="checkbox" />
                                {task.mode == "recurring" ? <button className="round"><img src={RefreshWhite} alt='refresh-icon' /></button> :  <div className={`round`}/>}
                               
                                <div className={`switch-part-tooltip tooltip-top  ${task.status == 0 ? "pending" : (task.status == 1 ? "inprogress" : "complete")}`} >
                                    {task.status == 0 ?
                                                <p>{t('goalPage.goalTask.status1')}</p>
                                                :
                                                (task.status == 1 ? <p>{t('goalPage.goalTask.status2')}</p> : <p>{t('goalPage.goalTask.status3')}</p>)
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className={`tooltip-part day-part-tooltip ${isActive ? 'tooltip-click-show' : ''}`}>
                            <div className='day-part pending' onClick={() => addTooltip(goalIndex, planKey, taskIndex)}>
                                <h6>{task.process_days || 0}/{task.days || 0}</h6>
                                <div className='emark-tooltip tooltip-left day-tooltip'>Use this to update how many days you have practiced a habit-style task</div>
                            </div>
                            <div className={tooltip ? 'd-block' : 'd-none'}>
                                <div className='incremental-overlay' onClick={() => addTooltip(goalIndex, planKey, taskIndex)}></div>
                            <div className={tooltip ? 'custom-tooltip tooltip-top tooltip-text' : 'd-none'}>
                                <div className='toltip-block'>
                                    <p>{t('goalPage.goalTask.habitBlock1')}</p>
                                    <div className='inc-dec-part'>
                                        <button className='btn dec' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'progress', false)}>
                                            <img src={MinusIcon} />
                                        </button>
                                        <input type='text' className='input-no' placeholder={task.process_days || 0} disabled />
                                        <button className='btn inc' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'progress', true)}><img src={AddChipsIcon} /></button>
                                    </div>
                                </div>

                                <div className='toltip-block'>
                                    <p>{t('goalPage.goalTask.habitBlock2')}</p>
                                    <div className='inc-dec-part'>
                                        <button className='btn dec' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'goal', false)}>
                                            <img src={MinusIcon} />
                                        </button>
                                        <input type='text' className='input-no' placeholder={task.days || 0} disabled />
                                        <button className='btn inc' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'goal', true)}><img src={AddChipsIcon} /></button>
                                    </div>
                                </div>
                                <span className='tooltip-text-habit'>Update how many days you've been able to stick to your habit</span>
                            </div>
                            </div>
                        </div>
                    }

                    <h6 className={classnames({ [`goal-task-text`]: true, 'complete-process': task.status == 2 })}>
                        
                        <ReactLinkify target="blank" componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" className='word-break' href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                            )}>{task.title[lang]}
                        </ReactLinkify>
                        <div className={classnames({ [`task-part-tooltip tooltip-top`]: true, 'tooltip-click-show': isActive })} >
                            <p>This is a task within a plan.  Use the progress indicators on the left to move the task from pending, in-progress to complete.  Complete all of the tasks in this list to earn the coins in the top right of the plan</p>
                        </div>
                    </h6>
                </div>
                <div className="calendar-part">
                    {task.mode !== 'habit' && task.due_date && <p className="date">{moment(task.due_date).format('DD MMMM YYYY')}</p>}
                    {/* {task.mode == "recurring" && <button className="btn refresh-button"></button> } */}
                    {false && LOAD_GOOGLE_CALENDAR &&
                        <div className={`non-schedule-part ${updateTaskLoading ? "loader-position" : ''}  ${isActive ? 'tooltip-click-show' : ''}`}>
                            { updateTaskLoading ? <i class="fas fa-circle-notch fa-spin"></i> : <button 
                                disabled={updateGoalLoading}
                                data-tut="calendar-event-btn"
                                className={`btn z-index non-schedule-button
                                    
                                    ${!googleLoggedin ?
                                        'not-login' :
                                        (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ?
                                            'not-synce'
                                            :
                                            'not-loggedIn'
                                        )}`
                                }
                            // onClick={() => updateTaskType(goalIndex, planKey, taskIndex)}
                            />}
                            <div className={`schedule-part-tooltip tooltip-top  ${task.status == 0 ? "pending" : (task.status == 1 ? "inprogress" : "complete")}`} >
                                {
                                    updateTaskLoading
                                        ?
                                        <p>{t('summaryPage.loading')}</p>
                                        :
                                        <p>
                                            {!googleLoggedin ?
                                                t('goalPage.goalTask.calendarText1') :
                                                (
                                                    idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ?
                                                        t('goalPage.goalTask.calendarText2')
                                                        :
                                                        t('goalPage.goalTask.calendarText3')
                                                )
                                            }
                                        </p>
                                }
                            </div>
                        </div>
                    }
                    <nav className="navbar-expand more-dropdown-part" data-tut="more-btn">
                        <div className="collapse navbar-collapse">
                            <ul className={`navbar-nav`}>
                                <li className="nav-item dropdown more-btn-hover-effect">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={Menu} />
                                    </a>
                                    <div className={`more-btn-hover tooltip-top ${isActive ? 'tooltip-click-show' : ''}`} >
                                        <p>Use this menu to change the task type (Schedulable, Recurring, or Habit), set date information, and publish tasks to your Google Calendar</p>
                                    </div>
                                    <div className="dropdown-menu">

                                        <form className="accordion" id={`ax-example_${goalIndex}_${planKey}_${taskIndex}`}>
                                            {/* <div className='link-part'>
                                                <a
                                                    className={`nav-link ${task.mode !== 'habit' ? 'active' : 'non-active'}`}
                                                    data-toggle="collapse"
                                                    data-target={`#collapse_4_${goalIndex}_${planKey}_${taskIndex}`}
                                                    aria-expanded="true"
                                                    aria-controls={`collapse_4_${goalIndex}_${planKey}_${taskIndex}`}
                                                >
                                                    Sync<i className="fas fa-chevron-down"></i>
                                                </a>
                                                <div id={`collapse_4_${goalIndex}_${planKey}_${taskIndex}`} className="collapse inner-lock" data-parent={`#ax-example_${goalIndex}_${planKey}_${taskIndex}`}>
                                                    <div className='input-part'>
                                                        <DatePicker
                                                            disabled={updateGoalLoading}
                                                            selected={getTaskDueDate(goalIndex, planKey, taskIndex)}
                                                            onChange={(date) => onDateChange(date, goalIndex, planKey, taskIndex)}
                                                            customInput={<input type='text' placeholder='created task date' className='dropdown-input' />}
                                                        />
                                                    </div>
                                                    <div className='input-part time-part'>
                                                        <input type='text' placeholder='hh' value={hh} onChange={e => setHH(e.target.value)} ref={syncNowHourRef} />
                                                        <input type='text' placeholder='mm' value={mm} onChange={e => setMM(e.target.value)} ref={syncNowMinRef} />
                                                        <input type='text' placeholder='ss' value={ss} onChange={e => setSS(e.target.value)} ref={syncNowSecRef} />
                                                    </div>
                                                    {!googleLoggedin ? 
                                                            'not-login' :
                                                        (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ? 
                                                            'synced'
                                                            :
                                                            'not-synced')}
                                                    <button
                                                        ref={syncNowBtnRef}
                                                        onClick={(e) => handleSync(e, task)}
                                                        className={`sync-btn ${googleLoggedin && !isTaskValid() && 'disable'}`}
                                                        disabled={ googleLoggedin && (updateGoalLoading || idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] || !isTaskValid())}
                                                    >
                                                        {
                                                            updateGoalLoading ?
                                                                'Loading...'
                                                                :
                                                                (
                                                                    googleLoggedin === true ?
                                                                        (
                                                                            idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ?
                                                                                'Synced'
                                                                                :
                                                                                'Sync Now'
                                                                        )
                                                                        :
                                                                        `Login`
                                                                )
                                                        }
                                                    </button>
                                                </div>
                                            </div> */}
                                            <div className={`link-part schedule-hover-part ${isActive ? 'tooltip-click-show' : ''}`} data-tut="schedule-more-btn" disabled={updateGoalLoading}>
                                                
                                                <div className='goal-checkbox-part'>
                                                    <div className="custom-control custom-checkbox">
                                                        <input disabled={updateGoalLoading} type="checkbox" className="custom-control-input" id={`customCheck_schedule_${goalIndex}_${planKey}_${taskIndex}`} checked={!task.mode || task.mode === 'scheduleable'} onChange={() => updateTaskType(goalIndex, planKey, taskIndex, 'scheduleable')} />
                                                        <label disabled={updateGoalLoading} className="custom-control-label" for={`customCheck_schedule_${goalIndex}_${planKey}_${taskIndex}`}></label>
                                                    </div>
                                                    <div className='emark-tooltip tooltip-left schedule-dropdown-tooltip'>Select this option it this is a one-half tasks.  Please schedule a date and time</div>
                                                </div>
                                                <a
                                                    className={`nav-link schedule-link ${(!task.mode || task.mode === 'scheduleable') ? 'active' : 'non-active'}`}
                                                    data-toggle="collapse"
                                                    data-target={`#collapse_1_${goalIndex}_${planKey}_${taskIndex}`}
                                                    aria-expanded="true"
                                                    aria-controls={`collapse_1_${goalIndex}_${planKey}_${taskIndex}`}
                                                    disabled={updateGoalLoading}
                                                >
                                                    {t('goalPage.goalTask.dropdownHeading1')}<i className="fas fa-chevron-down"></i>
                                                </a>
                                                <div id={`collapse_1_${goalIndex}_${planKey}_${taskIndex}`} className="collapse inner-lock" data-parent={`#ax-example_${goalIndex}_${planKey}_${taskIndex}`}>
                                                    <div className='input-part'>
                                                        <DatePicker
                                                            disabled={updateGoalLoading}
                                                            selected={getTaskDueDate(goalIndex, planKey, taskIndex)}
                                                            onChange={(date, e) => { onDateChange(date, goalIndex, planKey, taskIndex); e.stopPropagation()}}
                                                            customInput={<input type='text' placeholder={t('goalPage.goalTask.placeHolder')} value={getTaskDueDate(goalIndex, planKey, taskIndex)} className='dropdown-input' />}
                                                            minDate={new Date()}
                                                        // customInput={<button className="btn z-index-0" data-tut="calendar-btn"/>}
                                                        />
                                                    </div>
                                                    {/* <div className='input-part mt-2'>
                                                        <DatePicker
                                                            disabled={updateGoalLoading}
                                                            selected={getTaskDueDate(goalIndex, planKey, taskIndex)}
                                                            onChange={(date) => onDateChange(date, goalIndex, planKey, taskIndex)}
                                                            customInput={<input type='text' placeholder='created task date' className='dropdown-input' />}
                                                        />
                                                    </div> */}
                                                    {LOAD_GOOGLE_CALENDAR &&
                                                    <div className='input-part time-part'>
                                                        <input type='text' placeholder='hh' value={hh} onChange={e => setHH(e.target.value)} ref={syncNowHourRef} />
                                                        <input type='text' placeholder='mm' value={mm} onChange={e => setMM(e.target.value)} ref={syncNowMinRef} />
                                                        <input type='text' placeholder='ss' value={ss} onChange={e => setSS(e.target.value)} ref={syncNowSecRef} />
                                                    </div>
                                                    }
                                                    {/* {!googleLoggedin ? 
                                                            'not-login' :
                                                        (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ? 
                                                            'synced'
                                                            :
                                                            'not-synced')} */}
                                                    {LOAD_GOOGLE_CALENDAR &&
                                                    <button
                                                        ref={syncNowBtnRef}
                                                        onClick={(e) => handleSync(e, task)}
                                                        className={`sync-btn ${googleLoggedin && !isTaskValid() && 'disable'}`}
                                                        disabled={googleLoggedin && (updateGoalLoading || idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] || !isTaskValid())}
                                                        >
                                                        <div className={`sync-part-tooltip tooltip-top ${isActive ? 'tooltip-click-show' : ''}`} >
                                                            <p>Upload your task to your calendar</p>
                                                        </div>
                                                        {
                                                            updateGoalLoading ?
                                                                t('goalPage.goalTask.btnText1')
                                                                :
                                                                (
                                                                    googleLoggedin === true ?
                                                                        (
                                                                            idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ?
                                                                                t('goalPage.goalTask.btnText2')
                                                                                :
                                                                                t('goalPage.goalTask.btnText3')
                                                                        )
                                                                        :
                                                                        t('goalPage.goalTask.btnText4')
                                                                )
                                                        }
                                                    </button>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`link-part schedule-hover-part ${isActive ? 'tooltip-click-show' : ''}`} data-tut="recurring-more-btn" disabled={updateGoalLoading}>
                                            
                                                <div className='goal-checkbox-part'>
                                                    <div className="custom-control custom-checkbox">
                                                        <input disabled={updateGoalLoading} type="checkbox" className="custom-control-input" id={`customCheck_recurring_${goalIndex}_${planKey}_${taskIndex}`} name="example2" checked={task.mode === 'recurring'} onChange={() => updateTaskType(goalIndex, planKey, taskIndex, 'recurring')} />
                                                        <label disabled={updateGoalLoading} className="custom-control-label" for={`customCheck_recurring_${goalIndex}_${planKey}_${taskIndex}`}></label>
                                                    </div>
                                                    <div className='emark-tooltip tooltip-left schedule-dropdown-tooltip'>Select this option if this task recurs over regular intervals.  Please select dates & times</div>
                                                </div>
                                                <a data-toggle="collapse" data-target={`#collapse_2_${goalIndex}_${planKey}_${taskIndex}`} aria-expanded="false" aria-controls={`collapse_2_${goalIndex}_${planKey}_${taskIndex}`} className={`nav-link schedule-link ${task.mode === 'recurring' ? 'active' : 'non-active'}`} disabled={updateGoalLoading}>
                                                    {t('goalPage.goalTask.dropdownHeading2')}<i className="fas fa-chevron-down"></i>
                                                </a>
                                                <div id={`collapse_2_${goalIndex}_${planKey}_${taskIndex}`} className="collapse inner-lock" data-parent={`#ax-example_${goalIndex}_${planKey}_${taskIndex}`}>
                                                    <div className='day-date-part'>
                                                        <p className='day-part'>
                                                            <span onClick={() => updateWeekDays(goalIndex, planKey, taskIndex, 'sunday')} className={classnames("day-name", { "active": task.week_days && task.week_days.includes('sunday') })}>s</span>
                                                            <span onClick={() => updateWeekDays(goalIndex, planKey, taskIndex, 'monday')} className={classnames("day-name", { "active": task.week_days && task.week_days.includes('monday') })}>m</span>
                                                            <span onClick={() => updateWeekDays(goalIndex, planKey, taskIndex, 'tuesday')} className={classnames("day-name", { "active": task.week_days && task.week_days.includes('tuesday') })}>t</span>
                                                            <span onClick={() => updateWeekDays(goalIndex, planKey, taskIndex, 'wednesday')} className={classnames("day-name", { "active": task.week_days && task.week_days.includes('wednesday') })}>w</span>
                                                            <span onClick={() => updateWeekDays(goalIndex, planKey, taskIndex, 'thursday')} className={classnames("day-name", { "active": task.week_days && task.week_days.includes('thursday') })}>t</span>
                                                            <span onClick={() => updateWeekDays(goalIndex, planKey, taskIndex, 'friday')} className={classnames("day-name", { "active": task.week_days && task.week_days.includes('friday') })}>f</span>
                                                            <span onClick={() => updateWeekDays(goalIndex, planKey, taskIndex, 'saturday')} className={classnames("day-name", { "active": task.week_days && task.week_days.includes('saturday') })}>s</span>
                                                        </p>
                                                    </div>
                                                    <div className='input-part mt-2'>
                                                        <DatePicker
                                                            disabled={updateGoalLoading}
                                                            selected={getTaskDueDate(goalIndex, planKey, taskIndex)}
                                                            onChange={(date, e) => {onDateChange(date, goalIndex, planKey, taskIndex); e.stopPropagation()}}
                                                            minDate={new Date()}
                                                            customInput={<input type='text' placeholder={t('goalPage.goalTask.placeHolder')} className='dropdown-input' />}
                                                        />
                                                    </div>
                                                    {LOAD_GOOGLE_CALENDAR &&
                                                    <div className='input-part time-part'>
                                                        <input type='text' placeholder='hh' value={hh} onChange={e => setHH(e.target.value)} ref={syncNowWeekHourRef} />
                                                        <input type='text' placeholder='mm' value={mm} onChange={e => setMM(e.target.value)} ref={syncNowWeekMinRef} />
                                                        <input type='text' placeholder='ss' value={ss} onChange={e => setSS(e.target.value)} ref={syncNowWeekSecRef} />
                                                    </div>
                                                    }
                                                    {/* {!googleLoggedin ? 
                                                            'not-login' :
                                                        (idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ? 
                                                            'synced'
                                                            :
                                                            'not-synced')} */}
                                                    {LOAD_GOOGLE_CALENDAR &&
                                                    <button
                                                        ref={syncNowBtnRef}
                                                        onClick={(e) => handleSync(e, task)}
                                                        className={`sync-btn ${googleLoggedin && !isTaskValid() && 'disable'}`}
                                                        disabled={googleLoggedin && (updateGoalLoading || idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] || !isTaskValid())}
                                                    >
                                                        <div className={`sync-part-tooltip tooltip-top recurring-sync-btn ${isActive ? 'tooltip-click-show' : ''}`} >
                                                            <p>Upload your task to your calendar</p>
                                                        </div>
                                                        {
                                                            updateGoalLoading ?
                                                                t('goalPage.goalTask.btnText1')
                                                                :
                                                                (
                                                                    googleLoggedin === true ?
                                                                        (
                                                                            idList[task.eventId || `${goalIndex}${planKey}${taskIndex}`.toLowerCase()] ?
                                                                                t('goalPage.goalTask.btnText2')
                                                                                :
                                                                                t('goalPage.goalTask.btnText3')
                                                                        )
                                                                        :
                                                                        t('goalPage.goalTask.btnText4')
                                                                )
                                                        }
                                                    </button>}
                                                </div>
                                            </div>
                                            <div className={`link-part schedule-hover-part ${isActive ? 'tooltip-click-show' : ''}`} data-tut="habit-more-btn" disabled={updateGoalLoading}>
                                                <div className='goal-checkbox-part'>
                                                    <div className="custom-control custom-checkbox">
                                                        <input disabled={updateGoalLoading} type="checkbox" className="custom-control-input" id={`customCheck_habit_${goalIndex}_${planKey}_${taskIndex}`} name="example3" checked={task.mode === 'habit'} onChange={() => updateTaskType(goalIndex, planKey, taskIndex, 'habit')} />
                                                        <label disabled={updateGoalLoading} className="custom-control-label" for={`customCheck_habit_${goalIndex}_${planKey}_${taskIndex}`}></label>
                                                        <div className='emark-tooltip tooltip-left schedule-dropdown-tooltip'>Select this option for habits with no specific time associated with it</div>
                                                    </div>
                                                </div>
                                                <a data-toggle="collapse" data-target={`#collapse_3_${goalIndex}_${planKey}_${taskIndex}`} aria-expanded="false" aria-controls={`collapse_3_${goalIndex}_${planKey}_${taskIndex}`} className={`nav-link schedule-link ${task.mode === 'habit' ? 'active' : 'non-active'}`} disabled={updateGoalLoading}>
                                                    {t('goalPage.goalTask.dropdownHeading3')}<i className="fas fa-chevron-down"></i>
                                                </a>
                                                <div id={`collapse_3_${goalIndex}_${planKey}_${taskIndex}`} className="collapse inner-lock" data-parent={`#ax-example_${goalIndex}_${planKey}_${taskIndex}`}>
                                                    <div className='tooltip-top inc-dec-dropdown'>
                                                        <div className="tooltip custom-tooltip">
                                                            <div className='toltip-block'>
                                                                <p>{t('goalPage.goalTask.habitBlock1')}</p>
                                                                <div className='inc-dec-part'>
                                                                    <button className='btn inc' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'progress', true)}><img src={AddChipsIcon} /></button>
                                                                    <input type='text' className='input-no' placeholder={task.process_days || 0} disabled />
                                                                    <button className='btn dec' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'progress', false)}>
                                                                        <img src={MinusIcon} />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className='toltip-block'>
                                                                <p>{t('goalPage.goalTask.habitBlock2')}</p>
                                                                <div className='inc-dec-part'>
                                                                    <button className='btn inc' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'goal', true)}><img src={AddChipsIcon} /></button>
                                                                    <input type='text' className='input-no' placeholder={task.days || 0} disabled />
                                                                    <button className='btn dec' disabled={updateGoalLoading} onClick={() => updateTaskDays(goalIndex, planKey, taskIndex, 'goal', false)}>
                                                                        <img src={MinusIcon} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <span className='tooltip-text-habit'>Update how many days you've been able to stick to your habit</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='link-part'>
                                                <a className="nav-link delete-btn" onClick={() => deleteTask(goal.id, planKey, taskIndex)}>
                                                    {t('goalPage.goalTask.DeleteBtnText')}
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </div>
    )
}

export default Task
