import React, { useEffect, useState, useContext } from "react"
import { useDebounce } from "use-debounce"
import { scroller } from "react-scroll"
import { StateContext } from "../../store/state/contexts"

const Component = ({
  screeningQuestions,
  inDepthQuestions,
  physcialHistory,
  lifeFunctions,
  followUpQuestions,
  onSetTab,
  onSetIndepthTab,
  onSuccessfullScrollToFirstUnansweredQuestion,
  firstUnansweredQuestion,
}) => {
  const [idScrollTo, setIdScrollTo] = useState(null)
  const [myProps, setMyProps] = useState("")
  const [debouncedValue] = useDebounce(myProps, 700)
  const [debouncedScrollTo] = useDebounce(idScrollTo, 200)

  useEffect(() => {
    if (!firstUnansweredQuestion) {
      return
    }
    onSetTab(firstUnansweredQuestion.tab)

    if (firstUnansweredQuestion.subTab) {
      onSetIndepthTab(firstUnansweredQuestion.subTab)
    }

    setIdScrollTo(firstUnansweredQuestion.id)
  }, [debouncedValue])

  useEffect(() => {
    scroller.scrollTo(idScrollTo, {
      smooth: true,
      offset: -100,
      duration: 500,
      delay: 0,
    })

    if (debouncedScrollTo) {
      onSuccessfullScrollToFirstUnansweredQuestion()
    }
  }, [debouncedScrollTo])

  useEffect(() => {
    setMyProps({
      screeningQuestions,
      inDepthQuestions,
      physcialHistory,
      lifeFunctions,
      followUpQuestions
    })
  }, [
    screeningQuestions,
    inDepthQuestions,
    physcialHistory,
    lifeFunctions,
    firstUnansweredQuestion,
    followUpQuestions,
  ])

  return <div />
}

export const FirstUnansweredQuestion = (props) => {
  const { state, dispatch } = useContext(StateContext)

  let screeningQuestions = []
  let inDepthQuestions = []
  let physcialHistory = []
  let lifeFunctions = []
  let followUpQuestions = []

  try {
    screeningQuestions = state.questionsToShow.questions.find(
      (i) => i.name === "screening_questions"
    ).questions
    physcialHistory = state.questionsToShow.questions.find(
      (i) => i.name === "health_history"
    ).questions
    lifeFunctions = state.questionsToShow.questions.find(
      (i) => i.name === "life_functions"
    ).questions
    followUpQuestions = state.questionsToShow.questions.find(
      (i) => i.name === "follow_up_questions"
    ).questions
  } catch (err) {}

  try {
    inDepthQuestions = state.questionsToShow.questions.find(
      (i) => i.name === "in_depth_questions"
    ).questions
  } catch (err) {}

  return (
    <Component
      screeningQuestions={screeningQuestions}
      inDepthQuestions={inDepthQuestions}
      physcialHistory={physcialHistory}
      lifeFunctions={lifeFunctions}
      followUpQuestions={followUpQuestions}
      firstUnansweredQuestion={state.firstUnansweredQuestion}
      {...props}
    />
  )
}
