import React, { useEffect } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import CloseSvg from "../../../assets/images/close.svg"
import { setMobileLinksShowed } from "../../../utils/displayMobileAppLinks"
import { withTranslation } from "react-i18next"

function DownloadApp({ canDelete = true, onClose, t, i18n }) {
  useEffect(() => {
    setMobileLinksShowed(true)
  }, [])

  return (
    <div
      className={
        canDelete === true
          ? "d-flex align-items-center justify-content-center bg-dark"
          : "d-flex align-items-center justify-content-center"
      }
      // style={{ height: "100vh" }}
    >
      <div className="container">
        <div className="card" style={{ borderRadius: "12px" }}>
          <div
            css={css`
              padding: 0.6rem 1.25rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-left: 16px;
              margin-top:16px;
            `}
          >
            <h4 className="card-title">
              <img
                src={require('../../../assets/images/FeelingBetterNowLogo.svg').default}
                alt=""
              />
            </h4>
            {canDelete === true && (
              <Link
                to="/"
                css={css`
                  padding: 5px;
                `}
              >
                <img
                  src={CloseSvg}
                  css={css`
                    width: 20px;
                    height: 20px;
                  `}
                />
              </Link>
            )}
            {canDelete === false && (
                <img
                  onClick={() => onClose()}
                  src={CloseSvg}
                  css={css`
                    width: 20px;
                    height: 20px;
                    margin-right:16px;
                  `}
                />
            )}
          </div>
          <div
            className="card-body"
            style={{ marginLeft: "16px", marginRight: "16px" }}
          >
            <h4
              className="card-text download-title"
              style={{
                fontFamily: "Tiempos Headline",
                fontSize: "36px",
                fontWeight: 400,
                color: "#151852",
              }}
            >
              {t("mobileLinks.title")}
            </h4>
            <p 
              className="download-desc"
              style={{
                // fontFamily: "Circular Std",
                fontStyle: "normal",
                fontWeight: 450,
                fontSize: "18px",
                lineHeight: "28px",
                color: "#50597C",
                marginBottom: "24px",
              }}
            >
              {t("mobileLinks.description")}
            </p>
            <a
              className="download-subtitle"
              href="https://www.feelingbetternow.com/mobile"
              target="_blank"
              style={{
                color: "#33CA8F",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "18px",
              }}
            >
              <u>www.feelingbetternow.com/mobile</u>
            </a>
            <p
              className="download-subtitle"
              style={{
                // fontFamily: "Circular Std",
                fontStyle: "normal",
                fontWeight: 450,
                fontSize: "18px",
                lineHeight: "28px",
                color: "#50597C",
                marginTop: "24px",
              }}
            >
              {t("mobileLinks.shareMessage")}
            </p>
          </div>
          <div
            style={{
              marginLeft: "36px",
              marginRight: "36px",
              marginBottom: "50px",
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div style={{ paddingRight: 12, paddingTop: 12 }}>
                <a href="https://play.google.com/apps/testing/com.mensante.fbn3" target="_blank">
                  <img
                    src={require("../../../assets/images/DownloadAndroidLogo.svg").default}
                    alt=""
                  />
                </a>
              </div>
              <div
                onClick={() => {
                  // window.location.href =
                  //   "http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8"
                }}
                style={{ paddingTop: 12 }}
              >
                <img
                  src={require("../../../assets/images/DownloadiOSLogo.svg").default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(DownloadApp)