import React, { useEffect, useState, useRef } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import _get from "lodash/get"
import { WindowDimentions } from "../../utils/WindowDimentions"
import AngleTopSVG from "../../assets/images/green-angle-top.svg"
import GreenCheckmarkSVG from "../../assets/images/green-checkmark.svg"
import yellowCircle from "../../assets/images/incompleted.png"
import { baseUrl } from "../../lib/constants"
import { Link } from "react-router-dom"
import { AssessmentTimer } from "../../Modules/Assessment/AssessmentTimer.jsx"
import { withTranslation } from "react-i18next"
import '../../css/AssessmentHistory.css'


const EAccordionWrapper = styled.div`
  border-bottom: 1px solid #edebeb;
  overflow: hidden;
  max-height: ${(props) => `${props.maxHeight}px`};
  transition: all 0.2s;
  background-color: #eff9f5;
`

const EAccordionButton = styled.div`
  outline: 0 !important;
  background-color: #fff;
  width: 100%;
  padding: 0.6em 1.2em;
  display: grid;
  grid-template-columns: 20em 1fr 1.8em;
  align-items: center;
  justify-content: space-between;
  border: 0;
  border-bottom: 2px solid #dfefef;
  cursor: pointer;
  font-family: "CircularStdBook", Arial, Helvetica, sans-serif;

  h3 {
    font-size: 1em;
    margin: 0;
    color: #505050;
    display: block;
    font-weight: 500;
  }
`

const ECircle = styled.img`
  width: 1.2em;
  margin-right: 0.6em;
`

const ERiskStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: "CircularStdBook", Arial, Helvetica, sans-serif;

  p {
    font-size: 1em;
    margin: 0;
    color: #505050;
    font-weight: 300;
  }
`

const EAngleComponent = styled.img`
  width: 0.8em;
  justify-self: end;
  filter: ${(props) =>
    props.isOpen ? "unset" : "grayscale(1) brightness(1.25)"};

  transition: all 0.2s;
  transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
`

const ComponentObj = ({
  headerDate,
  children,
  isOpen,
  setOpen,
  isCompleted,
  isInProgress,
  t
}) => {
  const [contentHeight, setContentHeight] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const contentRef = useRef(null)
  const headerRef = useRef(null)

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight)
  }, [_get(contentRef[("current", "clientHeight")])])

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight)
  }, [_get(headerRef[("current", "clientHeight")])])

  return (
    <EAccordionWrapper
      maxHeight={isOpen ? contentHeight + headerHeight : headerHeight}
    >
      <EAccordionButton
        tabIndex={0}
        ref={headerRef}
        onClick={() => {
          setOpen(!isOpen)
        }}
        className="responsive-layout"
        isOpen={isOpen}
      >
        <h3 style={{ textTransform: 'capitalize' }}>{headerDate}</h3>

        {!!isCompleted && (
          <ERiskStatusWrapper>
            <ECircle isCompleted={isCompleted} src={GreenCheckmarkSVG} />

            <p className="">{t("assessmentHistory.complete")}</p>
          </ERiskStatusWrapper>
        )}
        {!isCompleted && (
          <ERiskStatusWrapper>
            <ECircle isCompleted={isCompleted} src={yellowCircle} />

            <p className="">{t("assessmentHistory.inProgress")}</p>
            <AssessmentTimer screen="history" />
          </ERiskStatusWrapper>
        )}

        <EAngleComponent src={AngleTopSVG} isOpen={isOpen}/>
      </EAccordionButton>

      <div ref={contentRef}>{children}</div>
    </EAccordionWrapper>
  )
}

export const AccordionAssessmentsHistory = ({
  headerDate,
  children,
  openByDefault = false,
  isCompleted,
}) => {
  const [isOpen, setOpen] = useState(openByDefault)

  return (
    <WindowDimentions
      render={({ width }) => (
        <Component
          key={`rwhistory${width}${headerDate}`}
          headerDate={headerDate}
          children={children}
          isOpen={isOpen}
          setOpen={setOpen}
          isCompleted={isCompleted}
          isInProgress={false}
        />
      )}
    />
  )
}

const Component = withTranslation()(ComponentObj);

export const AccordionAssessmentsHistoryIncomplete = ({
  headerDate,
  children,
  openByDefault = false,
  isCompleted,
}) => {
  const [isOpen, setOpen] = useState(openByDefault)

  return (
    <WindowDimentions
      render={({ width }) => (
        <Component
          key={`rwhistory${width}${headerDate}`}
          headerDate={headerDate}
          children={children}
          isOpen={isOpen}
          setOpen={setOpen}
          isCompleted={isCompleted}
          isInProgress={true}
        />
      )}
    />
  )
}
