module.exports = {
  home: {
    path: "/",
  },

  DownloadApp: {
    path: "/DownloadApp",
  },

  SupportResources: {
    path: "/SupportResources",
  },
  Education: {
    path: "/Education",
  },

  OldSiteRedirect: {
    path: "/clinadvance",
  },
}
