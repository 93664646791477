const personalInfoReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIRST_NAME":
      return { ...state, firstName: action.payload }
    case "SET_LAST_NAME":
      return { ...state, lastName: action.payload }
    case "SET_GREETING": 
      return { ...state, greetingMsg: action.payload }
    case "SET_GENDER":
      return { ...state, gender: action.payload }
    case "SET_AGE":
      return { ...state, age: action.payload }
    case "SET_PREFERRED_LANGUAGE":
      return { ...state, preferredLanguage: action.payload }
    case "SET_LOCATION":
      return { ...state, location: action.payload }
    case "SET_DOCTOR_MAIL":
      return { ...state, doctorsEmail: action.payload }
    case "SET_EMAIL":
      return { ...state, email: action.payload }
    case "SET_CONTACT":
      return { ...state, contact: action.payload }
    case "SET_PDF_PASSWORD":
      return { ...state, pdfPassword: action.payload }
    case "SET_ACCESS_CODE":
      return { ...state, organizationAccessCode: action.payload }
    case "SET_SINGLE_GENDER":
      return { ...state, singleGender: action.payload }
    case "SET_SINGLE_AGE":
      return { ...state, singleAge: action.payload }
    case "SET_SINGLE_PREFERRED_LANGUAGE":
      return { ...state, singlePreferredLanguage: action.payload }
    case "SET_SINGLE_LOCATION":
      return { ...state, singleLocation: action.payload }
    case "SET_DOCTOR_EMAIL_NOTIFICATIONS":
      return { ...state, autoSend: action.payload }
    case "SET_DOCTOR_ASSESSMENT_EMAIL_NOTIFICATIONS":
      return { ...state, autoSendAssessment: action.payload }
    default:
      return state
  }
}

const securityQuestionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_QUESTION":
      return {
        ...state,
        questionsAndAnswers: state.questionsAndAnswers.map((i) => {
          if (i.name === action.payload.name) {
            return {
              ...i,
              selectedQuestion: action.payload.question,
            }
          }
          return i
        }),
      }
    case "SET_SECURITY_QUESTION_ANSWER":
      return {
        ...state,
        questionsAndAnswers: state.questionsAndAnswers.map((i) => {
          if (i.name === action.payload.name) {
            return {
              ...i,
              answer: action.payload.answer,
            }
          }
          return i
        }),
      }

    default:
      return state
  }
}

const demographicQuestionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_DEMOGRAPHIC_QUESTION":
      console.log(state)
      return {
        ...state,
        questions: state.questions.map((i) => {
          if (i.name === action.payload.name) {
            return {
              ...i,
              answer: action.payload.answer,
            }
          }
          return i
        }),
      }

    default:
      return state
  }
}

const notificationSchedulerReducer = (state, action) => {
  switch (action.type) {
    case "SET_EMAIL_NOTIFICATIONS":
      return { ...state, email: action.payload }
    case "SET_PHONE_NOTIFICATIONS":
      return { ...state, phone: action.payload }
    case "SET_TEXT_NOTIFICATIONS":
      return { ...state, textNotifications: action.payload }
    case "SET_WEEKDAY_NOTIFICATIONS":
      return { ...state, weekday: action.payload }
    case "SET_WEEKS_PER_MONTH":
      return { ...state, weeksPerMonth: action.payload }
    case "SET_SINGLE_WEEKSPERMONTH":
      return { ...state, singleWeeksPerMonth: action.payload }

    default:
      return state
  }
}

export default function appReducer(state, action) {
  return {
    personalInfo: personalInfoReducer(state.personalInfo, action),
    notificationScheduler: notificationSchedulerReducer(
      state.notificationScheduler,
      action
    ),
    securityQuestions: securityQuestionsReducer(
      state.securityQuestions,
      action
    ),

    demographicQuestions: demographicQuestionsReducer(
      state.demographicQuestions,
      action
    ),
  }
}
