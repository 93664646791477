
import React from 'react';
import { useReducer, useEffect } from 'react';
import _get from "lodash/get"
import _flatten from "lodash/flatten"
import _flattenDepth from "lodash/flattenDepth"
import appReducer from './reducers';



export const StateContext = React.createContext()

const initialState = {
    assessment: {
      isLoading: true,
      error: null,
      questions: null,
    },
  
    answerIsPending: false,
  
    assessmentAttempt: {
      isLoading: true,
      error: null,
      questions: null,
    },
  
    questionsToShow: {
      questions: null,
    },
  
    firstUnansweredQuestion: null,
    highlightFirstUnansweredQuestion: false,
    questions: null,
    percentage: {
      main: 0,
      screeningQuestions: 0,
      inDepthQuestions: 0,
      healthHistory: 0,
      lifeFunctions: 0,
      followUp: 0
    },
    auth: {
      isLoggedIn: false,
      authInitialized: false,
      email: null,
    },
    assessmentControls: {
      tab: 1,
    }
  }
  
const StateContextHook = () => {
    const [state, dispatch] = useReducer(appReducer, initialState)
    //PROGRESS BAR
    useEffect(() => {
      let lifeFunctionsList = null
      let lifeFunctionsPercentAnswered = 0
  
      try {
        lifeFunctionsList = _get(state, "questionsToShow.questions.life_functions.questions")
  
        lifeFunctionsPercentAnswered = Math.floor(
          (100 / lifeFunctionsList.length) *
            lifeFunctionsList.filter((i) => i.isAnswered).length
        )
      } catch {}
  
      let physicalHistoryList = null
      let physicalHistoryPercentAnswered = 0
  
      try {
        physicalHistoryList = _get(state, "questionsToShow.questions.health_history.questions")
        physicalHistoryPercentAnswered = Math.floor(
          (100 / physicalHistoryList.length) *
            physicalHistoryList.filter((i) => i.isAnswered).length
        )
      } catch {}

      let followUpList = null
      let followUpPercentAnswered = 0
  
      try {
        followUpList = _get(state, "questionsToShow.questions.follow_up_questions.questions")
        followUpPercentAnswered = Math.floor(
          (100 / (followUpList.length || 0)) *
            (followUpList.filter((i) => i.isAnswered).length || 0)
        )
      } catch {}

      let screeningQestionsList = null
      let screeningQestionsPercentAnswered = 0
  
      try {
        screeningQestionsList = _get(state, "questionsToShow.questions.screening_questions.questions")
  
        screeningQestionsPercentAnswered = Math.floor(
          (100 / screeningQestionsList.length) *
            screeningQestionsList.filter((i) => i.isAnswered).length
        )
      } catch {}
  
      let inDepthQuestionList = null
      let inDepthQuestionPercentAnswered = 0
  
      try {
        inDepthQuestionList = _get(state,"questionsToShow.questions.in_depth_questions")
          .ids.map((i) => state.questions[i])
        inDepthQuestionPercentAnswered = Math.floor(
          (100 / inDepthQuestionList.length) *
            inDepthQuestionList.filter((i) => i.isAnswered).length
        )
  
        if (
          (state.questionsToShow &&
            state.questionsToShow.questions &&
            state.questionsToShow.questions.in_depth_questions &&
            state.questionsToShow.questions.in_depth_questions.questions.length === 0 &&
            state.firstUnansweredQuestion &&
            (state.firstUnansweredQuestion.tab === 3 ||
              state.firstUnansweredQuestion.tab === 4)) ||
          !state.firstUnansweredQuestion ||
          state.firstUnansweredQuestion.tab === 3 ||
          state.firstUnansweredQuestion.tab === 4
        ) {
          inDepthQuestionPercentAnswered = 100
        }
  
        if (isNaN(inDepthQuestionPercentAnswered)) {
          inDepthQuestionPercentAnswered = 0
        }
      } catch (err) {
        inDepthQuestionPercentAnswered = 0
      }
      
      let allQuestionsList = null
      let mainProgressPercentage = 0
      
      try {
        const { historyData } = state
        allQuestionsList = Object.keys(state.questions).map(i=>state.questions[i])
        try {
          allQuestionsList = Object.keys(state.questions).map(i=>state.questions[i]).filter(i => {
            return historyData && historyData.length > 0 ? true : !(i.id.startsWith('v1fuq'))
          })
        } catch (error) {
          console.error('all que list and state', error)
        }

        mainProgressPercentage = Math.floor(
          (100 / allQuestionsList.length) *
          allQuestionsList.filter((i) => i.isAnswered).length
        )
      } catch {}
      const tabs = {
        v1s:1,
        v1_:2,
        hh_:3,
        lf_:4,
        v1f:5
      }
      if(allQuestionsList){
        allQuestionsList = allQuestionsList.map(q => {
          if(tabs[q.id.slice(0, 3)] === 5){
            let followUpQuestion = state.questionsToShow.questions.follow_up_questions.questions.find(fq => fq.id === q.id)
            if(followUpQuestion){
              return { ...q, ...followUpQuestion }
            } else {
              return q
            }
          } else {
            return q
          }
        })
      }
      
      if(allQuestionsList) {
        let allQuestionsListReverse = []
        for(let i = allQuestionsList.length - 1; i >= 0; i--){
          allQuestionsListReverse.push(allQuestionsList[i])
        }
        var lastQuestion = allQuestionsList[allQuestionsList.length - 1]
        var lastAnswered = allQuestionsListReverse.find(i=>i.isAnswered)
        var firstUnanswered = allQuestionsList.find(i=>!i.isAnswered)
        if(firstUnanswered!==undefined){
          firstUnanswered.tab = tabs[firstUnanswered.id.slice(0,3)]
          if (firstUnanswered.tab===2) {
            var currDisorder = null
            var subTab = 1
            for(let i of inDepthQuestionList) {
              if(i.id.slice(3,6)!==currDisorder){
                currDisorder = i.id.slice(3,6)
                subTab = subTab + 1
              }
              if(!i.isAnswered) {
                firstUnanswered.subTab = subTab - 1
                break
              }
            }
          }
          var nextIndex = null
          var lastIndex = null 
          if(firstUnanswered.tab === 5 && lastQuestion.id !== lastAnswered.id){
            if(lastAnswered.response && Array.isArray(lastAnswered.response)){
              var nextId = null
              if(lastAnswered.response.find(f => f.en === 'no')){
                nextId = lastAnswered.next.no
              } else {
                nextId = lastAnswered.next.yes
              }

              allQuestionsList.map((q, index) => {
                if(lastAnswered.id == q.id) {
                  lastIndex = index
                }
                if(q.id == nextId) {
                  nextIndex = index
                }
              })

              if(nextId){
                allQuestionsList = allQuestionsList.map((q, index) => {
                  if(index > lastIndex && index < nextIndex && (q.question_type == 'free_text' || q.question_type == 'boolean')) {
                    q.isAnswered = true
                    q.response = [
                      {
                        en: 'no',
                        fr: 'no'
                      }
                    ]
                  }
                  return q
                })
                firstUnanswered = allQuestionsList.find(q => q.id === nextId)
                firstUnanswered.tab = tabs[firstUnanswered.id.slice(0,3)]
              }
              // console.log('answer follow up', allQuestionsList, lastIndex, nextIndex)
            }


            try {
              const questionsList = _flattenDepth(
                [
                  state.questionsToShow.questions.screening_questions.questions,
                  state.questionsToShow.questions.in_depth_questions.questions.map((i) => i.questions),
                  state.questionsToShow.questions.health_history.questions,
                  state.questionsToShow.questions.life_functions.questions,
                  state.questionsToShow.questions.follow_up_questions.questions
                ],
                [2]
              )

              // console.log('answer follow up : que list', questionsList, lastIndex, nextIndex, lastAnswered)
        
              const questionsentity = questionsList
                .map((i) => i.id)
                .reduce((accState, key) => {
                  accState[key] = questionsList.find((i) => i.id === key)
                  return accState
                }, {})
              dispatch({
                type: "SET_QUESTIONS",
                payload: questionsentity,
              })
            } catch (err) {
              console.log({ err })
            }



          }
          dispatch({
            type: "SET_FIRST_UNANSWERED_QUESTION",
            payload: lastQuestion.id !== lastAnswered?.id ? firstUnanswered : null,
          })
        }
      }

      dispatch({
        type: "SET_PERCENTAGE",
        payload: {
          main: mainProgressPercentage,
          screeningQuestions: screeningQestionsPercentAnswered,
          inDepthQuestions: inDepthQuestionPercentAnswered,
          healthHistory: physicalHistoryPercentAnswered,
          lifeFunctions: lifeFunctionsPercentAnswered,
          followUp: followUpPercentAnswered
        },
      })
    }, [
      JSON.stringify(state.questionsToShow),
      JSON.stringify(state.questions),
      JSON.stringify(state.firstUnansweredQuestion),
      state.assessmentAttempt.isLoading,
    ])

    //ASSESSMENT LOADING

    useEffect(() => {
      const noAssessmentYet = !_get(state, [
        "assessment",
        "questions",
        "screening_questions",
        "questions",
      ])
  
      if (
        noAssessmentYet ||
        state.assessment.isLoading ||
        state.assessmentAttempt.isLoading
      ) {
        return
      }
  
      let unansweredQuestion = []
      let unansweredQuestionPosition = []
  
      const responsesAvailable = _flatten(
        Object.keys(state.assessmentAttempt.questions).reduce((accState, key) => {
          return [...accState, state.assessmentAttempt.questions[key]]
        }, [])
      ).filter((i) => _get(i, "id"))
  
      const screening_question_disordersList =
        _get(state, [
          "assessmentAttempt",
          "questions",
          "screening_question_disorders",
        ]) || []
        
      const questionsToShow = {
        screening_questions:{
          ids: state.assessment.questions.screening_questions.questions.map(
            (i) => i.id
          ),
          questions: state.assessment.questions.screening_questions.questions.map(
            (i) => {
              const response = responsesAvailable.find((j) => j.id === i.id)
                ? responsesAvailable.find((j) => j.id === i.id).response
                : null
              const isAnswered = !!responsesAvailable.find((j) => j.id === i.id)
              if (!isAnswered) {
                unansweredQuestion = [...unansweredQuestion, { ...i }]
                unansweredQuestionPosition = [
                  ...unansweredQuestionPosition,
                  { tab: 1, subtab: null },
                ]
              }
  
              return { ...i, response, isAnswered }
            }
          ),
        },
        in_depth_questions:{
          ids: _flatten(
            state.assessment.questions.in_depth_questions
              .map((i) => i.disorder)
              .filter((i) => screening_question_disordersList.includes(i))
              .map((i) =>
                state.assessment.questions.in_depth_questions
                  .find((j) => j.disorder === i)
                  .questions.map((j) => j.id)
              )
          ),
  
          questions: state.assessment.questions.in_depth_questions
            .map((i) => i.disorder)
            .filter((i) => screening_question_disordersList.includes(i))
            .map((i, index) => ({
              name: i,
              questions: state.assessment.questions.in_depth_questions
                .find((j) => j.disorder === i)
                .questions.map((i) => {
                  const response = responsesAvailable.find((j) => j.id === i.id)
                    ? responsesAvailable.find((j) => j.id === i.id).response
                    : null
  
                  const isAnswered = !!responsesAvailable.find(
                    (j) => j.id === i.id
                  )
                  if (!isAnswered) {
                    unansweredQuestion = [...unansweredQuestion, { ...i }]
  
                    unansweredQuestionPosition = [
                      ...unansweredQuestionPosition,
                      { tab: 2, subTab: 1 + index },
                    ]
                  }
                  return { ...i, response, isAnswered }
                }),
            })),
        },
        health_history:{
          ids: state.assessment.questions.health_history.questions.map(
            (i) => i.id
          ),
          questions: state.assessment.questions.health_history.questions.map(
            (i) => {
              const response = responsesAvailable.find((j) => j.id === i.id)
                ? responsesAvailable.find((j) => j.id === i.id).response
                : null
  
              const isAnswered = !!responsesAvailable.find((j) => j.id === i.id)
              if (!isAnswered) {
                unansweredQuestion = [...unansweredQuestion, { ...i }]
                unansweredQuestionPosition = [
                  ...unansweredQuestionPosition,
                  { tab: 3, subtab: null },
                ]
              }
              return { ...i, response, isAnswered }
            }
          ),
        },
        follow_up_questions:{
          ids: state.assessment.questions.follow_up_questions.questions.map(
            (i) => i.id
          ) ,
          questions: state.assessment.questions.follow_up_questions.questions.map(
            (i, index) => {
              const response = responsesAvailable.find((j) => j.id === i.id)
                ? responsesAvailable.find((j) => j.id === i.id).response
                : null
  
              const isAnswered = !!responsesAvailable.find((j) => j.id === i.id)
              if (!isAnswered) {
                unansweredQuestion = [...unansweredQuestion, { ...i, index }]
                unansweredQuestionPosition = [
                  ...unansweredQuestionPosition,
                  { tab: 5, subtab: null, id: i.id },
                ]
              }
              return { ...i, response, isAnswered, disabled: !isAnswered }
            }
          ),
        },
        life_functions:{
          ids: state.assessment.questions.life_functions.questions.map(
            (i) => i.id
          ),
          questions: state.assessment.questions.life_functions.questions.map(
            (i) => {
              const response = responsesAvailable.find((j) => j.id === i.id)
                ? responsesAvailable.find((j) => j.id === i.id).response
                : null
  
              const isAnswered = !!responsesAvailable.find((j) => j.id === i.id)
              if (!isAnswered) {
                unansweredQuestion = [...unansweredQuestion, { ...i }]
                unansweredQuestionPosition = [
                  ...unansweredQuestionPosition,
                  { tab: 4, subtab: null },
                ]
              }
              return { ...i, response, isAnswered }
            }
          ),
        },
      }

      let isLastAnswered = questionsToShow.follow_up_questions.questions[questionsToShow.follow_up_questions.questions.length - 1].isAnswered

      let nextId
      let nextIndex
      let lastIndex
      if(unansweredQuestion[0]?.index > 0){
        const lastAnswered = questionsToShow.follow_up_questions.questions[unansweredQuestion[0].index - 1]
        if(lastAnswered.response && Array.isArray(lastAnswered.response)){
          if(lastAnswered.response.find(r => r.en == 'no')){
            nextId = lastAnswered.next.no
          } else {
            nextId = lastAnswered.next.yes
          }

          questionsToShow.follow_up_questions.questions.map((q, index) => {
            if(lastAnswered.id == q.id) {
              lastIndex = index
            }
            if(q.id == nextId) {
              nextIndex = index
            }
          })

          questionsToShow.follow_up_questions.questions = questionsToShow.follow_up_questions.questions.map((q, index) => {
            if(index > lastIndex && index < nextIndex && (q.question_type == 'free_text' || q.question_type == 'boolean')) {
              q.isAnswered = true
              q.response = [
                {
                  en: 'no',
                  fr: 'no'
                }
              ]
            }
            if(q.id === nextId){
              return { ...q, disabled: false }
            } else {
              return q
            }
          })
        }
      }
  
      dispatch({
        type: "SET_QUESTIONS_TO_SHOW",
        payload: questionsToShow,
      })
  
      try {
        const questionsList = _flattenDepth(
          [
            questionsToShow.screening_questions.questions,
            questionsToShow.in_depth_questions.questions.map((i) => i.questions),
            questionsToShow.health_history.questions,
            questionsToShow.life_functions.questions,
            questionsToShow.follow_up_questions.questions
          ],
          [2]
        )
  
        const questionsentity = questionsList
          .map((i) => i.id)
          .reduce((accState, key) => {
            accState[key] = questionsList.find((i) => i.id === key)
            return accState
          }, {})
        dispatch({
          type: "SET_QUESTIONS",
          payload: questionsentity,
        })
      } catch (err) {
        console.log({ err })
      }
      if(nextId){
        var firstUnansweredQuestion = {
          ...unansweredQuestion.find(u => u.id === nextId),
          ...unansweredQuestionPosition.find(u => u.id === nextId),
          disabled: false
        }
      } else {
        var firstUnansweredQuestion = {
          ...unansweredQuestion[0],
          ...unansweredQuestionPosition[0],
          disabled: false
        }
      }
  
      if (!Object.keys(firstUnansweredQuestion).length || isLastAnswered) {
        firstUnansweredQuestion = null
      }
  
      dispatch({
        type: "SET_FIRST_UNANSWERED_QUESTION",
        payload: firstUnansweredQuestion,
      })
    }, [
      state.assessment.isLoading,
      state.assessmentAttempt.isLoading,
      JSON.stringify(state.assessment.questions),
      JSON.stringify(state.assessmentAttempt.questions),
    ])

    const reset = () => {
      dispatch({type:"RESET",defaultState: initialState})
    }

    const answerScreening = (i,choice) => {
      var _questions = state.questionsToShow.questions
      _questions.screening_questions.questions[i].isAnswered=true;
      _questions.screening_questions.questions[i].response=choice;
      dispatch({
        type: "SET_QUESTIONS_TO_SHOW",
        payload: _questions,
      })
    }

    const answerInDepth = (i,j,choice) => {
      var _questions = state.questionsToShow.questions
      _questions.in_depth_questions.questions[i].questions[j].isAnswered=true;
      _questions.in_depth_questions.questions[i].questions[j].response=choice;
      dispatch({
        type: "SET_QUESTIONS_TO_SHOW",
        payload: _questions,
      })
    }

    const answerHealth = (i,choice) => {
      var _questions = state.questionsToShow.questions
      _questions.health_history.questions[i].isAnswered=true;
      _questions.health_history.questions[i].response=choice;
      dispatch({
        type: "SET_QUESTIONS_TO_SHOW",
        payload: _questions,
      })
    }

    const answerFollowUp = (i,choice, flag) => {
      // console.log('answer follow up', i , choice, flag)

      let no = [
        {
          en: 'no',
          fr: 'no'
        }
      ]

      var _questions = state.questionsToShow.questions
      _questions.follow_up_questions.questions[i].isAnswered=true;
      _questions.follow_up_questions.questions[i].response=choice;

      if(flag === 'yes'){
        let nextId = _questions.follow_up_questions.questions[i].next.yes
        _questions.follow_up_questions.questions = _questions.follow_up_questions.questions.map(q => q.id === nextId ? {...q, disabled: false } : {...q, disabled: true})
        
      } else {
        let nextId = _questions.follow_up_questions.questions[i].next.no
        let nextIndex = 0
        _questions.follow_up_questions.questions.map((q, index) => {
          if(q.id === nextId ) {
            nextIndex = index
          }
        })

        _questions.follow_up_questions.questions = _questions.follow_up_questions.questions.map((q, index) => {
          if(index > i && (q.question_type == 'free_text' || q.question_type == 'boolean') && index < nextIndex) {
            q.isAnswered = true
            q.response = no
          }
          return q.id === nextId ? {...q, disabled: false } : {...q, disabled: true}
        })
        
      }

      console.log(_questions, ' <=== i am questions...')

      dispatch({
        type: "SET_QUESTIONS_TO_SHOW",
        payload: _questions,
      })
    }

    const answerLife = (i,choice) => {
      var _questions = state.questionsToShow.questions
      _questions.life_functions.questions[i].isAnswered=true;
      _questions.life_functions.questions[i].response=choice;
      console.log(_questions.life_functions.questions)
      dispatch({
        type: "SET_QUESTIONS_TO_SHOW",
        payload: _questions,
      })
    }

    return {state, dispatch, reset, answerScreening, answerInDepth, answerHealth, answerFollowUp, answerLife}
}

export default StateContextHook;