import React from "react"

export const SendLogo = ({ isSent }) => (
  <svg
    fill={isSent ? "rgb(60, 160, 138)" : "#9e9e9e"}
    version="1.1"
    id="Layaser_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 507.9 507.9"
    style={{ enableBackground: "new 0 0 507.9 507.9;" }}
    xmlSpace="preserve"
    width="55px"
  >
    <g>
      <g>
        <path
          d="M445.299,73.5h-74.8V14.1c0-7.8-6.3-14.1-14.1-14.1h-293.7c-7.8,0-14.1,6.3-14.1,14.1v390.8c0,7.8,6.3,14.1,14.1,14.1
           h59.3v74.8c0,7.8,6.3,14.1,14.1,14.1h213.3c3.7,0,7.3-1.5,10-4.1l95.8-95.8c2.6-2.6,4.1-6.2,4.1-10V87.6
           C459.399,79.8,453.099,73.5,445.299,73.5z M121.999,87.6v303.2h-45.2V28.2h265.5v45.3h-206.1
           C128.399,73.5,121.999,79.8,121.999,87.6z M363.599,459.8v-47.7h47.7L363.599,459.8z M431.199,383.9h-81.7
           c-7.8,0-14.1,6.3-14.1,14.1v81.7h-185.1v-378h280.9V383.9z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M385.199,151h-188.9c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1h188.9c7.8,0,14.1-6.3,14.1-14.1
           S392.999,151,385.199,151z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M385.199,229.9h-188.9c-7.8,0-14.1,6.3-14.1,14.1s6.3,14.1,14.1,14.1h188.9c7.8,0,14.1-6.3,14.1-14.1
           S392.999,229.9,385.199,229.9z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M385.199,308.7h-188.9c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1h188.9c7.8,0,14.1-6.3,14.1-14.1
           S392.999,308.7,385.199,308.7z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M283.199,387.5h-86.9c-7.8,0-14.1,6.3-14.1,14.1s6.3,14.1,14.1,14.1h86.9c7.8,0,14.1-6.3,14.1-14.1
           C297.299,393.8,290.999,387.5,283.199,387.5z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)
