import React, { useContext, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const ESwitcherWrapper = styled.button`
  align-items: center;
  background-color: ${(props) => (props.enabled ? "#33CA8F" : "#D8D8D8")};
  border-radius: 24px;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 30px;
  outline: none;
  padding: 2px;
  transition: all 0.1s;
  width: 56px;

  :focus {
    outline: none;
  }
`

const ECircleSwitcher = styled.div`
  background: #fff;
  border-radius: 100%;
  height: 18px;
  margin-left: ${(props) => (props.enabled ? "28px" : "3px")};
  transition: all 0.3s;
  width: 18px;
`

export const Switcher = ({ enabled = false, onChange }) => (
  <ESwitcherWrapper
    enabled={enabled}
    onClick={() => {
      onChange(!enabled)
    }}
  >
    <ECircleSwitcher enabled={enabled} />
  </ESwitcherWrapper>
)
