import React from "react"
import {
  Canvas,
  pdf,
  Page,
  Text,
  Link,
  Font,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import { sendPDFEmail } from "../../api/email"
import date from "date-and-time"
import { makepdf } from "../../Modules/PAP/PAPMake"
import { makeassessmentpdf } from "../../Modules/PAP/PAPMakeAssessment"
import generatePassword from '../../utils/generatePassword';

const loremIpsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum mauris, hendrerit in elit interdum, sagittis pellentesque massa. Aenean dolor arcu, blandit a orci eu, fringilla tempor risus. Cras quam ante, vehicula ut sagittis at, maximus id purus. Suspendisse et metus urna. Duis pulvinar, diam in feugiat luctus, nibh ipsum fringilla felis, eu tempor sapien libero sit amet nunc. Nam luctus, quam vitae fermentum rutrum, quam eros cursus ex, id tincidunt massa neque id lorem. Quisque eget fermentum nibh. Duis pellentesque libero augue, non dictum nisl auctor non. Curabitur feugiat augue non massa egestas rutrum. Maecenas mollis neque ut feugiat facilisis. Duis a dui dui. Praesent tempor metus vitae libero accumsan consequat. Donec pretium turpis justo, quis semper massa dictum et. Maecenas eget felis eros. Quisque mi leo, porttitor sed semper eget, dignissim iaculis enim. Sed velit ex, volutpat at turpis eget, tempus interdum odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
const comorbidity = [
  { title: "substance abuse and anxiety", desc: loremIpsum.slice(80, 160) },
  { title: "Lipsum", desc: loremIpsum.slice(0, 80) },
]
const Circle = ({ riskStatus, children }) => {
  const circleStyle = {
    width: 10,
    height: 10,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "red",
  }
  switch (riskStatus) {
    case "High Risk":
      return <View style={[circleStyle, { backgroundColor: "red" }]} />
    case "Moderate Risk":
      return <View style={[circleStyle, { backgroundColor: "orange" }]} />
    default:
      return (
        <View style={[circleStyle, { backgroundColor: "green" }]}>
          {children}
        </View>
      )
  }
}
const Checkmark = ({ size }) => (
  <Canvas
    style={{
      height: 12,
      width: 12,
      borderRadius: 6,
      backgroundColor: "#00AA00",
    }}
    paint={(painter) =>
      painter
        .moveTo(2.75, 5.25)
        .lineTo(1.25, 6.75)
        .lineTo(4.5, 10)
        .lineTo(10.5, 4)
        .lineTo(9, 2.5)
        .lineTo(4.5, 7)
        .fill("white")
    }
  />
)

const styles = StyleSheet.create({
  page: {
    flexDirection: "",
    backgroundColor: "white",
    padding: 20,
  },
  header: {
    fontSize: 18,
  },
  headerContainer: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#e1f4f4",
    margin: -10,
    marginBottom: 0,
  },
  small: {
    fontSize: 12,
  },
  smallBold: {
    fontSize: 12,
    fontWeight: "bold",
  },
  sectionWrapper: {
    padding: 10,
    borderWidth: 1,
    borderColor: "#EEE",
    overflow: "hidden",
    flexDirection: "column",
    width: "100%" - 20,
    borderRadius: 10,
    marginBottom: 10,
  },
  descriptionText: {
    fontSize: 11,
    color: "grey",
  },
  sectionText: {
    fontSize: 13,
    color: "orange",
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 5,
  },
  containerBox: {
    borderRadius: 10,
    backgroundColor: "#e1f4f4",
    padding: 10,
  },
  medicationBox: {
    borderRadius: 10,
    backgroundColor: "#e1f4f4",
    flex: 1,
    overflow: "hidden",
  },
  symptomText: {
    fontSize: 10,
    marginLeft: 6,
    marginRight: 10,
  },
  sectionHeader: {
    fontSize: 18,
    marginBottom: 5,
  },
})

const Separator = () => (
  <View
    style={{
      marginVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#EAF1F2",
    }}
  />
)
const OverviewPage = ({ userData, detailsData }) => {
  const myStyles = StyleSheet.create({
    headerContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    bigHeader: {
      fontSize: 15,
    },
    medHeader: {
      fontSize: 13,
    },
    body: {
      fontSize: 10,
      color: "grey",
    },
    sourceBox: {
      padding: 10,
      borderRadius: 8,
      width: "100%",
      backgroundColor: "#F6fefe",
      borderColor: "#EAF1F2",
      borderWidth: 1,
    },
    responseBox: {
      borderRadius: 6,
      padding: 7,
    },
    disorderMatrix: {
      borderRadius: 6,
      overflow: "hidden",
      borderColor: "#EEE",
      borderWidth: 1,
    },
    disorderListItem: {
      flexDirection: "row",
      padding: 7,
    },
  })
  return (
    <Page size="A4" style={styles.page}>
      <View style={myStyles.headerContainer}>
        <Text style={myStyles.medHeader}>Medical Action Plan</Text>
        <Text style={{ fontSize: 13, color: "grey" }}>
          {date.format(new Date(userData.completed_at), "MMM DD, YYYY")}
        </Text>
      </View>
      <Separator />
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 7 }}>
          <Text style={myStyles.bigHeader}>
            About your Medical Action Plan
          </Text>
          <Text
            style={[styles.descriptionText, { marginTop: 10, lineHeight: 1.5 }]}
          >
            {loremIpsum.slice(0, 500)}
          </Text>
          <View style={myStyles.sourceBox}>
            <Text style={styles.medHeader}>Document Source</Text>
            <Text
              style={[
                styles.descriptionText,
                { marginTop: 10, lineHeight: 1.5 },
              ]}
            >
              {loremIpsum.slice(0, 250)}
            </Text>
          </View>
        </View>
        <View style={{ flex: 3 }}></View>
      </View>
      <Separator />
      <View wrap={false} style={{ flexDirection: "row" }}>
        <View style={{ flex: 1, marginRight: 4 }}>
          <Text style={[styles.medHeader, { marginBottom: 5 }]}>
            Health History
          </Text>
          {detailsData &&
            detailsData.health_history_responses &&
            detailsData.health_history_responses.map((item, index) => (
              <HealthQuestionBox item={item} index={index} lang={"en"} />
            ))}
        </View>
        <View style={{ flex: 1, marginLeft: 4 }}>
          <Text style={[styles.medHeader, { marginBottom: 5 }]}>
            Life Functions
          </Text>
          {detailsData &&
            detailsData.life_functions_responses &&
            detailsData.life_functions_responses.map((item, index) => (
              <LifeQuestionBox item={item} index={index} lang={"en"} />
            ))}
        </View>
      </View>
      <Separator />
      <View wrap={false} style={{ flexDirection: "row" }}>
        <View style={{ flex: 1, marginRight: 4 }}>
          <Text style={[styles.medHeader, { marginBottom: 5 }]}>
            Comorbidity Groups
          </Text>
          {comorbidity.map((item, index) => (
            <View
              style={[
                responseStyles.responseBox,
                { backgroundColor: index % 2 ? "#F8FAF9" : "#F2FBFA" },
              ]}
            >
              <Text style={responseStyles.question}>{item.title}</Text>
              <Text style={responseStyles.answer}>{item.desc}</Text>
            </View>
          ))}
        </View>
        <View style={{ flex: 1, marginLeft: 4 }}>
          <Text style={[styles.medHeader, { marginBottom: 5 }]}>
            Disorder Matrix
          </Text>
          <View style={myStyles.disorderMatrix}>
            <View
              style={[myStyles.disorderListItem, { backgroundColor: "#EEE" }]}
            >
              <Text style={{ fontSize: 10, flex: 6 }}>DISORDER NAME</Text>
              <Text style={{ fontSize: 10, flex: 3 }}>RISK LEVEL</Text>
            </View>
            {userData.disorders.map((item, index) => (
              <View
                style={[
                  myStyles.disorderListItem,
                  { backgroundColor: index % 2 ? "#F6F8F7" : "white" },
                ]}
              >
                <Text style={{ fontSize: 10, flex: 6 }}>{item.name}</Text>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 3,
                    alignItems: "center",
                  }}
                >
                  <Circle riskStatus={item.riskStatus} />
                  <Text style={{ fontSize: 10, marginLeft: 4 }}>
                    {item.riskStatus}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Page>
  )
}
const responseStyles = StyleSheet.create({
  responseBox: {
    borderRadius: 6,
    padding: 7,
    marginVertical: 4,
  },
  question: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 5,
  },
  answer: {
    fontSize: 10,
    color: "grey",
  },
})
const HealthQuestionBox = ({ item, index, lang }) => {
  return (
    <View
      style={[
        responseStyles.responseBox,
        { backgroundColor: index % 2 ? "#F8FAF9" : "#F2FBFA" },
      ]}
    >
      <Text style={responseStyles.question}>{item.question[lang].text}</Text>
      <Text style={responseStyles.answer}>
        {item.response.length
          ? item.response.map(
              (res, index) =>
                res[lang] + (index === item.response.length - 1 ? "" : ", ")
            )
          : "None"}
      </Text>
    </View>
  )
}

const LifeQuestionBox = ({ item, index, lang }) => {
  return (
    <View
      style={[
        responseStyles.responseBox,
        { backgroundColor: index % 2 ? "#F8FAF9" : "#F2FBFA" },
      ]}
    >
      <Text style={responseStyles.question}>{item.question[lang].text}</Text>
      {item.question.question_type !== "disablity" && item.response !== -1 && (
        <ProgressBar
          style={{
            width: "100%",
            height: 8,
            backgroundColor: "#E8E8E8",
            marginBottom: 5,
            borderRadius: 4,
            overflow: "hidden",
          }}
          barColor={"#33CA90"}
          value={
            item.response /
            (item.question.question_type === "percent" ? 100 : 60)
          }
        />
      )}
      {item.question.question_type === "disablity" ? (
        <Text style={responseStyles.answer}>
          {item.response === -1 ? "None" : item.response[lang]}
        </Text>
      ) : (
        <Text style={responseStyles.answer}>
          {item.response === -1
            ? "None"
            : item.response +
              (item.question.question_type === "percent" ? "%" : "")}
        </Text>
      )}
    </View>
  )
}

const ProgressBar = ({ value, style, barColor }) => {
  const myStyles = StyleSheet.create({
    bar: {
      width: value * 100 + "%",
      height: "100%",
      backgroundColor: barColor,
    },
  })
  return (
    <View style={style}>
      <View style={myStyles.bar} />
    </View>
  )
}
const MyDocument = ({ userData, detailsData }) => {
  return (
    <Document>
      <OverviewPage userData={userData} detailsData={detailsData} />
      {userData.disorders.map((item, index) => {
        return (
          <Page key={index} size="A4" style={styles.page}>
            <View style={styles.sectionWrapper}>
              {/* HEADER */}
              <View style={styles.headerContainer}>
                <Text style={styles.header}>Disorder {item.name}</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={styles.small}>
                    Score: {item.score}% Risk Level:{" "}
                  </Text>
                  <Circle riskStatus={item.riskStatus} />
                  <Text style={styles.small}>
                    {" " + item.riskStatus.split(" ")[0]}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 2, marginRight: 20 }}>
                  <Text style={{ fontSize: 10, marginTop: 10 }}>
                    {item.description.pap.text.en}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.sectionText}>Symptoms</Text>
                  <View style={styles.containerBox}>
                    {item.symptomes &&
                      item.symptomes.map((i, index) => (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            paddingVertical: 3,
                          }}
                        >
                          <Checkmark />
                          <Text style={styles.symptomText}>
                            {i.text.en.replace(/\//, `/ `)}
                          </Text>
                        </View>
                      ))}
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                {!!item.medication.length && (
                  <View style={{ flex: 2, marginRight: 20 }}>
                    <Text style={styles.sectionText}>Medication</Text>
                    <View style={{ width: "100%", flexDirection: "row" }}>
                      {[
                        "Medication",
                        "Class",
                        "Starting Dose",
                        "Steady Dose",
                      ].map((title) => (
                        <View key={title} style={{ flex: 1, paddingLeft: 10 }}>
                          <Text style={{ fontSize: 8, color: "grey" }}>
                            {title}
                          </Text>
                        </View>
                      ))}
                    </View>
                    <View style={styles.medicationBox}>
                      {item.medication.map((i, index) => (
                        <View
                          key={index}
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            backgroundColor: index % 2 == 0 ? null : "#F8FCFF",
                          }}
                        >
                          {[
                            "brand_name",
                            "sci_name",
                            "initial_dose",
                            "dose",
                          ].map((name) => (
                            <View key={name} style={{ flex: 1, padding: 10 }}>
                              <Text style={{ fontSize: 8 }}>
                                {i[name].text.en}
                              </Text>
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  </View>
                )}
                <View style={{ flex: 1 }}>
                  <Text style={styles.sectionText}>Clinician's Steps</Text>
                  <Text style={{ fontSize: 8, color: "grey" }}>
                    Simple steps of your client’s Assessment Results.
                  </Text>
                  <View style={styles.containerBox}>
                    {console.log(item)}
                    {item.clinical_steps &&
                      item.clinical_steps.map((i, index) => (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            paddingVertical: 3,
                          }}
                        >
                          <Checkmark />
                          <Text style={styles.symptomText}>
                            {i.text.en.replace(/\//, `/ `)}
                          </Text>
                        </View>
                      ))}
                  </View>
                </View>
              </View>
              <Link
                src="www.google.com"
                style={{ fontSize: 10, marginTop: 10 }}
              >
                Link to evidence, clinician guide, sources
              </Link>
              <Text style={styles.sectionText}>Talk Therapy</Text>
              {item.therapy.map((item, index) => (
                <Text key={index} style={styles.descriptionText}>
                  {item.text.en}
                </Text>
              ))}
            </View>
          </Page>
        )
      })}
    </Document>
  )
}

export const DownloadPAP = ({
  userData,
  detailsData,
  children,
  lang = "en",
  onClick = () => {},
}) => {
  const downloadPAP = async () => {
    const blob = await pdf(
      <MyDocument userData={userData} detailsData={detailsData} />
    ).toBlob()
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "Assessment Details.pdf"
    link.click()
  }
  const myOnClick = () => {
    downloadPAP()
    onClick()
  }
  return (
    <div style={{ color: "#33ca8f" }} onClick={myOnClick}>
      {children}
    </div>
  )
}

export const PrintMyPAP = ({ userData, detailsData, children }) => (
  <PDFDownloadLink
    document={<MyDocument userData={userData} detailsData={detailsData} />}
    fileName="Assessment Details.pdf"
    style={{ color: "#33ca8f" }}
  >
    {children}
  </PDFDownloadLink>
)

export const PAPView = (props) => (
  <PDFViewer width={props.width} height={props.height}>
    <MyDocument userData={props.userData} detailsData={props.detailsData} />
  </PDFViewer>
)

export const emailPAP = async ({
  userData,
  detailsData,
  email,
  assessment_id,
  fullHistory,
  assessmentDisorders,
  state
}) => {
  let passwordChanged = false;
  if(!detailsData.user || !detailsData.user.pdfPassword) {
    let autoGeneratedPassword = generatePassword();
    detailsData.user.pdfPassword = autoGeneratedPassword;
    passwordChanged = true;
  }
  const blob = await makepdf(detailsData,
    assessmentDisorders,
    fullHistory,
    "getBlob", state, passwordChanged, true);
  var reader = new FileReader()
  reader.onloadend = async function () {
    var base64data = reader.result.split(",")[1]
    sendPDFEmail(email, base64data, assessment_id, true)
  }
  reader.readAsDataURL(blob)
}

export const emailAssessment = async ({
  userData,
  detailsData,
  email,
  assessment_id,
  fullHistory,
  assessmentDisorders,
  state
}) => {
  let passwordChanged = false;
  if(!detailsData.user || !detailsData.user.pdfPassword) {
    let autoGeneratedPassword = generatePassword();
    detailsData.user.pdfPassword = autoGeneratedPassword;
    passwordChanged = true;
  }
  const blob = await makeassessmentpdf(detailsData, // needed
    assessmentDisorders, // <=== not needed
    fullHistory, // <=== not needed
    "getBlob",  // <=== needed
    state, // <== not needed
    passwordChanged, // <=== needed
    true // needed but optional
  );
  var reader = new FileReader()
  reader.onloadend = async function () {
    var base64data = reader.result.split(",")[1]
    sendPDFEmail(email, base64data, assessment_id)
  }
  reader.readAsDataURL(blob)
}