import axios from 'axios'
import { baseUrl } from '../lib/constants'
import moment from 'moment'

export const GOOGLE_CALENDAR_SCOPES = "https://www.googleapis.com/auth/calendar"

export const revokeAccessToken = async () => {
    if (localStorage.getItem('google_refresh_token')) {
      try {
        let response = await axios.post(`${baseUrl}/google-calendar/refresh-token`, { refreshToken: localStorage.getItem('google_refresh_token') })

        if (response.data.access_token) {
          localStorage.setItem('google_access_token', response.data.access_token)
        }
        if (response.data.expiry_date) {
          localStorage.setItem('google_expiry_date', response.data.expiry_date)
        }
        console.log("token refreshed success!")
        const res = validateToken()
        if(res){
            return true
        }
      } catch (err) { console.log(err) }
    } else {
      console.log('refresh token not found')
    }
  }


export const validateToken = async () => {
    const nowMoment = moment(new Date())
    const expiryMoment = moment(parseInt(localStorage.getItem('google_expiry_date')))
    if (nowMoment.isAfter(expiryMoment)) {
      console.log('unauthorized -> revoking access token')
      const response = await revokeAccessToken()
      if(response == true){
        return true;
      }
    } else {
      console.log('Token validation success')
      return true;
    }
  }

  export const fetchGoogleAccessToken = async (tokenResponse) => {
    try {
    //   console.log('token response : ', tokenResponse)
      // axios.defaults.withCredentials = true
      const options = {
        // withCredentials: true,
        // mode: "cors",
      };
      const response = await axios.post(`${baseUrl}/google-calendar`, { authCode: tokenResponse.code }, options)
      console.log(response)
      localStorage.setItem('google_access_token', response.data.access_token)
      if (response.data.refresh_token) { localStorage.setItem('google_refresh_token', response.data.refresh_token) }
      if (response.data.expiry_date) { localStorage.setItem('google_expiry_date', response.data.expiry_date) }
      return true
    } catch (error) {
      console.log(error)
    }
  }