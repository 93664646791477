import React, { useState, useEffect } from "react"
import { Button, } from "react-bootstrap"
// import "../css/ClientAccessCode.css"
import "../../../css/privacyPolicy.css"
import { SharedModalBackground } from "../../../utils/shared"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { SupportedOrganizations } from "../../../lib/constants"
import LogoSVG from "../../../assets/images/fbn-logo.svg"
import CloseImg from "../../../assets/images/close.svg"
import OutsideClickHandler from "react-outside-click-handler"
import styled from "@emotion/styled"
import "../../../css/modelCommonStyle.css"
const store = require("store")

const EModal = styled.div`
  min-width: 15em;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "1px 7px 20px 2px rgba(0, 0, 0, 0.4)"};
  background-color: #fff;
  border-radius: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 82vh;
  width: 600px;
  position: relative;
  font-family: "CircularStdBook", sans-serif;
  @media (max-width: 640px) {
    width: 90vw;
    overflow-y: auto;
  }
`

const EModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const GreyLine = styled.div`
  border: 1px solid #ececec;
`

const EModalText = styled.p`
  font-family: "CircularStdBook", sans-serif;
  font-size: 1.2em;
  color: #505050;
  margin: 0;
`

export const DemoClientAccessid = ({ show, handleClose, onHide, t, i18n, close = false }) => {
  const [openClientDetails, setOpenClientDetails] = useState(false)

  useEffect(() => {
    if (i18n)
      i18n.changeLanguage(store.get('lang') || 'en')
  }, [])

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
    store.set('lang', newLang);
  };

  // const { t, i18n, customWidth, customMaxWidth, education_id } = props
  const [currentLink, setCurrentLink] = useState('')

  return (
    <>
      {show && (
        <>
          <SharedModalBackground />

          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              left: 0;
              z-index: 1100;
              @media (max-width: 640px) {
                font-size: 0.8em;
              }
              @media (max-height: 1000px) {
                font-size: 0.7em;
              }
            `}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpenClientDetails(false)
              }}
            >
              <EModal>
                <>
                  <EModalContent>
                    <div
                      css={css`
                        padding: 1.5em 2em;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      `}
                    >
                      <img
                        src={LogoSVG}
                        alt="logo"
                        css={css`
                          width: 15em;
                        `}
                      />
                      {close &&
                        <img
                          src={CloseImg}
                          onClick={() => {
                            onHide()
                          }}
                          css={css`
                            height: 1.2em;
                            cursor: pointer;
                          `}
                        />
                      }
                      <Button onClick={toggleLanguage}>
                        {i18n.language === 'en' ? 'French' : 'English'}
                      </Button>
                    </div>

                    <GreyLine />

                    {!openClientDetails && (
                      <>
                        <EModalText
                          css={css`
                            margin: 1.5em 2em 1em 2em;
                            font-weight: 600;
                          `}
                          dangerouslySetInnerHTML={{ __html: t("partnershipPopup.title") }}
                        ></EModalText>

                        <EModalText
                          css={css`
                            margin: 0.6em 2em 0 2em;
                          `}
                          dangerouslySetInnerHTML={{ __html: t("partnershipPopup.information") }}
                        >
                        </EModalText>

                        <EModalText
                          css={css`
                            margin: 0.6em 2em 0 2em;
                          `}
                          dangerouslySetInnerHTML={{ __html: t("partnershipPopup.vistitingText") }}
                        >
                        </EModalText>

                        <EModalText
                          css={css`
                            margin: 0.6em 2em 0 2em;
                          `}
                          dangerouslySetInnerHTML={{ __html: t("partnershipPopup.teachersExperience") }}
                        >


                        </EModalText>


                        {/* <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            handleClientAccessCodeSubmit()
                          }}
                        >
                          <div
                            css={css`
                              display: grid;
                              grid-template-columns: 1fr 9em;
                              margin: 0.8em 2em 2em 2em;
                              min-height: 3.2em;
                              position: relative;
                              @media (max-width: 640px) {
                                grid-template-columns: 1fr 5em;
                              }
                            `}
                          >
                            <img
                              src={LeafsSVG}
                              css={css`
                                width: 1.5em;
                                justify-self: center;
                                position: absolute;
                                left: 0.7em;
                                font-size: 1.1em;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                z-index: 1;
                              `}
                            />
                            <input
                              {...codeInput.bindToInput}
                              placeholder="Enter Client Access Code"
                              type="text"
                              css={css`
                                padding-left: 2.4em;
                                border-left: 1px solid #d1d1d1;
                                border-top: 1px solid #d1d1d1;
                                border-bottom: 1px solid #d1d1d1;
                                border-top-left-radius: 0.4em;
                                border-bottom-left-radius: 0.4em;
                                outline: none;
                                border-right: 0;
                                font-size: 1.2em;
                                z-index: 2;
                                background-color: transparent;
                                min-width: 10em;
                                ::placeholder {
                                  color: #c8c8c8;
                                }
                              `}
                            />
                            <button
                              css={css`
                                background-color: #33ca8f;
                                color: #fff;
                                text-transform: capitalize;
                                font-family: "CircularStdBook", sans-serif;
                                padding: 0;
                                margin: 0;
                                outline: none;
                                border: 0;
                                border-top-right-radius: 0.4em;
                                border-bottom-right-radius: 0.4em;
                                letter-spacing: 0.06em;
                                :focus {
                                  outline: none;
                                }
                              `}
                              type="submit"
                              onClick={() => {
                                // handleClientAccessCodeSubmit()
                              }}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </form> */}
                        {currentLink &&
                          <EModalText
                            css={css`
                              font-weight: 600;
                              margin: 0.6em 2em 0 2em;
                            `}
                          >
                            {t('startAssessmentPopup.label')} <a style={{ color: '#33ca8f', wordBreak: 'break-all' }} href={currentLink}>{currentLink}</a>
                          </EModalText>
                        }
                      </>
                    )}

                    {/* {openClientDetails && (
                      <>
                        <div
                          css={css`
                            font-weight: 600;
                            margin: 1.7em 2.2em 0em 2.2em;
                          `}
                        ></div>
                        <EModalText
                          css={css`
                            margin: 0.8em 2em 0 2em;
                          `}
                        >
                          {t('clientPopup.description')}
                        </EModalText>
                        <div
                          css={css`
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-row-gap: 1em;
                            padding: 2em 2em 1.5em 2em;
                          `}
                        >
                          <EInputWrapper>
                            <EInputImg src={BuildingSVG} />
                            <EInput
                              type="text"
                              {...companyInput.bindToInput}
                              placeholder="School"
                              maxLength={50}
                            />
                          </EInputWrapper>
                          <EInputWrapper>
                            <EInputImg src={PhoneSVG} />
                            <EInput
                              type="tel"
                              {...phoneInput.bindToInput}
                              placeholder="Phone Number"
                              maxLength={50}
                            />
                          </EInputWrapper>
                          <EInputWrapper>
                            <EInputImg src={MailSVG} />
                            <EInput
                              type="email"
                              {...emailInput.bindToInput}
                              placeholder="Email Address"
                            />
                          </EInputWrapper>
                          <EInputWrapper>
                            <button
                              css={css`
                                background-color: #33ca8f;
                                color: #fff;
                                text-transform: capitalize;
                                padding: 0;
                                margin: 0;
                                outline: none;
                                border: 0;
                                border-radius: 0.4em;
                                letter-spacing: 0.06em;
                                height: 100%;
                                width: 100%;
                                :focus {
                                  outline: none;
                                }
                              `}
                              onClick={() => {
                                onSubmit(2)
                              }}
                            >
                              SUBMIT
                            </button>
                          </EInputWrapper>
                          {!isContactValid &&
                            <p style={{ color: 'red' }}>Please enter valid phone number</p>
                          }
                          {!isEmailValid &&
                            <p style={{ color: 'red' }}>Please enter valid email</p>
                          }
                        </div>
                      </>
                    )} */}

                    <GreyLine />


                    <div
                      css={css`
                        margin: 1.5em 2em;
                        align-self: flex-end;
                      `}
                    >
                      {/* <p
                        css={css`
                          font-style: normal;
                          font-weight: normal;
                          font-size: 1.2em;
                          color: #33ca8f;
                          margin: 0;
                          cursor: pointer;
                        `}
                        onClick={() => {
                          setOpenClientDetails(!openClientDetails)
                        }}
                      >
                        {openClientDetails
                          ? "Have a client access code?"
                          : "Don’t have a client access code?"}
                      </p> */}
                      <p></p>
                    </div>
                  </EModalContent>
                </>
              </EModal>
            </OutsideClickHandler>
          </div>
        </>
      )}
    </>
  )
}