import React from 'react'
import {scroller} from 'react-scroll'

export default function ScrollToID(id) {
    scroller.scrollTo(id, {
        smooth: true,
        offset: -100,
        duration: 400,
        delay: 0,
    });
}