import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'react-bootstrap';
import SlidingPanel from 'react-sliding-side-panel';
import { ErrorScreen } from '../error/ErrorScreen';
import { jsx, css } from "@emotion/react"
import 'react-sliding-side-panel/lib/index.css';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import SucessIcon from '../../assets/images/success.png'
import styled from "@emotion/styled"
import x2 from "../../assets/images/close.svg"
import i18n from '../../i18n';

class ReactSlidingOutcomePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPanel: false,
      numberOfPieces: 400
    };
    
  }

  static EClose = styled.button`
        @media (max-width: 768px) {
          right: 30px
        }
        overflow: 0;
        position: absolute;
        padding: 0px;
        width: 0.8em;
        height: 0.8em;
        background: transparent;
        border: 0;
        right: 60px;
        top: 25px;
        outline: none;
        :focus {
          outline: none
        }
        `

  static CornerNo = styled.p`
        margin-bottom: 0;
        width: 42px;
        height: 42px;
        min-height: 42px;
        min-width: 42px;
        background: #EC5F43;;
        border-radius: 100px;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        `

  setOpenPanel = (val) => {
    this.setState({
      openPanel: val,
    });
  };


  componentDidUpdate() {
    if (this.state.openPanel) {
      setTimeout(() => {
        this.setState({ numberOfPieces: 0 })
      }, 2000)
    }
  }

  componentDidMount(){
    if(!this.state.openPanel) {
        console.log("PASSED ONE")
        let urlString = window.location.href
        let url = new URL(urlString)
        
        let fromHistory = url.searchParams.get('fromHistory')
        console.log(fromHistory, "PASSED fromHistory")
        if(fromHistory !== 'true'){
            console.log("PASSED TWO")
            this.setState({ openPanel: true })
        }
    }
  }

  

  render() {
    const { openPanel } = this.state;
    const { width, height } = this.props
    const sgColors = [
      "#9ce8c2",
      "#60d399",
      "#b9e2fe",
      "#4fb3f6",
      "#bdc7fb",
      "#6d83f3",
      "#ff7968",
      "#ffe8e5",
      "#fedd8e",
      "#fbbe2e"
    ];
    return (
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <div>
          <div className="bottom-bar-position mobile-devices-bottom-part">
            <div className="tab-container">
              <ul className="tabs-custom clearfix customSlider-tab-part" >
                <li className=" nav-item " onClick={() => this.setOpenPanel(true)}>
                  <a className='nav-link nav-link-bold' >{i18n.t('summaryPage.bottomSlider.tab')}</a>
                </li>
                {/* <li  onClick={() => this.setOpenPanel(true)}>
                <a >2000 Point Earned!</a>
              </li> */}
              </ul>
            </div>
          </div>
          <SlidingPanel
            type="bottom"
            isOpen={openPanel}
            size={50}
            panelContainerClassName=""
            backdropClicked={() => this.setOpenPanel(false)}
          >

            <div className="tab-container">
              <ul className="tabs-custom clearfix customSlider-tab-part inner-heading-tab" style={{ marginLeft: 50 }}>
                <li className="active nav-item" onClick={() => this.setOpenPanel(false)}>
                  <a className='nav-link nav-link-bold'>{i18n.t('summaryPage.bottomSlider.tab')}</a>
                </li>
              </ul>
            </div>
            <div className='bg-white'>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                    </div>
                  </div>
                </div>
              </div>
              <div className='ribbion-animation'>
                <Confetti
                  width={width}
                  height={height}
                  colors={sgColors}
                  numberOfPieces={this.state.numberOfPieces}
                />
                <div className="py-4 panel-content-part">
                  <div className='container-fluid outcome-bottom-container' >
                    <div className='throphy-section-outcome'>
                        <div className='sucess-part-outcome'>
                            <img src={SucessIcon} alt="throfy" />
                        </div>
                        <div className='sucess-text-part-outcome'>
                            <h5>{i18n.t('summaryPage.bottomSlider.title')}</h5>
                            <p>{i18n.t('summaryPage.bottomSlider.description')}</p>
                        </div>
                    </div>
                    <div className="links-section-outcome">
                        <div className='steps-text-part' >
                            <ReactSlidingOutcomePanel.CornerNo >1</ReactSlidingOutcomePanel.CornerNo>
                            <span>{i18n.t('summaryPage.bottomSlider.step1')}</span>
                        </div>
                        <div className='steps-text-part' >
                            <ReactSlidingOutcomePanel.CornerNo >2</ReactSlidingOutcomePanel.CornerNo>
                            <span>{i18n.t('summaryPage.bottomSlider.step2')}</span>
                        </div>
                        <div className='steps-text-part' >
                            <ReactSlidingOutcomePanel.CornerNo >3</ReactSlidingOutcomePanel.CornerNo>
                            <span>{i18n.t('summaryPage.bottomSlider.step3')}</span>
                        </div>
                        <div className='steps-text-part' >
                            <ReactSlidingOutcomePanel.CornerNo >4</ReactSlidingOutcomePanel.CornerNo>
                            <span>{i18n.t('summaryPage.bottomSlider.step4')}</span>
                        </div>
                    </div>

                    <ReactSlidingOutcomePanel.EClose
                      onClick={() => {
                         this.setOpenPanel(false)
                      }}
                    >
                      <img src={x2} alt="close btn" />
                    </ReactSlidingOutcomePanel.EClose>

                  </div>
                </div>
              </div>
            </div>
          </SlidingPanel>
        </div>
      </ErrorBoundary>
    );
  }
}

function ReactSlidingOutcomePanelFun(props) {
  const { width, height } = useWindowSize()
  return <ReactSlidingOutcomePanel {...props} height={height} width={width} />
}

export default withTranslation()(ReactSlidingOutcomePanelFun);
