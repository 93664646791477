import React, { useState, useEffect } from "react"
import "../../../css/popup.scss"
import "../../../css/NotificationScheduler.scss"
import OutsideClickHandler from "react-outside-click-handler"
import { Notification } from "../../../api/notification"
import DownloadApp from "../mobile/DownloadApp"

const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"]
const weeksPerMonth = [1, 2, 4]

export const NotificationScheduler = (props) => {
  const { onClose } = props
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [formInfo, setFormInfo] = useState(null)

  const { t, i18n } = props;

  useEffect(() => {
    handleGetInfo()
  }, [])

  const handleGetInfo = () => {
    setError(false)
    setLoading(true)

    getInfo()
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const getInfo = async () => {
    try {
      const res = await Notification.get()
      if (res && res.status && res.status === 200) {
        setFormInfo(res.data)
      } else {
        setError(true)
      }
    } catch (err) {
      setError(true)
    }
  }

  const handlePatchInfo = (obj) => {
    postInfo(obj)
    onClose()
    return
  }

  const postInfo = async (obj) => {
    try {
      setError(false)

      const res = await Notification.post(obj)

      if (res && res.status && res.status === 201) {
        getInfo()
      } else {
        setError(true)
      }
    } catch (err) {
      setError(true)
      getInfo()
    }
  }

  const handleErrorClose = () => {
    setError(false)
    handleGetInfo()
  }

  if (isError)
    return (
      <ErrorWindow
        onErrorClose={handleErrorClose}
        onClose={onClose}
        isError={isError}
        t={t}
        i18n={i18n}
      />
    )

  return (
    <button>
      <Component
        onClose={onClose}
        isLoading={isLoading}
        isError={isError}
        {...formInfo}
        onNotificationUpdate={handlePatchInfo}
        onErrorClose={handleErrorClose}
        signUpNotifsHandler={props.signUpNotifsHandler}
        t={t}
        i18n={i18n}
      />
    </button>
  )
}

const ErrorWindow = ({ onErrorClose, isError, onClose, t, i18n }) => {
  return (
    <>
      <div className="modal-background" />

      <div className="modal-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => {
            onClose()
          }}
        >
          <div className={`modal-form`}>
            <h2>
              {typeof isError === "string" || isError instanceof String ? (
                <>{isError}</>
              ) : (
                <>{t("summaryPage.notifModal.error")}</>
              )}
            </h2>
            <button
              className="submit-btn"
              onClick={() => {
                onErrorClose()
              }}
            >
              {t("summaryPage.notifModal.try")}
            </button>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

const Component = ({
  onClose,
  _id,
  email,
  phone,
  text,
  weekdays,
  weekspermonth,
  isEmail,
  isPhone,
  isText,
  userId,
  isLoading,
  onNotificationUpdate,
  signUpNotifsHandler,
  t,
  i18n
}) => {
  const [day, setDay] = useState(null)
  const [weekPerMonth, setWeekPerMonth] = useState(weeksPerMonth[0])
  const [mailEnabled, setMailEnabled] = useState(false)
  const [phoneNotificationsEnabled, setPhoneNotificationsEnabled] =
    useState(false)
  const [notificationsEnabled, setNotificationEnabled] = useState(false)

  const [displayMobileLinks, setDisplayMobileLinks] = useState(false)
  const [displayWeekPerMonthOptions, setDisplayWeekPerMonthOptions] =
    useState(false)

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setDay(weekdays)
    setWeekPerMonth(weekspermonth ? weekspermonth : weeksPerMonth[0])
    setMailEnabled(isEmail)
    setPhoneNotificationsEnabled(isPhone)
    setNotificationEnabled(isText)
  }, [email, text, weekdays, isEmail, isPhone, isText])

  return (
    <>
      <div className="modal-background" />

      <div className="modal-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => {
            document.body.style.overflow = "scroll";
            onClose()
          }}
        >
          {displayMobileLinks ? (
            <div className="downloadapp-container">
              <DownloadApp canDelete={false} onClose={() => {
            document.body.style.overflow = "scroll";
            onClose();
          }} />
            </div>
          ) : (
            <div>
              <div
                className={`${
                  displayMobileLinks
                    ? "modal-form modal-form-mobile"
                    : "modal-form"
                } ${isLoading ? "modal-form-loading" : ""}`}
              >
                {isLoading && (
                  <div className="notification-loading-cover">
                    <div
                      className="spinner-border text-primary"
                      style={{
                        width: "3rem",

                        height: "3rem",
                      }}
                    ></div>
                  </div>
                )}

                {!displayMobileLinks && (
                  <>
                    <div className="modal-header" style={{ borderColor: "transparent" }}>
                      <button
                        type="button"
                        className="close x-button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          document.body.style.overflow = "scroll";
                          onClose()
                        }}
                      >
                        <span aria-hidden="true" style={{ color: "black" }}>
                          &times;
                        </span>
                      </button>
                    </div>
                    <img src={require('../../../assets/images/FeelingBetterNowLogo.svg').default} className="fbn-logo-self-harm" />


                      {/* <img src={require('../../../assets/images/illustration-notification-scheduler.svg')} className="illustration-notification" /> */}



                    <h2 className="modal-title">{t("summaryPage.notifModal.headline")}</h2>
                    <p className="modal-description">
                    {t("summaryPage.notifModal.description")}
                    </p>

                    <div className="modal-body-wrapper">
                    <div className="field-wrapper">
                      <p className="text-left">{t("summaryPage.notifModal.email")}</p>

                      <Swticher
                        enabled={mailEnabled}
                        onClick={setMailEnabled}
                      />
                    </div>

                    <div className="field-wrapper">
                      <p className="text-left">{t("summaryPage.notifModal.phnNotif")}</p>

                      <Swticher
                        enabled={phoneNotificationsEnabled}
                        onClick={setPhoneNotificationsEnabled}
                      />
                    </div>

                    <div className="field-wrapper">
                      <p className="text-left">{t("summaryPage.notifModal.textNotif")}</p>

                      <Swticher
                        enabled={notificationsEnabled}
                        onClick={setNotificationEnabled}
                      />
                    </div>

                    <div className="days-wrapper">
                      {days.map((i, index) => (
                        <button
                          className={`day-button ${
                            day === 1 + index ? "day-button__active" : ""
                          }`}
                          key={`dayList${i}`}
                          onClick={() => {
                            setDay(1 + index)
                          }}
                        >
                          {i}
                        </button>
                      ))}
                    </div>

                    <div className="weeks-per-month-field">
                      <p>{t("summaryPage.notifModal.weeksPerMonth")} </p>

                      <div className="select-wrapper">
                        <button
                          className="toggle-button"
                          onClick={() => {
                            setDisplayWeekPerMonthOptions(
                              !displayWeekPerMonthOptions
                            )
                          }}
                        >
                          <>
                            {weekPerMonth}

                            <OutsideClickHandler
                              onOutsideClick={() => {
                                setDisplayWeekPerMonthOptions(false)
                              }}
                            >
                              <div
                                className={`toggleable-weeks-options ${
                                  displayWeekPerMonthOptions
                                    ? "toggleable-weeks-options__active"
                                    : ""
                                }`}
                              >
                                {weeksPerMonth.map((i, index) => (
                                  <div
                                    tabIndex={0}
                                    key={`weeksPerMonth${index}`}
                                    onClick={() => {
                                      setWeekPerMonth(i)
                                      setDisplayWeekPerMonthOptions(false)
                                    }}
                                  >
                                    {i}
                                  </div>
                                ))}
                              </div>
                            </OutsideClickHandler>
                          </>
                        </button>
                      </div>
                      </div>
                    </div>
                    
                    <div className="bottom-btns">
                      <div className="d-flex">
                        <button
                          className="submit-btn"
                          onClick={() => {
                            onNotificationUpdate({
                              // _id,
                              // phone,
                              // weekdays: day,
                              // weekspermonth: weekPerMonth,
                              isEmail: mailEnabled,
                              isPhone: phoneNotificationsEnabled,
                              isText: notificationsEnabled,
                              // userId,
                            })
                            signUpNotifsHandler()
                          }}
                        >
                          {t("summaryPage.notifModal.update")}
                        </button>
                      </div>
                      <button
                        className="mobile-link mobile-link-center d-flex"
                        onClick={() => {
                          setDisplayMobileLinks(true)
                        }}
                      >
                        {t("summaryPage.notifModal.needToDownload")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
    </>
  )
}

const Swticher = ({ enabled, onClick }) => (
  <button
    onClick={() => {
      onClick(!enabled)
    }}
    className={`switcher-wrapper ${enabled ? "switcher-wrapper__active" : ""}`}
  >
    <div
      className={`circle-switcher ${enabled ? "circle-switcher__active" : ""}`}
    />
  </button>
)

export const FormInput = ({
  type,
  onChange,
  value = "",
  caption,
  activeClassName = "label__active",
}) => {
  const [focused, setFocused] = useState(false)

  return (
    <div className="form-input-wrapper">
      <label
        className={`label ${
          focused || (value && value.length > 0)
            ? activeClassName
            : "input-not-active"
        }`}
      >
        {caption}
      </label>

      <input
        placeholder=""
        className={`${focused ? "input__active" : ""}`}
        type={type}
        maxLength={320}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        value={value ? value : ""}
      />
    </div>
  )
}
