import React, { useState, useEffect, useContext } from "react"
import { withTranslation } from "react-i18next"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { FormInput } from "../notificationScheduler/NotificationScheduler"
import "../../../css/popup.scss"
import "../../../css/NotificationScheduler.scss"
import OutsideClickHandler from "react-outside-click-handler"
import { emailPAP } from "../../printable/PrintablePAP"
import { sendDocEmail, sendPDFEmail } from "../../../api/email"
import * as EmailValidator from "email-validator"
import { baseUrl } from "../../../lib/constants"
import logo from "../../../assets/images/FeelingBetterNowLogo.svg"
import closeX from "../../../assets/images/closeX.png"
import leftellipse from "../../../assets/images/leftEllipse.png"
import rightellipse from "../../../assets/images/rightEllipse.png"
import { logEvent } from "../../../index"
import { StateContext } from "../../../store/state/contexts"
import { Link } from "react-router-dom"
const SendAssessmentPdfs = ({
  onClose,
  userData,
  detailsData,
  assessment_id,
  sharePAPHandler,
  assessmentDisorders,
  t,
  fullHistory,
  i18n
}) => {
  const [email, setEmail] = useState("")
  const [doctorEmail, setDoctorEmail] = useState("")
  const [emailIsValid, setEmailValidity] = useState(false)
  const [norificationsText, setNotificationsText] = useState("")
  const [day, setDay] = useState(null)
  const [isEmailValid, setEmailIsValid] = useState(undefined)
  const [isDoctorEmailValid, setIsDoctorEmailValid] = useState(undefined)
  const [autofillResponse, setautofillResponse] = useState(undefined)
  const [checkBoxVal, setcheckBoxVal] = useState(false)

  const { state, dispatch } = useContext(StateContext)

  const [didSendAuto, setDidSendAuto] = useState(false)
  const [showPassword, setshowPassword] = useState(false)

  console.log('i18n  :::===> ', t, i18n)
  useEffect(() => {
    try {
      fetch(baseUrl + `/email/autofill`, {
        credentials: "include",
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json()
        })
        .then((result) => {
          handleRes(result)
        })
        .catch((err) => {
          logEvent("useEffect /email/autofill failed")
          console.log(err)
        })
    } catch (err) {
      console.log(err);
    }
    try {
      fetch(baseUrl + `/pdf/generate`, {
        credentials: "include",
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(assessment_id),
      }).then((res) => {
        if (res.ok) {
          logEvent("useEffect /pdf/generate successful")
          return res
        } else {
          logEvent("useEffect /pdf/generate failed")
        }
      })
    } catch (err) {
      console.log(err);
    }

  }, [assessment_id])

  useEffect(() => {
    console.log("component mounted!!")
  }, [])

  const handleRes = (res) => {
    setDoctorEmail(res.doctorEmail)
    setcheckBoxVal(res.isChecked)
    if (res.isChecked === true) {
      setDidSendAuto(true)
      // emailPAP({
      //   userData: userData,
      //   email: res.doctorEmail,
      //   detailsData: detailsData,
      //   assessment_id,
      // })
    }
  }

  let postDetails = async () => {
    console.log("checked:", checkBoxVal)
    let postOptions = {}
    // if (didSendAuto === false) {
    postOptions = {
      method: "POST",
      credentials: "include",
      mode: "cors",
      body: JSON.stringify({ isChecked: checkBoxVal, email: doctorEmail }),
      headers: {
        "Content-Type": "application/json",
      },
    }
    // }
    let response = await fetch(`${baseUrl}/email/updateDocEmail`, postOptions)
      .then((res) => {
        logEvent("postDetails /email/updateDocEmail successful")
        return res
      })
      .catch((e) => {
        logEvent("postDetails /email/updateDocEmail failed")
        console.log(e)
      })
    if (response.ok) return response
    else return 0
  }

  const handleShareSend = async () => {
    if (EmailValidator.validate(email) === true) {
      setEmailIsValid(true)
      emailPAP({
        userData: userData,
        email: email,
        detailsData: detailsData,
        assessment_id,
        fullHistory,
        assessmentDisorders,
        state
      })
      onClose()
    } else {
      setEmailIsValid(false)
    }
  }
  const handleDoctorSend = async () => {
    if (EmailValidator.validate(doctorEmail) === true) {
      setIsDoctorEmailValid(true)
      emailPAP({
        userData: userData,
        email: doctorEmail,
        detailsData: detailsData,
        assessment_id,
        fullHistory,
        assessmentDisorders,
        state
      })
      onClose()
    } else {
      setIsDoctorEmailValid(false)
    }
  }

  return (
    <>
      <div className="modal-background" />

      <div className="modal-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => {
            onClose()
          }}
        >
          <div
            className={"modal-form"}

            style={{
              border:
                isEmailValid === false || isDoctorEmailValid === false
                  ? "2px solid red"
                  : "none",
            }}
          >
            <div className="logo-line">
              <img src={logo} className="logo" />
              <img
                src={closeX}
                className="close-btn-send-action-plan"
                onClick={onClose}
              />
            </div>
            <div className="modal-scroll">
              {/* <img src={shareActionPlan} className="share-action-plan"/> */}
              <h2 className="share-action-plan">{t("shareActionPlanPopup.shareActionPlans")}</h2>
              <p className="words-above-box">
                {t("shareActionPlanPopup.description")}
              </p>

              <div className="summary-more-action modal-form-wrapper">
                <FormInput
                  type="text"
                  caption={t("shareActionPlanPopup.placeHolder")}
                  value={email}
                  onChange={setEmail}
                />


                <button
                  className="send-button-top"
                  onClick={() => {
                    handleShareSend()
                    sharePAPHandler()
                  }}
                >
                  {t("summaryPage.shareAssessmentModal.sendButton")}
                </button>
              </div>


              {isEmailValid === false && (
                <p style={{ color: "red", marginLeft: '2px', marginTop: '4px' }}>
                  {t("shareActionPlanPopup.PleaseEnterValidEmail")}
                </p>
              )}

              <>
                <hr style={{ marginTop: '2rem', marginBottom: '1.5rem' }}></hr>
                <h2 className="share-action-plan" style={{ marginTop: "15px" }}>
                  {t("shareActionPlanPopup.sendToYourDoctor")}
                </h2>
                <p className="words-above-box">
                  {t("shareActionPlanPopup.doctorDescription")}
                </p>

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    
                  `}
                >
                  <div className="custom-control custom-checkbox ml-1">
                      <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck"
                      checked={checkBoxVal}
                      onChange={() => {
                        // console.log(!checkBoxVal)
                        setcheckBoxVal(!checkBoxVal)
                      }}
                    />
                    <label className="custom-control-label" for="customCheck"> {t("shareActionPlanPopup.sendAutomatically")}</label>
                  </div>
                </div>

                <div className="summary-more-action modal-form-wrapper">
                  <FormInput
                    type="email"
                    value={doctorEmail}
                    onChange={setDoctorEmail}
                    caption={t("shareActionPlanPopup.doctorPlaceHolder")}
                  />




                  <button
                    className="send-button-bottom"
                    style={{}}
                    onClick={() => {
                      handleDoctorSend()
                      postDetails()
                      sharePAPHandler()
                    }}
                  >
                    {t("summaryPage.shareAssessmentModal.sendButton")}
                  </button>
                </div>
                {isDoctorEmailValid === false && (
                  <p style={{ color: "red", marginLeft: '2px', marginTop: '4px' }}>
                    {t("shareActionPlanPopup.PleaseEnterValidEmail")}
                  </p>
                )}
                <div className="mt-3">
                  <p style={{ fontWeight: '600' }} dangerouslySetInnerHTML={{ __html: t("shareActionPlanPopup.passwordNote1") }}>
                    {/* {t("shareActionPlanPopup.passwordNote1")}<Link style={{ color: '#33ca8f' }} to="/profile">{t("shareActionPlanPopup.profileLink")}</Link>{t("shareActionPlanPopup.passwordNote2")} */}
                  </p>
                </div>
                {detailsData && detailsData.user && detailsData.user.pdfPassword &&
                  <div className="password-part d-flex align-items-center">
                    <p style={{ fontWeight: '600' }} dangerouslySetInnerHTML={{__html: t("shareActionPlanPopup.actionPlanDescription")(detailsData && detailsData.user && (showPassword ? detailsData.user.pdfPassword : '*******'))}} />
                    <i className={`fal ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} style={{ fontWeight: "600", marginLeft: 8, marginTop: -6}} onClick={() => setshowPassword(showPassword => !showPassword)}></i>
                    {/* <button className="submit-btn" style={{marginLeft:'1rem'}} onClick={() => setshowPassword(showPassword => !showPassword)} >{showPassword ? 'Hide password' : 'Show Password'}</button> */}
                  </div>
                }
                <div style={{ zIndex: "-1", position: "absolute", left: "0", bottom: "0" }}>
                  <img
                    src={rightellipse}
                    className="right-ellipse-send-action-plan"
                  />
                  <img
                    src={leftellipse}
                    className="left-ellipse-send-action-plan"
                  />
                </div>



                {/* <button
                    css={css`
                      margin-top: 10px;
                      align-self: center;
                      padding: 2px 10px;
                      outline: none;
                      border: 0;
                      border-radius: 3px;
                      font-size: 1.3em;
                    `}
                    onClick={() => {
                      // handleDoctorSend()
                      // postDetails()
                      // sharePAPHandler()
                    }}
                  >
                    Send
                  </button> */}
              </>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export const SendAssessmentPdf = withTranslation()(SendAssessmentPdfs);
