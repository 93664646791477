import React from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import _map from "lodash/map"

const EWrapper = styled.div`
  max-height: 10em;
  overflow: auto;
  z-index: 44;
  position: absolute;
  padding: 0.375em 0;
  margin: 0.625em 0 0.625em -0.0625em;
  box-shadow: 0.0625em 0.125em 1.125em rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 100%;
  border-radius: 0.4375em;
`

const EListItem = styled.div`
  min-height: 2.6875em;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0.375em;
  font-size: 0.9em;
  :hover {
    background-color: #f1f1f1;
  }
`

const CompaniesDropDown = React.memo(
  ({ availableCompanies, onCompanyClick }) => {
    if (availableCompanies && availableCompanies.length === 0)
      return (
        <EWrapper>
          <EListItem
            css={css`
              cursor: default !important;
              padding-left: 1.875em;
              :hover {
                background-color: white;
              }
            `}
          >
            No entries found
          </EListItem>
        </EWrapper>
      )

    return (
      <EWrapper>
        {_map(availableCompanies, (i) => (
          <EListItem
            key={`${i.name}listitem`}
            tabIndex={0}
            onClick={() => {
              const companyObj = { name: i.name }
              onCompanyClick(companyObj)
            }}
          >
            <div
              css={css`
                padding-left: 0.25em;
              `}
            >
              {i.name}
            </div>
          </EListItem>
        ))}
      </EWrapper>
    )
  },
  (prev, next) =>
    prev.availableCompanies === next.availableCompanies &&
    prev.onCompanyClick === next.onCompanyClick
)

export { CompaniesDropDown }
