import React, { useEffect, useState } from 'react';
import { Element } from "react-scroll"
import { withTranslation } from "react-i18next"

const AssessmentCard = function ({
    open,
    answered,
    loading,
    setOpen,
    children,
    redHighlight,
    id,
    t,
    question,
    answer,
    disabled,
    fromFollowUp
}) {
    const [clicked, setClicked] = useState(false)
    const mouseDown = () => {
        setClicked(true)
    }
    let tranlationObject = {
        "Never": "inDepth.never",
        "Rarely": "inDepth.rarely",
        "Sometimes": "inDepth.sometimes",
        "Frequently": "inDepth.frequently",
        "All the time": "inDepth.allTheTime",
        "None": "screening.none",
        "Yes": "screening.yes",
        "No": "screening.no",
        "none": "screening.none",
        "yes": "screening.yes",
        "no": "screening.no"
    }
    const mouseUp = () => {
        if (clicked) {
            if (!fromFollowUp) {
                if (!disabled || answered) {
                    setOpen(!open)
                }
            }
        }
        setClicked(false)
    }
    useEffect(() => {
        if (!fromFollowUp) {
            if (disabled !== undefined) {
                setOpen(!disabled && !answered)
            }
        }
    }, [disabled, answered, fromFollowUp])

    return (
        <div
            onMouseDown={mouseDown}
            onMouseUp={mouseUp}
            className={`${open ? "trueStat" : "falseStat"} ${redHighlight ? "highlight-unanswered-card" : ""}`}
        >
            <Element name={id}></Element>
            <div className={open ? 'focusCard' : (disabled ? "answeredCard" : (answered ? 'answeredCard' : "unansweredCard"))}>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }} className="question">{question}</span>
                    {(!open && answered && !loading) && <i className="fas fa-check-circle"></i>}
                </div>
                {!!loading &&
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">{t('summaryPage.loading')}</span>
                    </div>}
                {(!open && answered && !loading) == true &&
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="responsed">
                            <span>
                                {tranlationObject[answer] ? t(tranlationObject[answer]) : answer}
                            </span>
                        </div>
                    </div>}
                <div onMouseDown={e => e.stopPropagation()}>
                    {open && !loading && children}
                </div>
            </div>
        </div>
    )
}
export default withTranslation()(AssessmentCard);