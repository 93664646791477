import React, { Component, useEffect, useState, useRef } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { WindowDimentions } from "../../utils/WindowDimentions"
import _get from "lodash/get"
import { baseUrl } from "../../lib/constants"
import "../../css/main.css"
import Footer from "../../components/footer/Footer"
import Navbar from "../../components/navbar/Navbar"
import print from "print-js"
import { checkdevice } from "../../utils/checkdevice"
import date from "date-and-time"
import LoadingScreen from "../../components/loading/LoadingScreen"
import SummaryDetails from "./SummaryDetails"
var store = require("store")

export const SummaryDetailsPrintView = (props) => {
  return <SummaryDetails {...props} printView={true} />
}
