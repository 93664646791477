import React, { useState, useEffect, useContext } from "react"
import "../../css/App.css"
import { DefaultButton } from "../../components/buttons/Button"
import { baseUrl } from "../../lib/constants"
import _get from "lodash/get"
import axios from "axios"
import AssessmentCard from "./AssessmentCard"
import { StateContext } from "../../store/state/contexts"
import ScrollToID from "./ScrollToCard"
import { logEvent } from "../../index"
import { withTranslation } from "react-i18next"
const store = require('store')
const options = {
  withCredentials: true,
  mode: "cors",
}

const Screenings = ({
  onQuestionIsPending,
  // lang,
  progressBarMethod,
  onQuestionIsNotPending,
  highlightFirstUnansweredQuestion,
  firstUnansweredQuestion,
  id,
  response,
  isAnswered,
  answerScreening,
  en,
  t,
  fr,
  i18n,
}) => {
  const { state, dispatch } = useContext(StateContext)

  const [lang, setLang] = useState(store.get("lang") ? store.get("lang") : "en")
  const [isOpenedCard, setOpenedCard] = useState(false)
  const [loadCard, setLoadCard] = useState(false)

  useEffect(() => {
    if (firstUnansweredQuestion && firstUnansweredQuestion.id === id) {
      setOpenedCard(true)
      ScrollToID(id)
    }
  }, [
    firstUnansweredQuestion?.id,
    highlightFirstUnansweredQuestion,
    // JSON.stringify(state.questions),
  ])

  useEffect(() => {
    setLang(i18n.language)
  }, [i18n.language, store, t])

  const onClick = (choice) => {
    onQuestionIsPending()
    var dataPost = { id: id, response: choice }
    // answerScreening(choice)
    // setOpenedCard(false)
    // return
    setLoadCard(true)
    axios
      .post(baseUrl + "/assessment/screening-questions", dataPost, options)
      .then(() => {
        logEvent("onClick /assessment/screening-questions successful")
        answerScreening(choice)
        progressBarMethod()
        setLoadCard(false)
        onQuestionIsNotPending()
        setOpenedCard(false)
      })
      .catch(() => {
        logEvent("onClick /assessment/screening-questions failed")
        alert(
          "Something Went Wrong, Please resubmit the question or check your Internet Connection"
        )
        setLoadCard(false)
        onQuestionIsNotPending()
      })
  }

  const redHighlight =
    _get(firstUnansweredQuestion, ["id"]) &&
    id &&
    firstUnansweredQuestion.id === id &&
    highlightFirstUnansweredQuestion

  return (
    <AssessmentCard
      loading={loadCard}
      open={isOpenedCard}
      setOpen={setOpenedCard}
      answered={isAnswered}
      redHighlight={redHighlight}
      id={id}
      question={lang === "fr" ? fr : en}
      answer={response ? "Yes" : "No"}
    >
      <div>
        <DefaultButton
          bgColor={isAnswered && response ? "var(--green)" : "var(--white_300)"}
          btnText={t("screening.yes")}
          onClick={() => onClick(true)}
        />
        <DefaultButton
          bgColor={
            isAnswered && !response ? "var(--green)" : "var(--white_300)"
          }
          btnText={t("screening.no")}
          onClick={() => onClick(false)}
        />
      </div>
    </AssessmentCard>
  )
}

export const Screening = withTranslation()(Screenings);
