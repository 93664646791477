import React from "react"

export const ReviewLogo = ({ isReviewed }) => (
  <svg
    fill={isReviewed ? "rgb(60, 160, 138)" : "#9e9e9e"}
    id="Capaas_1"
    enable-background="new 0 0 512 512"
    viewBox="0 0 512 512"
    width="55px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m457 80h-15v-35c0-24.813-20.187-45-45-45s-45 20.187-45 45v12.285c0 15.544-10.438 29.383-25.385 33.653l-14.615 4.176v-.114c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v67h-177c-24.813 0-45 20.187-45 45v290c0 5.532 3.045 10.615 7.922 13.225 2.218 1.188 4.65 1.775 7.077 1.775 2.91 0 5.812-.846 8.321-2.52l56.222-37.48h285.458c24.813 0 45-20.187 45-45v-157h47c30.327 0 55-24.673 55-55v-80c0-30.327-24.673-55-55-55zm-205 30h30v130h-30zm128 317c0 8.271-6.729 15-15 15h-290c-2.961 0-5.856.877-8.32 2.52l-36.68 24.453v-261.973c0-8.271 6.729-15 15-15h177v63c0 8.284 6.716 15 15 15h60c7.706 0 14.048-5.814 14.896-13.293 13.363 8.522 29.075 13.293 45.415 13.293h22.689zm102-212c0 13.785-11.215 25-25 25h-99.689c-18.238 0-35.271-9.201-45.311-24.246v-89.439l22.856-6.53c27.758-7.931 47.144-33.632 47.144-62.5v-12.285c0-8.271 6.729-15 15-15s15 6.729 15 15v50c0 8.284 6.716 15 15 15h30c13.785 0 25 11.215 25 25z" />
      <path d="m75 282h100c8.284 0 15-6.716 15-15s-6.716-15-15-15h-100c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
      <path d="m335 312h-260c-8.284 0-15 6.716-15 15s6.716 15 15 15h260c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
      <path d="m335 372h-260c-8.284 0-15 6.716-15 15s6.716 15 15 15h260c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
    </g>
  </svg>
)
