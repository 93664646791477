export const baseUrl = "https://apiotip.feelingbetternow.com/api/v1"
// export const baseUrl = "http://localhost:5000/api/v1"

// let isTemp = localStorage.getItem('tempEnv')

export const loginUrl = (nonce = 'defaultNonce') => `https://mensanteid.b2clogin.com/mensanteid.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_fbn_signup_signin&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=${nonce}&redirect_uri=${encodeURIComponent(
  baseUrl + "/callback"
)}&scope=openid&response_type=id_token&prompt=login&response_mode=form_post`
// export const baseUrl = "http://127.0.0.1:5000/api/v1";

export const disorderFiles = [
  "adhd-info.json",
  "AdjustmentDisorder-info.json",
  "Alcohol-info.json",
  "AngerProblems-info.json",
  "BipolarDisorder-info.json",
  "depression-info.json",
  "eatingDisorder-info.json",
  "gad-info.json",
  "insomnia-info.json",
  "ocd-info.json",
  "panicDisorder-info.json",
  "ptsd-info.json",
  "SocialAnxiety-info.json",
]

export const SupportedOrganizations = [
  "AEFO",
  "CUPE",
  "ETFO",
  "EWAO",
  "OCEW",
  "OECTA",
  "OTIP Group of Companies",
  "OSSTF",
  "OTHER GROUPS",
  "RTIP",
  "UNIFOR",
]