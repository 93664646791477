import React, { useContext, useEffect, useState } from "react"
import { Screening } from "../Screening"
import { StateContext } from "../../../store/state/contexts"

export default function ScreeningTab(props) {
  const { state, answerScreening } = useContext(StateContext)

  const [screeningQuestions,setScreeningQuestions] = useState([])
  useEffect(()=> {
    try {
      let _screeningQuestions = state.questionsToShow.questions.screening_questions.questions
      setScreeningQuestions(_screeningQuestions)
    } catch (err) {}

    
  },[JSON.stringify(state.questionsToShow)])
  const answerScreeningRefresh = (index,choice) => {
    answerScreening(index,choice)
    const newList = state.questionsToShow.questions.screening_questions.questions.slice()
    newList.splice(index,1,{response:choice})
    if(newList.findIndex((item)=>{return item.response==true})!=-1
      &&newList.findIndex((item)=>{return item.response===null})===-1)
      {
        console.log('gettingInDepth')
        props.onFetchAttempt();
      }
  }
  return (
    <div>
      {screeningQuestions.map((i, index) => (
        <Screening
          answerScreening={(choice)=>answerScreeningRefresh(index,choice)}
          key={i.id}
          id={i.id}
          response={i.response}
          isAnswered={i.isAnswered}
          lang={props.lang}
          question_type={props.question_type}
          en={i.en.text}
          fr={i.fr.text}
          progressBarMethod={props.progressBarMethod}
          onQuestionIsNotPending={props.onQuestionIsNotPending}
          onQuestionIsPending={props.onQuestionIsPending}
          firstUnansweredQuestion={state.firstUnansweredQuestion}
          highlightFirstUnansweredQuestion={
            state.highlightFirstUnansweredQuestion
          }
        />
      ))}
    </div>
  )
}
