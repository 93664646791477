import React, { useContext, useState, useEffect } from "react"
import { StateContext } from "../../store/state/contexts"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { AssessmentTimer } from "./AssessmentTimer.jsx"
import { baseUrl } from "../../lib/constants"
import { Link } from "react-router-dom"
import Sticky from "react-sticky-el"
/** @jsx jsx */
import "../../css/App.css"
import { css, jsx } from "@emotion/react"
import _get from "lodash/get"
import styled from "@emotion/styled"
import {
  SharedSummaryProgressBar,
  SharedSummaryProgressBarInnerLine,
} from "../../utils/shared"
import as1SVG from "../../assets/images/as1.svg"
import as2SVG from "../../assets/images/as2.svg"
import as3SVG from "../../assets/images/as3.svg"
import as4SVG from "../../assets/images/as4.svg"
import { withTranslation } from "react-i18next"

const EHeader = styled.h1`
  color: #151852;
  font-size: 1.5em;
  font-weight: 800;
  margin-top: 1em;
  margin: 0;
`

const EH2 = styled.h2`
  color: #383940;
  font-size: 1em;
  font-weight: 800;
  margin: 0.8em 0 0.3em 0;
`

const percentCss = css`
  color: #afafb1;
  font-size: 1em;
  font-weight: 300;
  margin: 0;
`

const EPercent = styled.h2`
  ${percentCss}
`

const ENextAssessment = styled.p`
  color: #707070;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.2em;
  margin: 0.8em 0 2.2em 0;
`

const EIconSVG = styled.img`
  width: 2.25em;
  filter: ${(props) =>
    props.active
      ? "grayscale(0) brightness(1)"
      : "grayscale(1) brightness(1.2)"};
`

const EAssessmentElement = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 0.7em;
  grid-template-columns: 2.25em 1fr 2.4em 1.6em;
  h5 {
    ${percentCss}

    text-align: end;
  }
  margin-bottom: 1em;
`

const EAssessmentElementCaption = styled.p`
  font-weight: 500;
  font-size: 1em;
  color: ${(props) => (props.active ? "#383940" : "#AFAFB1")};
  margin: 0;
`

const ProgressbarComponent = React.memo(
  ({ value }) => {
    return (
      <div
      // css={css`
      //   width: 1.6em;
      // `}
      >
        <CircularProgressbar
          value={value}
          minValue={0}
          strokeWidth={12}
          maxValue={100}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathTransitionDuration: 0.5,
            pathColor: `#33CA8F`,
            background: "white",
            trailColor: "#E7E7E7",
            backgroundColor: "#fff",
          })}
        />
      </div>
    )
  },
  (prev, next) => prev.value === next.value
)

export const AssessmentSidebar = (props) => {
  const { state, dispatch } = useContext(StateContext)
  const [width, setWidth] = useState(window.innerWidth);

  const {t, i18n, history} = props;

  let indepthQuestions = []

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  try {
    indepthQuestions =
      state.questionsToShow.questions.in_depth_questions.questions
  } catch (err) {}

  function indepthDisorders(props) {
    return (
      props.tab === 2 &&
      indepthQuestions && (
        <div className="my-2">
          {indepthQuestions.map((disorder, i) => {
            return (
              <div className="d-flex" key={i}>
                <i className="fas fa-comments mr-2 notActiveIcon invisible"></i>
                <span
                  // className={i === props.indepthTab - 1 ? "text-dark" : ""}
                  style={{
                    fontSize: 17,
                    color: i === props.indepthTab - 1 ? "black" : "#AFAFB1",
                  }}
                >
                  {`${t("sidebar.step")} ${i + 1}`}
                </span>
              </div>
            )
          })}
        </div>
      )
    )
  }

  const { tab } = props

  if (!_get(state, "assessment.questions.health_history.questions")) {
    return null
  }

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  const isMobile = width <= 768;

  return (
    <Sticky boundaryElement="#importantStickyBoundary" disabled={isMobile}>
      <div
        className="nav flex-column m-3"
        css={css`
          margin-top: 0 !important;
        `}
      >
        <div
          css={css`
            margin-top: 3em;
          `}
        >
          <h2 className="mb-4 titleAsmnt">
          {t("sidebar.title")}
          </h2>
        </div>
        <div className="d-flex justify-content-between">
          <p className="heading mb-0">{t("sidebar.progress")}</p>
          <p className="percent mb-0">{state.percentage.main + "%"}</p>
        </div>
        <div className="progress mt-2 mb-4">
          <div
            className="progress-bar progress-bar-success"
            role="progressbar"
            aria-valuenow={state.percentage.main}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: state.percentage.main + "%" }}
          ></div>
        </div>

        <div
          className="border-muted mt-4"
          css={css`
            @media (min-width: 991px) {
              border-right: 1px solid #dee2e6;
            }
          `}
        >
          <div className="d-flex justify-content-between align-items-center mr-1 flex-wrap">
            <div>
              <i
                className={
                  tab === 1
                    ? "fas fa-laptop mr-2 activeIcon"
                    : "fas fa-laptop mr-2 notActiveIcon"
                }
              ></i>
              <span className={tab === 1 ? "darkMenuText" : "lightMenuText"}>
              {t("sidebar.screening")}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="mr-1 percent">
                {state.percentage.screeningQuestions}%
              </span>
              <div className="mr-2 circularProgressbarStyle">
                <ProgressbarComponent
                  value={state.percentage.screeningQuestions}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mr-1 mt-3 flex-wrap">
            <div>
              <i
                className={
                  tab === 2
                    ? "fas fa-comments mr-2 activeIcon"
                    : "fas fa-comments mr-2 notActiveIcon"
                }
              ></i>
              <span className={tab === 2 ? "darkMenuText" : "lightMenuText"}>
              {t("sidebar.inDepth")}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="mr-1 percent">
                {state.percentage.inDepthQuestions}%
              </span>
              <div className="mr-2 circularProgressbarStyle">
                <ProgressbarComponent
                  value={state.percentage.inDepthQuestions}
                />
              </div>
            </div>
          </div>

          {indepthDisorders(props)}

          <div className="d-flex justify-content-between align-items-center mr-1 mt-3 flex-wrap">
            <div>
              <i
                className={
                  tab === 3
                    ? "fas fa-redo mr-2 activeIcon"
                    : "fas fa-redo mr-2 notActiveIcon"
                }
              ></i>
              <span className={tab === 3 ? "darkMenuText" : "lightMenuText"}>
              {t("sidebar.health")}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="mr-1 percent">
                {state.percentage.healthHistory}%
              </span>
              <div className="mr-2 circularProgressbarStyle">
                <ProgressbarComponent value={state.percentage.healthHistory} />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mr-1 mt-3 flex-wrap">
            <div>
              <i
                className={
                  tab === 4
                    ? "fas fa-podcast mr-2 activeIcon"
                    : "fas fa-podcast mr-2 notActiveIcon"
                }
              ></i>
              <span className={tab === 4 ? "darkMenuText" : "lightMenuText"}>
              {t("sidebar.life")}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="mr-1 percent">
                {state.percentage.lifeFunctions}%
              </span>
              <div className="mr-2 circularProgressbarStyle">
                <ProgressbarComponent value={state.percentage.lifeFunctions} />
              </div>
            </div>
          </div>

                
          {history && history.length > 0 && <div className="d-flex justify-content-between align-items-center mr-1 mt-3 flex-wrap">
            <div>
              <i
                className={
                  tab === 5
                    ? "fas fa-envelope-open mr-2 activeIcon"
                    : "fas fa-envelope-open mr-2 notActiveIcon"
                }
              ></i>
              <span className={tab === 5 ? "darkMenuText" : "lightMenuText"}>
              {t("sidebar.follow")}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="mr-1 percent">
                {state.percentage.followUp}%
              </span>
              <div className="mr-2 circularProgressbarStyle">
                <ProgressbarComponent value={state.percentage.followUp} />
              </div>
            </div>
          </div>}


          {/* <AssessmentTimer viewPopup={this.viewPopup} />   */}
        </div>

        <AssessmentTimer screen="main" />
      </div>
    </Sticky>
  )
}
