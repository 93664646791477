import React, { useEffect, useState } from "react"
import { AssessmentHistoryNew } from "./AssessmentHistoryNew"
import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"

const a1 = keyframes`
  0% {
    filter: opacity(0);
  }
  
  40% {
    filter: opacity(0);
  }

  100% {
    filter: opacity(1);
  }
`

const EWrapper = styled.section`
  transform: translateX(-100vw);
  transition: transform 0.5s;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 99999999;

  &[data-open="true"] {
    transform: translateX(0px);
  }
`

const EBg = styled.div`
  transition: background 0.5s, box-shadow 0.5s;
  background: #00000066;
  box-shadow: ${(props) =>
    props.open ? "inset 0 0 10vw #ffffff00" : "inset 0 0 10vw #ffffff00"};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  animation: ${a1} 800ms ease-in-out forwards;
`

export const AssessmentsHistoryWrapper = React.memo(
  ({ close, open }) => {
    return (
      <EWrapper data-open={open}>
        <>
          {open && (
            <EBg
              open={open}
              onClick={() => {
                close()
              }}
            />
          )}
          <AssessmentHistoryNew open={open} handleClose={close} />
        </>
      </EWrapper>
    )
  },
  (prev, next) => prev.open === next.open
)
