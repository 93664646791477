import React from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { withTranslation } from "react-i18next"
import styled from "@emotion/styled"
import {
  SharedDisorderBackground,
  SharedDisorderHeaderText,
} from "../../utils/shared"

const ETable = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    color: #fff;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
  }

  > div:nth-of-type(2) {
    border-right: 1px solid #e7e7e7;
  }

  > div:nth-of-type(odd) {
    background-color: #fff;
  }

  > div:nth-of-type(even) {
    background-color: #f0fbf7;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: transparent;
    color: #505050;
    min-height: 2.8em;

    > div,
    h4 {
      display: flex;
      align-items: center;
      padding-left: 12px;
    }
  }

  > div:nth-of-type(1) {
    border-bottom: 2px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
    color: #fff;
    background-color: #5ec89e;

    h4 {
      margin: 0;
      font-size: 0.9em;
      text-transform: uppercase;
    }
  }
`

const Medication = ({ medication, riskStatus, name, lang, t, i18n }) => {
  let medicationAvailable = {}
  let medicationsProcessed = []
  for(let key in medication) {
    let i = medication[key];
    let med = `${i.brand_name.text["en"]}_${typeof i.sci_name === "object"
    ? i.sci_name.text[lang]
    : i.sci_name}_${typeof i.dose === "object" ? i.dose.text[lang] : i.dose}_${typeof i.initial_dose === "object"
    ? i.initial_dose.text[lang]
    : i.initial_dose}`
    if(!medicationAvailable[med]) {
      medicationsProcessed.push(i)
      medicationAvailable[med] = true
    }
  }
  return (
  <SharedDisorderBackground
    css={css`
      width: 97.8%;
      margin: 16px auto 0 auto;

      @media (max-width: 1200px) {
        width: 97.8%;
      }

      @media (max-width: 640px) {
        display: none;
      }
    `}
  >
    <SharedDisorderHeaderText>{t("papPage.medication")} </SharedDisorderHeaderText>
     <p style={{color:"#69696f"}}>{t("papPage.medicationDescription")}</p>
    <ETable>
      <div>
        <h4>{t("papPage.tradeName")}</h4>
        <h4>{t("papPage.drug")}</h4>
        <h4>{t("papPage.dose")}</h4>
        <h4>{t("papPage.initialDose")}</h4>
      </div>
      {medicationsProcessed.map((i, index) => (
        <React.Fragment key={`${name}${i.brand_name.text[lang]}`}>
          <div>
            <div>{i.brand_name.text["en"]}</div>
            <div>
              {typeof i.sci_name === "object"
                ? i.sci_name.text[lang]
                : i.sci_name}
            </div>
            <div>{typeof i.dose === "object" ? i.dose.text[lang] : i.dose}</div>
            <div>
              {typeof i.initial_dose === "object"
                ? i.initial_dose.text[lang]
                : i.initial_dose}
            </div>
          </div>
        </React.Fragment>
      ))}
    </ETable>
  </SharedDisorderBackground>
)}

export default withTranslation()(Medication);