import React from 'react'
export default ({ inputRef, msg = 'Manage Your Daily Action', showButton = true }) => {
    return (
        <>
        <div className="image-part">
        <svg className="no-content-image" width="748" height="480" viewBox="0 0 748 480" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1852_99)">
            <path d="M654.08 478.343C654.08 478.343 709.18 491.42 736.315 432.206C771.524 355.373 722.103 282.408 636.89 277.159C513.981 269.577 582.097 73.3343 388.157 126.593C333.609 141.572 214.998 64.2788 145.164 195.23C133.284 217.485 97.7991 216.41 50.4959 254.382C-32.937 321.392 -2.8852 479.08 67.3789 479.786C137.674 480.492 654.08 478.343 654.08 478.343Z" fill="#D2E4FD"/>
            <path d="M79.6875 479.479C79.6875 479.479 82.2353 462.688 114.927 458.176C147.619 453.663 178.008 417.441 231.942 422.69C285.875 427.94 330.6 460.938 391.962 450.808C453.324 440.709 491.756 453.571 505.508 463.732C519.26 473.892 576.233 461.337 580.438 479.97L79.6875 479.479Z" fill="#33CA8F"/>
            <path d="M214.076 79.4432C214.076 79.4432 177.179 77.6321 196.764 190.104C196.764 190.104 209.932 228.29 193.295 252.878H437.976V79.4432H214.076Z" fill="#BBD5FC"/>
            <path d="M239.219 371.826C239.219 371.826 222.121 351.873 238.175 339.226C238.175 339.226 249.901 332.136 259.448 332.719C259.448 332.719 245.911 355.434 250.3 357.675C254.69 359.946 263.561 331.307 263.561 331.307C263.561 331.307 290.236 328.728 298.801 332.842C298.801 332.842 275.993 351.505 280.812 352.61C285.601 353.715 310.22 335.42 310.22 335.42C310.22 335.42 335.022 339.656 333.702 354.789C332.383 369.923 323.818 374.589 320.073 374.19C316.328 373.791 294.319 365.41 293.858 368.48C293.367 371.55 306.628 381.618 315.868 382.079C315.868 382.079 298.371 401.387 286.737 395.8C275.103 390.244 274.121 381.403 266.876 378.088C259.632 374.773 253.431 374.773 256.44 378.149C259.417 381.526 272.555 384.811 276.699 392.147C280.843 399.483 284.557 404.426 272.524 402.614C260.491 400.803 240.938 391.993 240.078 381.925C239.219 371.826 239.219 371.826 239.219 371.826Z" fill="#69B78B"/>
            <path d="M321.486 355.127C321.486 355.127 254.077 352.518 239.189 371.795C239.189 371.795 235.904 381.649 225.774 390.643L224.209 394.94C224.209 394.94 235.874 382.631 240.079 381.894C240.048 381.894 233.541 358.013 321.486 355.127Z" fill="#69B78B"/>
            <path d="M225.774 390.643C225.774 390.643 211.316 401.356 210.303 420.449L212.943 420.695C212.943 420.695 217.578 400.926 225.927 393.191C234.277 385.455 225.774 390.643 225.774 390.643Z" fill="#69B78B"/>
            <path d="M179.422 346.747C179.422 346.747 206.619 331.522 193.91 310.863C193.91 310.863 183.75 298.185 172.883 294.901C172.883 294.901 178.685 325.72 172.883 326.457C167.082 327.193 168.893 291.647 168.893 291.647C168.893 291.647 140.253 277.864 129.018 278.969C129.018 278.969 146.791 309.052 140.99 308.346C135.188 307.609 115.235 277.158 115.235 277.158C115.235 277.158 85.859 271.725 81.1625 289.13C76.4352 306.535 84.0479 315.222 88.4068 316.327C92.7657 317.401 120.669 317.063 119.963 320.686C119.226 324.308 100.378 330.109 89.8802 326.856C89.8802 326.856 101.484 355.495 116.709 354.053C131.934 352.61 136.662 343.186 146.085 342.449C155.509 341.713 162.416 344.261 157.689 346.808C152.961 349.356 137.03 347.637 129.417 354.114C121.805 360.591 115.635 364.582 129.786 367.467C143.937 370.383 169.292 368.541 174.387 357.675C179.422 346.747 179.422 346.747 179.422 346.747Z" fill="#33CA8F"/>
            <path d="M94.6074 294.532C94.6074 294.532 170.735 319.181 179.452 346.747C179.452 346.747 179.084 359.087 186.697 373.207V378.64C186.697 378.64 178.716 360.161 174.357 357.613C174.357 357.613 191.393 333.67 94.6074 294.532Z" fill="#33CA8F"/>
            <path d="M186.697 373.207C186.697 373.207 198.454 391.042 191.732 412.744L188.723 411.946C188.723 411.946 191.67 388.034 185.5 376.001C179.361 363.968 186.697 373.207 186.697 373.207Z" fill="#33CA8F"/>
            <path d="M222.704 325.72C222.704 325.72 186.851 305.644 203.581 278.417C203.581 278.417 216.964 261.687 231.299 257.39C231.299 257.39 223.656 298.001 231.299 298.983C238.943 299.935 236.549 253.092 236.549 253.092C236.549 253.092 274.305 234.92 289.131 236.363C289.131 236.363 265.71 276.022 273.354 275.071C280.997 274.119 307.273 233.968 307.273 233.968C307.273 233.968 345.981 226.785 352.213 249.746C358.413 272.676 348.376 284.157 342.666 285.6C336.926 287.042 300.121 286.551 301.072 291.34C302.024 296.128 326.888 303.772 340.732 299.474C340.732 299.474 325.445 337.231 305.37 335.328C285.294 333.425 279.094 320.993 266.662 320.041C254.23 319.089 245.144 322.435 251.375 325.781C257.576 329.127 278.603 326.886 288.64 335.42C298.678 343.953 306.813 349.203 288.149 353.04C269.516 356.877 236.057 354.482 229.366 340.147C222.704 325.72 222.704 325.72 222.704 325.72Z" fill="#33CA8F"/>
            <path d="M334.532 256.898C334.532 256.898 234.155 289.406 222.705 325.72C222.705 325.72 223.196 341.958 213.158 360.622V367.805C213.158 367.805 223.687 343.432 229.397 340.086C229.397 340.055 206.927 308.53 334.532 256.898Z" fill="#33CA8F"/>
            <path d="M213.127 360.622C213.127 360.622 197.656 384.135 206.466 412.744L210.457 411.67C210.457 411.67 206.589 380.145 214.693 364.305C222.797 348.466 213.127 360.622 213.127 360.622Z" fill="#33CA8F"/>
            <path d="M221.476 474.782C260.246 474.782 291.679 443.349 291.679 404.579H151.273C151.273 443.349 182.707 474.782 221.476 474.782Z" fill="#5153FF"/>
            <path d="M238.758 474.26C235.904 474.628 232.987 474.812 230.041 474.812C191.271 474.812 159.838 443.379 159.838 404.609H177.273C177.273 440.401 204.102 469.962 238.758 474.26Z" fill="#5153FF"/>
            <path d="M292.661 478.343H150.291C150.291 473.554 154.159 469.687 158.947 469.687H284.005C288.793 469.687 292.661 473.585 292.661 478.343Z" fill="#5153FF"/>
            <path d="M293.305 404.579H148.971V406.728H293.305V404.579Z" fill="#5153FF"/>
            <path d="M475.581 137.582C475.581 137.582 463.272 114.375 481.72 105.535C481.72 105.535 494.705 101.176 503.914 103.846C503.914 103.846 485.711 123.032 489.486 126.193C493.262 129.355 508.211 103.355 508.211 103.355C508.211 103.355 534.794 106.67 542.253 112.595C542.253 112.595 515.916 125.794 520.367 127.912C524.818 130.061 552.844 117.598 552.844 117.598C552.844 117.598 576.142 127.176 571.507 141.634C566.903 156.122 557.54 158.793 553.949 157.565C550.357 156.337 530.773 143.322 529.606 146.238C528.471 149.124 539.153 161.863 548.116 164.349C548.116 164.349 526.813 179.329 516.683 171.348C506.553 163.367 507.536 154.526 501.182 149.707C494.827 144.888 488.811 143.537 490.99 147.497C493.17 151.457 505.234 157.534 507.689 165.577C510.114 173.65 512.662 179.268 501.335 174.878C490.008 170.458 472.849 157.596 474.23 147.558C475.581 137.582 475.581 137.582 475.581 137.582Z" fill="#69B78B"/>
            <path d="M559.504 139.393C559.504 139.393 494.304 122.05 475.549 137.613C475.549 137.613 470.177 146.515 458.328 153.053L455.842 156.859C455.842 156.859 469.931 147.405 474.168 147.589C474.198 147.62 473.062 122.878 559.504 139.393Z" fill="#69B78B"/>
            <path d="M458.328 153.023C458.328 153.023 441.875 160.298 436.688 178.715L439.205 179.514C439.205 179.514 448.045 161.249 457.899 155.54C467.752 149.83 458.328 153.023 458.328 153.023Z" fill="#69B78B"/>
            <path d="M394.573 95.7118C394.573 95.7118 358.136 76.7108 374.068 48.9919C374.068 48.9919 386.96 31.8633 401.142 27.136C401.142 27.136 394.696 67.9623 402.37 68.699C410.044 69.4357 406.268 22.6851 406.268 22.6851C406.268 22.6851 443.472 3.40771 458.33 4.38999C458.33 4.38999 436.105 44.7251 443.718 43.5586C451.331 42.3922 476.41 1.47383 476.41 1.47383C476.41 1.47383 514.903 -6.8449 521.779 15.9012C528.686 38.6472 518.986 50.4039 513.276 52.0001C507.597 53.5964 470.792 54.2103 471.897 58.9682C473.002 63.7262 498.051 70.6329 511.803 65.9056C511.803 65.9056 497.652 104.092 477.515 102.803C457.409 101.483 450.809 89.2656 438.377 88.6824C425.914 88.0991 416.951 91.7213 423.274 94.883C429.598 98.0448 450.533 95.1593 460.816 103.386C471.099 111.613 479.387 116.616 460.877 121.006C442.367 125.395 408.847 123.983 401.725 109.863C394.573 95.7118 394.573 95.7118 394.573 95.7118Z" fill="#33CA8F"/>
            <path d="M504.313 23.6064C504.313 23.6064 404.949 59.0915 394.573 95.743C394.573 95.743 395.525 111.981 386.07 130.89L386.285 138.043C386.285 138.043 396.077 113.363 401.695 109.863C401.695 109.863 378.304 79.0135 504.313 23.6064Z" fill="white"/>
            <path d="M386.071 130.891C386.071 130.891 371.306 154.834 380.975 183.167L384.935 181.97C384.935 181.97 380.146 150.598 387.759 134.513C395.372 118.428 386.071 130.891 386.071 130.891Z" fill="white"/>
            <path d="M485.034 146.023H229.578V479.172H485.034V146.023Z" fill="#9ABCEF"/>
            <path d="M489.086 146.023H237.928V479.172H489.086V146.023Z" fill="#F8FBFF"/>
            <path d="M666.205 478.374H615.586C612.885 476.071 617.459 470.853 622.247 470.485C624.335 470.331 626.975 470.761 629.983 471.405C630.689 462.166 634.649 420.572 649.659 422.23C666.512 424.102 649.659 440.556 644.901 450.286C641.371 457.5 637.657 467.845 635.938 472.725C637.319 473.032 638.731 473.309 640.174 473.585C642.415 466.74 650.734 443.656 661.293 445.007C673.695 446.572 668.722 455.842 659.912 461.337C653.466 465.389 645.546 471.129 641.77 473.892C644.963 474.414 648.278 474.751 651.655 474.69C660.342 474.444 664.578 476.992 666.205 478.374Z" fill="#33CA8F"/>
            <path d="M489.118 146.023H237.929C239.311 127.022 237.223 113.24 233.877 103.325C227.431 84.2623 216.35 79.4736 216.35 79.4736H453.234C473.463 79.4736 489.118 91.6601 493.385 107.806C493.538 108.42 493.692 109.034 493.815 109.618C497.867 128.127 489.272 145.686 489.118 146.023Z" fill="#F8FBFF"/>
            <path d="M489.118 146.023H237.929C239.31 127.022 237.223 113.239 233.877 103.324C259.539 108.021 323.111 118.028 391.165 116.34C435.184 115.234 469.963 112.288 493.814 109.617C497.866 128.127 489.271 145.685 489.118 146.023Z" fill="#F8FBFF"/>
            <path d="M425.145 222.826H287.196C287.134 222.826 287.104 222.765 287.104 222.734V216.687C287.104 216.625 287.165 216.595 287.196 216.595H425.145C425.207 216.595 425.237 216.656 425.237 216.687V222.734C425.237 222.795 425.207 222.826 425.145 222.826Z" fill="#BBD5FC"/>
            <path d="M373.054 233.355H287.196C287.134 233.355 287.104 233.294 287.104 233.263V227.216C287.104 227.155 287.165 227.124 287.196 227.124H373.084C373.146 227.124 373.176 227.185 373.176 227.216V233.263C373.176 233.294 373.115 233.355 373.054 233.355Z" fill="#BBD5FC"/>
            <path d="M274.242 232.342V219.572H272.554C271.541 219.572 271.019 219.143 271.019 218.283C271.019 217.669 271.418 217.239 272.216 216.994C272.615 216.871 273.291 216.748 274.181 216.687C275.071 216.625 275.808 216.595 276.33 216.595C276.851 216.595 277.189 216.687 277.312 216.902C277.435 217.117 277.527 217.669 277.527 218.529V232.342H279.276C280.412 232.342 280.965 232.833 280.965 233.785C280.965 234.798 280.412 235.32 279.276 235.32H272.278C271.786 235.32 271.357 235.197 271.05 234.92C270.712 234.644 270.559 234.276 270.559 233.815C270.559 232.833 271.142 232.342 272.278 232.342H274.242Z" fill="#BBD5FC"/>
            <path d="M425.145 288.639H287.196C287.134 288.639 287.104 288.577 287.104 288.547V282.499C287.104 282.438 287.165 282.407 287.196 282.407H425.145C425.207 282.407 425.237 282.469 425.237 282.499V288.547C425.237 288.577 425.207 288.639 425.145 288.639Z" fill="#D2E4FD"/>
            <path d="M373.054 299.138H287.196C287.134 299.138 287.104 299.076 287.104 299.045V292.998C287.104 292.937 287.165 292.906 287.196 292.906H373.084C373.146 292.906 373.176 292.968 373.176 292.998V299.045C373.176 299.107 373.115 299.138 373.054 299.138Z" fill="#D2E4FD"/>
            <path d="M281.21 297.879C281.21 298.738 280.627 299.168 279.43 299.168H272.032C270.957 299.168 270.436 298.738 270.436 297.879C270.436 297.602 270.589 297.295 270.865 296.927C271.295 296.436 271.909 295.73 272.707 294.748C273.935 293.213 274.979 291.862 275.807 290.726C276.084 290.358 276.513 289.775 277.035 289.007C277.465 288.24 277.68 287.595 277.68 287.074C277.68 286.49 277.496 285.999 277.127 285.631C276.759 285.262 276.268 285.048 275.685 285.048C275.101 285.048 274.61 285.232 274.181 285.6C273.904 285.846 273.659 286.306 273.505 286.951C273.29 287.81 273.168 288.301 273.106 288.424C272.891 288.915 272.523 289.161 272.001 289.161C271.541 289.161 271.203 289.007 270.927 288.67C270.681 288.393 270.558 287.994 270.558 287.534C270.558 287.012 270.65 286.46 270.804 285.938C271.172 284.771 271.817 283.881 272.707 283.267C273.567 282.684 274.641 282.377 275.899 282.377C277.281 282.377 278.417 282.776 279.337 283.605C280.289 284.464 280.75 285.6 280.75 286.951C280.75 287.964 280.412 289.007 279.737 290.082C279.368 290.665 278.601 291.709 277.404 293.213C276.79 293.98 275.869 295.085 274.549 296.559H279.43C280.627 296.559 281.21 296.988 281.21 297.879Z" fill="#D2E4FD"/>
            <path d="M425.147 343.524H287.198C287.136 343.524 287.105 343.463 287.105 343.432V337.385C287.105 337.324 287.167 337.293 287.198 337.293H425.147C425.209 337.293 425.239 337.354 425.239 337.385V343.432C425.239 343.463 425.209 343.524 425.147 343.524Z" fill="#D2E4FD"/>
            <path d="M373.055 354.022H287.198C287.136 354.022 287.105 353.961 287.105 353.93V347.883C287.105 347.822 287.167 347.791 287.198 347.791H373.086C373.148 347.791 373.178 347.852 373.178 347.883V353.93C373.178 353.992 373.117 354.022 373.055 354.022Z" fill="#D2E4FD"/>
            <path d="M281.211 348.804C281.211 350.4 280.658 351.689 279.523 352.641C278.448 353.562 277.128 354.022 275.501 354.022C274.243 354.022 273.168 353.715 272.278 353.132C270.866 352.18 270.16 351.044 270.16 349.694C270.16 349.295 270.283 348.988 270.529 348.711C270.774 348.435 271.081 348.282 271.48 348.282C271.879 348.282 272.248 348.558 272.616 349.08C273.199 349.97 273.506 350.461 273.598 350.553C274.12 351.106 274.765 351.351 275.563 351.351C276.637 351.351 277.405 350.952 277.865 350.154C278.08 349.755 278.203 349.233 278.203 348.619C278.203 347.115 277.312 346.286 275.563 346.102C274.55 346.01 274.059 345.58 274.059 344.844C274.059 344.291 274.335 343.892 274.918 343.677C275.992 343.278 276.545 343.063 276.576 343.063C277.159 342.664 277.435 342.02 277.435 341.068C277.435 340.485 277.282 340.055 276.944 339.717C276.606 339.38 276.146 339.226 275.593 339.226C274.887 339.226 274.335 339.41 273.936 339.748C273.598 340.055 273.322 340.546 273.076 341.252C272.892 341.805 272.554 342.05 272.033 342.05C271.664 342.05 271.357 341.897 271.112 341.559C270.866 341.222 270.743 340.884 270.743 340.485C270.743 339.994 270.866 339.533 271.142 339.042C271.572 338.244 272.248 337.63 273.138 337.17C273.967 336.74 274.857 336.556 275.808 336.556C277.128 336.556 278.203 336.955 279.062 337.753C279.922 338.551 280.351 339.595 280.351 340.884C280.351 342.449 279.707 343.677 278.417 344.567C280.29 345.335 281.211 346.747 281.211 348.804Z" fill="#D2E4FD"/>
            <path d="M409.706 168.155H321.822C321.761 168.155 321.699 168.094 321.699 168.033V161.034C321.699 160.973 321.761 160.911 321.822 160.911H409.706C409.767 160.911 409.829 160.973 409.829 161.034V168.033C409.829 168.094 409.767 168.155 409.706 168.155Z" fill="#BBD5FC"/>
            <path d="M431.93 179.329H299.597C299.536 179.329 299.475 179.268 299.475 179.207V172.208C299.475 172.146 299.536 172.085 299.597 172.085H431.93C431.991 172.085 432.052 172.146 432.052 172.208V179.207C432.052 179.268 431.991 179.329 431.93 179.329Z" fill="#BBD5FC"/>
            <path d="M356.018 256.53C328.667 256.53 302.698 253.092 283.329 248.181C263.775 243.208 253.246 235.104 253.246 227.921C253.246 221.414 261.841 215.428 278.08 210.608C262.516 215.244 254.198 222.028 254.198 227.921C254.198 234.49 264.972 240.998 283.789 245.756C303.066 250.637 328.729 253.338 356.018 253.338C383.307 253.338 408.969 250.637 428.247 245.756C447.064 240.998 457.838 234.49 457.838 227.921C457.838 220.953 446.48 212.297 428.185 205.359C407.404 197.44 381.741 194.493 355.987 194.493C307.64 194.493 287.749 200.141 270.467 207.201C287.81 200.11 307.456 192.037 355.987 192.037C381.834 192.037 407.588 196.427 428.492 204.377C447.156 211.499 458.759 220.493 458.759 227.921C458.759 235.104 447.984 241.796 428.431 246.769C409.123 251.68 383.399 256.53 356.018 256.53Z" fill="#5153FF"/>
            <path d="M522.177 320.656C522.177 320.656 521.286 329.404 520.273 331.062C519.291 332.72 515.086 337.999 513.275 339.043C511.494 340.087 514.38 332.075 514.011 330.51C513.643 328.944 512.538 333.364 511.74 333.456C510.942 333.549 511.157 329.589 511.126 328.146C511.095 326.703 514.963 323.142 516.344 320.073C517.726 317.034 522.177 320.656 522.177 320.656Z" fill="#FC9490"/>
            <path d="M414.555 239.893L412.529 236.087C412.529 236.087 413.542 229.303 405.039 225.036C405.039 225.036 401.939 223.778 400.189 226.295C398.593 228.628 403.259 228.198 403.013 232.189C402.767 236.179 404.64 238.512 407.28 239.801L409.459 242.318L414.555 239.893Z" fill="#FC9490"/>
            <path d="M494.704 230.807C494.704 230.807 487.429 266.568 447.34 278.233C434.816 281.886 406.391 240.691 406.391 240.691C406.391 240.691 407.404 236.885 413.666 237.775C413.666 237.775 441.354 256.101 444.823 258.096C447.923 259.877 468.244 235.381 477.514 231.452C486.785 227.461 494.704 230.807 494.704 230.807Z" fill="#5153FF"/>
            <path d="M388.059 223.764L386.922 231.167L424.149 236.884L425.286 229.481L388.059 223.764Z" fill="#5153FF"/>
            <path d="M378.855 226.142L386.929 231.176L388.064 223.778L378.855 226.142Z" fill="#FC9490"/>
            <path d="M429.228 232.618L428.86 234.951C428.645 236.394 427.295 237.376 425.852 237.161L424.133 236.885L425.269 229.487L427.141 229.764C428.522 229.978 429.443 231.237 429.228 232.618Z" fill="#FC9490"/>
            <path d="M542.558 455.505C542.558 455.505 547.531 460.048 546.548 463.271C545.566 466.494 541.084 468.643 540.47 472.572C539.856 476.501 537.431 478.65 535.252 478.65C533.073 478.65 524.846 478.65 524.846 478.65C524.846 478.65 523.25 475.887 527.117 475.028C530.985 474.199 532.459 469.564 532.213 465.266C531.968 460.969 532.581 455.228 532.581 455.228L540.839 453.325L542.558 455.505Z" fill="#00C6A0"/>
            <path d="M481.565 467.262C481.565 467.262 482.455 477.576 480.276 478.65L452.342 478.589C452.342 478.589 452.342 475.365 456.823 474.659C461.305 473.953 469.194 471.313 470.514 465.45C471.865 459.618 481.565 467.262 481.565 467.262Z" fill="#00C6A0"/>
            <path d="M457.99 321.392C457.99 321.392 459.464 339.503 461.183 361.267C463.546 390.828 466.37 427.141 466.647 434.017C467.138 445.958 469.072 465.052 469.072 465.052C469.072 465.052 473.676 469.595 482.885 467.691C482.885 467.691 491.112 440.433 493.445 411.578C493.475 411.118 493.506 410.627 493.567 410.166C495.747 380.79 499.154 318.937 499.154 318.937L457.99 321.392Z" fill="#33CA8F"/>
            <path d="M457.99 321.392C457.99 321.392 459.464 339.503 461.183 361.266L493.475 411.547C493.506 411.087 493.537 410.596 493.598 410.135C495.778 380.759 499.185 318.905 499.185 318.905L457.99 321.392Z" fill="#00C6A0"/>
            <path d="M464.713 323.541C464.713 323.541 491.265 389.108 508.732 419.897C526.198 450.716 531.662 459.311 531.662 459.311C531.662 459.311 541.669 455.382 542.59 452.681C542.59 452.681 535.898 393.191 524.203 378.702C517.603 370.506 509.837 345.918 509.468 337.323C509.1 328.728 507.105 311.723 507.105 311.723L464.713 323.541Z" fill="#33CA8F"/>
            <path d="M499.645 222.52C499.645 222.52 481.688 231.176 471.312 227.984C471.312 227.984 473.86 224.76 478.311 222.366C477.943 220.279 477.667 218.468 477.513 216.964C477.083 213.433 477.083 211.438 477.083 211.438L484.021 205.974L490.59 200.756C490.099 215.398 499.645 222.52 499.645 222.52Z" fill="#FC9490"/>
            <path d="M509.132 315.069C509.163 316.174 506.83 317.433 505.786 317.893C498.081 321.27 490.929 323.634 482.794 324.401C473.186 325.383 461.829 323.235 457.93 323.296C457.531 323.296 457.163 323.142 456.856 322.866C456.58 322.682 456.365 311.109 456.58 296.529C456.58 295.945 456.58 295.362 456.61 294.748V294.564C456.702 288.885 456.887 282.899 457.163 276.944C457.715 263.837 458.759 251.221 460.509 244.222C462.627 235.995 467.416 230.531 471.744 226.571L475.335 223.44C478.927 224.146 482.702 223.962 486.478 222.12C490.622 220.125 495.073 217.976 495.81 218.498C501.243 222.458 504.896 231.36 506.86 242.748C507.965 249.287 508.549 256.623 508.487 264.328C508.487 267.152 508.395 270.068 508.242 272.923C507.29 288.455 508.242 301.962 508.794 309.759C508.948 312.061 509.071 313.811 509.132 315.069Z" fill="#5153FF"/>
            <path d="M508.794 314.363C445.866 314.241 497.191 246.095 497.191 246.095L507.382 242.779C508.18 249.778 508.579 257.606 508.548 265.832C508.548 268.841 508.487 271.941 508.364 275.011C507.72 291.587 508.395 306.014 508.794 314.363Z" fill="#5153FF"/>
            <path d="M490.713 206.342C490.713 206.342 486.17 214.63 477.514 216.933C477.084 213.403 477.084 211.407 477.084 211.407L484.021 205.943L490.713 206.342Z" fill="#F48484"/>
            <path d="M473.952 211.161C473.952 211.161 491.081 213.955 492.708 203.426C494.304 192.897 497.619 186.144 486.722 183.903C475.855 181.662 473.092 185.376 471.68 188.753C470.268 192.16 469.532 210.148 473.952 211.161Z" fill="#FC9490"/>
            <path d="M498.908 182.645C499.062 182.46 499.43 182.338 500.228 182.276C500.228 182.276 499.584 181.877 498.724 181.847C498.571 180.741 497.957 178.777 496.054 178.286C496.054 178.286 497.067 178.838 497.22 180.005C495.01 177.733 490.252 176.076 477.114 177.119C459.801 178.501 465.511 189.582 467.752 190.902C469.992 192.222 475.641 188.6 479.815 188.937C483.99 189.275 484.788 195.292 486.2 196.12C487.612 196.949 487.06 195.476 488.963 194.616C490.866 193.757 491.05 196.918 490.498 199.006C489.945 201.062 492.585 203.794 492.585 203.794C492.585 203.794 492.585 203.794 495.256 200.94C497.926 198.085 500.413 185.929 499.215 183.351C499.123 183.105 499.031 182.89 498.908 182.645Z" fill="#151852"/>
            <path d="M495.808 218.437C495.808 218.437 506.644 223.563 511.862 231.483C519.936 243.73 529.267 288.363 529.452 292.507C529.636 296.651 522.637 325.813 522.637 325.813C522.637 325.813 515.546 325.352 513.704 321.761C513.704 321.761 515.116 291.77 514.226 289.836C513.336 287.903 484.635 249.624 487.858 241.459C491.081 233.263 495.808 218.437 495.808 218.437Z" fill="#5153FF"/>
            <path d="M412.53 235.903C412.53 235.903 413.114 233.57 411.517 232.527C409.89 231.483 408.417 228.628 407.742 229.027C406.33 229.825 408.509 232.312 408.11 235.412C407.926 236.486 410.474 239.341 412.53 235.903Z" fill="#FC9490"/>
            <path d="M148.971 456.548C148.971 456.548 123.493 391.318 105.627 401.694C87.762 412.069 148.971 456.548 148.971 456.548Z" fill="#33CA8F"/>
            <path d="M136.354 456.548C136.354 456.548 102.711 427.448 87.3321 432.851C71.9531 438.253 136.354 456.548 136.354 456.548Z" fill="#33CA8F"/>
            <path d="M492.74 465.941C492.74 465.941 500.506 438.959 509.593 442.151C518.709 445.344 492.74 465.941 492.74 465.941Z" fill="#33CA8F"/>
            <path d="M499.83 464.468C499.83 464.468 519.629 451.483 523.988 455.474C528.316 459.495 499.83 464.468 499.83 464.468Z" fill="#33CA8F"/>
            <path d="M432.144 406.728H277.71C276.667 406.728 275.838 405.869 275.838 404.856C275.838 403.812 276.697 402.983 277.71 402.983H432.175C433.218 402.983 434.047 403.843 434.047 404.856C434.047 405.869 433.188 406.728 432.144 406.728Z" fill="#D2E4FD"/>
            <path d="M432.144 414.249H277.71C276.667 414.249 275.838 413.389 275.838 412.376C275.838 411.333 276.697 410.504 277.71 410.504H432.175C433.218 410.504 434.047 411.363 434.047 412.376C434.047 413.389 433.188 414.249 432.144 414.249Z" fill="#D2E4FD"/>
            <path d="M432.144 422.567H277.71C276.667 422.567 275.838 421.708 275.838 420.695C275.838 419.651 276.697 418.822 277.71 418.822H432.175C433.218 418.822 434.047 419.682 434.047 420.695C434.047 421.739 433.188 422.567 432.144 422.567Z" fill="#D2E4FD"/>
            <path d="M413.573 430.917H275.899C275.869 430.917 275.838 430.886 275.838 430.855V427.233C275.838 427.203 275.869 427.172 275.899 427.172H413.573C413.603 427.172 413.634 427.203 413.634 427.233V430.855C413.634 430.886 413.603 430.917 413.573 430.917Z" fill="#D2E4FD"/>
            </g>
            <defs>
            <clipPath id="clip0_1852_99">
            <rect width="747.796" height="480" fill="white"/>
            </clipPath>
            </defs>
            </svg>
        </div>
        <div className="no-content-part">
        <div className="content-part">
        <p>{msg}</p>
        {showButton && 
            <div className="button-section d-flex justify-content-center">
                {/* <button onClick={() => inputRef && inputRef.current.focus()} className="btn">Create Goal</button> */}
            </div>
        }
        </div>
        </div>
        </>
    )
}