import React, { useState, useEffect } from "react"
import "../../css/App.css"
import { DefaultButton } from "../../utils/shared"
import { baseUrl } from "../../lib/constants"
import _get from "lodash/get"
import AssessmentCard from "./AssessmentCard"
import ScrollToID from "./ScrollToCard"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { logEvent } from "../../index"
import { withTranslation } from "react-i18next"

const store = require("store")

const EButtonsWrapper = styled.div`
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    > button {
      min-width: 100px;
      margin: 0 0.3em 0.3em 0;
    }
  }
`

const Indepths = ({
  disorder,
  id,
  index,
  response,
  isAnswered,
  en,
  fr,
  t,
  lang,
  answerInDepth,
  progressBarMethod,
  onQuestionIsNotPending,
  onQuestionIsPending,
  firstUnansweredQuestion,
  highlightFirstUnansweredQuestion,
}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shouldRender, setshouldRender] = useState(false)

  const onClick = (value) => {
    onQuestionIsPending()
    setLoading(true)

    var indepthRes = value

    var dataPostIndepth = {
      id: id,
      response: indepthRes,
      disorder: disorder,
    }

    fetch(baseUrl + "/assessment/in-depth", {
      method: "POST",
      credentials: "include",
      mode: "cors",
      body: JSON.stringify(dataPostIndepth),
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        logEvent("onClick /assessment/in-depth successful")
        console.log({ res })
        answerInDepth(indepthRes)
        progressBarMethod()
        setLoading(false)
        setOpen(false)
        onQuestionIsNotPending()
      })
      .catch((err) => {
        logEvent("onClick /assessment/in-depth failed")
        alert(
          "Something Went Wrong, Please resubmit the question or check your Internet Connection"
        )
        onQuestionIsNotPending()
      })
  }

  const changeQuestionCard = (index, disorder) => {
    setOpen(!open)
    store.set("indepthCardIndex", { index: index, disorder: disorder })
  }

  useEffect(() => {
    if (firstUnansweredQuestion && firstUnansweredQuestion.id === id) {
      setOpen(true)
      ScrollToID(id)
    }
  }, [firstUnansweredQuestion?.id])

  useEffect(() => {
    if (index === 1 && response !== 0 && !response) {
      setOpen(true)
    }
    if (!store.get("indepthCardIndex")) {
      store.set("indepthCardIndex", {
        index: 0,
        disorder: disorder,
      })
    }
  }, [])

  const indepthAnswer = response
  const redHighlight =
    _get(firstUnansweredQuestion, ["id"]) &&
    id &&
    firstUnansweredQuestion.id === id &&
    highlightFirstUnansweredQuestion

  return (
    <>
      <AssessmentCard
        id={id}
        answered={indepthAnswer === 0 || indepthAnswer}
        open={open}
        setOpen={() => changeQuestionCard(index, disorder)}
        loading={loading}
        question={lang === "fr" ? fr : en}
        answer={
          ["Never", "Rarely", "Sometimes", "Frequently", "All the time"][
            indepthAnswer
          ]
        }
        redHighlight={redHighlight}
      >
        <EButtonsWrapper>
          <DefaultButton
            active={indepthAnswer === 0}
            onClick={() => {
              onClick(0)
            }}
          >
            {t("inDepth.never")}
          </DefaultButton>
          <DefaultButton
            active={indepthAnswer === 1}
            onClick={() => {
              onClick(1)
              setshouldRender(true)
            }}
            data-toggle={
              disorder == "Depression" && id == "v1_dep_7" ? "modal" : ""
            }
            data-target={
              disorder == "Depression" && id == "v1_dep_7"
                ? "#exampleModalCenter"
                : ""
            }
          >
            {t("inDepth.rarely")}
          </DefaultButton>

          <DefaultButton
            active={indepthAnswer === 2}
            onClick={() => {
              onClick(2)
            }}
            data-toggle={
              disorder == "Depression" && id == "v1_dep_7" ? "modal" : ""
            }
            data-target={
              disorder == "Depression" && id == "v1_dep_7"
                ? "#exampleModalCenter"
                : ""
            }
          >
             {t("inDepth.sometimes")}
          </DefaultButton>
          <DefaultButton
            active={indepthAnswer === 3}
            onClick={() => {
              onClick(3)
            }}
            data-toggle={
              disorder == "Depression" && id == "v1_dep_7" ? "modal" : ""
            }
            data-target={
              disorder == "Depression" && id == "v1_dep_7"
                ? "#exampleModalCenter"
                : ""
            }
          >
             {t("inDepth.frequently")}
          </DefaultButton>
          <DefaultButton
            active={indepthAnswer === 4}
            onClick={() => {
              onClick(4)
            }}
            data-toggle={
              disorder == "Depression" && id == "v1_dep_7" ? "modal" : ""
            }
            data-target={
              disorder == "Depression" && id == "v1_dep_7"
                ? "#exampleModalCenter"
                : ""
            }
          >
            {t("inDepth.allTheTime")}
          </DefaultButton>
        </EButtonsWrapper>
      </AssessmentCard>
    </>
  )
}
export const Indepth = withTranslation()(Indepths);
