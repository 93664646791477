import React, { useState, useEffect } from "react"
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder"
import BookmarkIcon from "@material-ui/icons/Bookmark"
import PhoneIcon from "@material-ui/icons/Phone"
import MailIcon from "@material-ui/icons/Mail"
import LanguageIcon from "@material-ui/icons/Language"
import "../../css/ResourcesCard.scss"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import phoneIcon from "../../assets/images/phone-icon.png"
import { baseUrl } from "../../lib/constants"

const leftCard = styled.div`
  height: 250px;
  width: 600px;
  font-family: "CircularStdBook";
  font-weight: 100;
  color: black;
  positiion: relative;
`

const disorders = {
  Depression: "Depression",
  "Bipolar Disorder": "Bipolar Disorder",
  Alcohol: "Alcohol and Substance Abuse",
  "Panic Disorder": "Panic Disorder",
  "Social Anxiety": "Social Anxiety",
  PTSD: "PTSD",
  OCD: "Obsessive Compulsive Disorder",
  GAD: "General Anxiety Disorder",
  "Eating Disorder": "Eating Disorder",
  adhd: "ADHD",
  "Anger Problems": "Anger Problems",
  "Adjustment Disorder": "Adjustment Disorder",
  Insomnia: "Sleep Disorder (Insomnia)",
  Covid: "Covid",
}

const disordersTranslation = {
  Depression: {
    en: "Depression",
    fr: "La dépression",
  },
  "Bipolar Disorder": {
    en: "Bipolar Disorder",
    fr: "Trouble bipolaire",
  },
  "Alcohol and Substance Abuse": {
    en: "Alcohol and Substance Abuse",
    fr: "Abus d'alcool et de substances",
  },
  "Panic Disorder": {
    en: "Panic Disorder",
    fr: "Trouble panique",
  },
  "Social Anxiety": {
    en: "Social Anxiety",
    fr: "Anxiété sociale",
  },
  PTSD: {
    en: "PTSD",
    fr: "SSPT",
  },
  "Obsessive Compulsive Disorder": {
    en: "Obsessive Compulsive Disorder",
    fr: "Trouble obsessionnel compulsif",
  },
  "General Anxiety Disorder": {
    en: "General Anxiety Disorder",
    fr: "Trouble Anxieux Général",
  },
  "Eating Disorder": {
    en: "Eating Disorder",
    fr: "Trouble de l'alimentation",
  },
  ADHD: {
    en: "ADHD",
    fr: "TDAH",
  },
  "Anger Problems": {
    en: "Anger Problems",
    fr: "Problèmes de colère",
  },
  "Adjustment Disorder": {
    en: "Adjustment Disorder",
    fr: "Trouble de l'adaptation",
  },
  Insomnia: {
    en: "Sleep Disorder (Insomnia)",
    fr: "Trouble du sommeil (insomnie)",
  },
  Covid: {
    en: "Covid",
    fr: "Covid",
  },
}

function ResourcesCard({
  resource,
  disorder,
  bookmarks,
  setBookmarks,
  setBookmarksData,
  postBookmarkIds,
  bookmarksIds,
  setBookmarksIds,
  // lang,
  fromBookmark,
  disorderBookmark,
  isVideo,
  t,
  i18n,
  setLoading,
  bookmarkLoading,
  setBookmarkLoading,
}) {
  const [isBookMarked, setIsBookMarked] = useState(false)

  const lang = i18n.language === "en" ? "en" : "french"
  const lang_short = i18n.language === "en" ? "en" : "fr"

  useEffect(() => {
    setBookmarks([])
    // if(bookmarksIds && bookmarksIds.includes(resource.id))
    console.log(
      "bookmarks",
      bookmarksIds && bookmarksIds.includes(resource.id),
      bookmarksIds,
      resource.id
    )
    setIsBookMarked(bookmarksIds && bookmarksIds.includes(resource.id))
    // }, [disorder])
  }, [bookmarksIds, disorder, resource])

  // useEffect(() => {
  //   console.log("bookmarks", bookmarks)
  // }, [bookmarks])

  const truncate = (str) =>
    str.length > 40 ? `${str.substring(0, 30)}...` : str

  const changeBookmarkState = async (arr) => {
    setBookmarkLoading(true)
    await fetch(`${baseUrl}/user-profile/bookmarks`, {
      credentials: "include",
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bookmarks: arr }),
    })
      .then((res) => res.json())
      .then((res) => {
        setBookmarksIds(arr)
        postBookmarkIds(undefined, true)
      })
      .catch((err) => {
        console.log(err)
        setBookmarkLoading(false)
      })
  }

  const getAttribute = (attr) => {
    switch (attr.type) {
      case "text":
        return (
          <li>
            <div className="icon-part">
              <img
                width={18}
                src={require("../../assets/images/icon-checkmark.png")}
              />
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}
            </div>
            <div className="contentPart">
              <div className="address">
                {attr.value && typeof attr.value === "object"
                  ? attr.value[lang_short]
                  : attr.value}
              </div>
            </div>
          </li>
        )
      case "mobile":
        return (
          <li>
            <div className="icon-part">
              <img src={require("../../assets/images/phone_icon.png")} />
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}
            </div>
            <div className="contentPart">
              <a
                href={`tel:${
                  attr.value && typeof attr.value === "object"
                    ? attr.value[lang_short]
                    : attr.value
                }`}
              >
                {attr.value && typeof attr.value === "object"
                  ? attr.value[lang_short]
                  : attr.value}
              </a>
            </div>
          </li>
        )
      case "email":
        return (
          <li>
            <div className="icon-part">
              <img src={require("../../assets/images/email_icon.png")} />
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}
            </div>
            <div className="contentPart">
              <a
                href={`mailto:${
                  attr.value && typeof attr.value === "object"
                    ? attr.value[lang_short]
                    : attr.value
                }`}
              >
                {attr.value && typeof attr.value === "object"
                  ? attr.value[lang_short]
                  : attr.value}
              </a>
            </div>
          </li>
        )
      case "liveChat":
        return (
          <li>
            <div className="icon-part live-chat-part">
              <div className="icon-background">
                <img
                  src={require("../../assets/images/icons/liveChat.png")}
                  className="liveChat"
                />
              </div>
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}
            </div>
            <div className="contentPart">
              <a
                href={
                  attr.value && typeof attr.value === "object"
                    ? attr.value[lang_short]
                    : attr.value
                }
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>
            </div>
          </li>
        )
      case "address":
        return (
          <li>
            <div className="icon-part location-part">
              <div className="icon-background">
                <img
                  src={require("../../assets/images/icons/location.png")}
                  className="liveChat"
                />
              </div>
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}
            </div>
            <div className="contentPart">
              <div className="address">
                {attr.value && typeof attr.value === "object"
                  ? attr.value[lang_short]
                  : attr.value}
              </div>
            </div>
          </li>
        )
      case "website":
        return (
          <li>
            <div className="icon-part">
              <img src={require("../../assets/images/web_icon.png")} />
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}
            </div>
            <div className="contentPart">
              <a
                href={
                  attr.value && typeof attr.value === "object"
                    ? attr.value[lang_short]
                    : attr.value
                }
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>
            </div>
          </li>
        )
      case "line":
        return (
          <li>
            <hr className="seprate" />
          </li>
        )
      case "name":
        return (
          <li>
            {console.log("resource name debug : ", attr.name, lang_short)}
            <p className="label-with-border">
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}{" "}
            </p>
          </li>
        )
      default:
        return (
          <li>
            <div className="icon-part">
              <img
                width={18}
                src={require("../../assets/images/icon-checkmark.png")}
              />
              {attr.name && typeof attr.name === "object"
                ? attr.name[lang_short]
                : attr.name}
            </div>
            <div className="contentPart">
              <div className="address">
                {attr.value && typeof attr.value === "object"
                  ? attr.value[lang_short]
                  : attr.value}
              </div>
            </div>
          </li>
        )
    }
  }

  const getResourceType = (resource) => {
    if (
      resource.qaulifiers.type !== undefined &&
      resource.qaulifiers.type.resourceType !== undefined
    ) {
      if (resource.qaulifiers.type.resourceType == "academicPapers") {
        return "Article"
      }
      if (resource.qaulifiers.type.resourceType[lang_short]) {
        return resource.qaulifiers.type.resourceType[lang_short]
          .replace(/([A-Z]+)/g, " $1")
          .replace(/([A-Z][a-z])/g, " $1")
      }
      return resource.qaulifiers.type.resourceType
        .replace(/([A-Z]+)/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1")
    }
    return ""
  }

  return (
    <>
      {/*
          <div className="accordion" id={"summaryDetails" + id}>
            <div className="card my-2 border-bottom radius">
              <div
                className="card-header border-0 lightBg radius"
                id={id}
                data-toggle="collapse"
                data-target={"#" + target}
                aria-expanded="false"
                aria-controls={target}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="mt-2 mb-2 accordion-title">{heading == 'Mania/Hypomania' ? 'Bipolar Disorder' : heading}</span>
                  <i id={iconId} className="fas fa-chevron-down"></i>
                </div>
              </div>
              <div
                id={target}
                className="collapse"
                aria-labelledby={id}
                data-parent={"#summaryDetails" + id}
              >
                <div className="card-body carddetail-question">{data}</div>
              </div>
            </div>
          </div> */}

      <div className="mainCard button-bottom-grid">
        <div className="topCard button-bottom-grid">
          {/* hover-content on card */}
          {/* {!isVideo &&
            <div className="hover-content-block">
              <div className="hover-bookmark">
                <button
                  onClick={() => {
                    if (isBookMarked) {
                      let arr = [...bookmarksIds];
                      arr = arr.filter((a) => a !== resource.id);
                      changeBookmarkState(arr);
                    } else {
                      let arr = [...(bookmarksIds || [])];
                      arr = arr.filter((a) => a !== resource.id);
                      changeBookmarkState([...arr, resource.id]);
                      // setBookmarks([...bookmarks, resource.id])
                    }
                    setIsBookMarked(!isBookMarked);
                  }}
                  className="bookmark-btn"
                >
                  <img
                    height="24"
                    src={
                      isBookMarked // || bookmarksIds && bookmarksIds.includes(resource.id)
                        ? require('../../assets/images/bookmark_active.png')
                        : require('../../assets/images/bookmark.png')
                    }
                  />
                </button>
              </div>
              <div className="content-link">
              <h4 className="cardTitle card-title text-center">
                  {resource[`title-${lang}`]}
                </h4>
                <p className="hover-visible-content">
                  {resource[`description-${lang}`]}
                </p>
                <div className="bottom-part">
                  <div className="icon-section">
                    <ul>
                      {resource['number-address'].number && (
                        <li>
                          <img src={require('../../assets/images/phone_icon.png')} />
                          <a href={`tel:${resource['number-address'].number}`}>{resource['number-address'].number}</a>
                        </li>
                      )}
                      {resource.email && (
                        <li>
                          <img src={require('../../assets/images/email_icon.png')} />
                          <a href={`mailto:${resource.email}`}>{resource.email}</a>
                        </li>
                      )}
                      {resource.pdf && (
                        <li>
                          <img src={require('../../assets/images/pdf_icon.png')} />
                          <a href={resource.pdf}>Download PDF</a>
                        </li>
                      )}
                      {resource.website && (
                        <li>
                          <img src={require('../../assets/images/web_icon.png')} />
                          <a target="_blank" href={resource.website}>{resource.videoUrl ? i18n.t("education.watchVideo") : i18n.t("education.visitWebsite")}</a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="link-button-section">
                    <a className="link-btn" href="#">
                      {resource.qaulifiers.type !== undefined
                        && resource.qaulifiers.type.resourceType !== undefined
                        ? resource.qaulifiers.type.resourceType == 'academicPapers' ? 'Article' : resource.qaulifiers.type.resourceType.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
                        : ''}

                    </a>
                    <a className="link-btn" href="#">{fromBookmark ? (resource?.qaulifiers?.type?.disorderType || '') : (resource?.qaulifiers?.type?.disorderType || '')}</a>
                  </div>
                </div>
              </div>
            </div>
          } */}

          <div
            className="formal-content button-bottom-grid collapsed"
            style={isVideo ? { display: "flex" } : {}}
            data-toggle="collapse"
            href={`#collapseOne-${resource.id}`}
          >
            <div
              className={`image-part ${
                resource?.qaulifiers?.type?.resourceType == "VideoResources"
                  ? "available-image"
                  : ""
              }`}
            >
              <div className="bookmark-with-image">
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    if (!bookmarkLoading) {
                      if (isBookMarked) {
                        let arr = [...bookmarksIds]
                        arr = arr.filter((a) => a !== resource.id)
                        changeBookmarkState(arr)
                      } else {
                        let arr = [...(bookmarksIds || [])]
                        arr = arr.filter((a) => a !== resource.id)
                        changeBookmarkState([...arr, resource.id])
                        // setBookmarks([...bookmarks, resource.id])
                      }
                    }
                    setIsBookMarked(!isBookMarked)
                  }}
                  className={`bookmark-btn bookmark-radius ${
                    bookmarkLoading ? "disable-card" : ""
                  }`}
                >
                  <img
                    height="24"
                    src={
                      isBookMarked // || bookmarksIds && bookmarksIds.includes(resource.id)
                        ? require("../../assets/images/bookmark_active.png")
                        : require("../../assets/images/bookmark-dark.png")
                    }
                  />
                </button>
              </div>
              {resource?.qaulifiers?.type?.resourceType == "VideoResources" && (
                <a href={resource.videoUrl} target="_blank" rel="noreferrer">
                  <div className="video-thumbnail-div">
                    <img src={resource.imageUrl} />
                  </div>
                </a>
              )}
            </div>
            <div className="content-part button-bottom-grid">
              <div
                className={`headerCard ${
                  resource?.qaulifiers?.type?.resourceType == "VideoResources"
                    ? "with-image-header"
                    : ""
                }`}
              >
                <h4
                  className="cardTitle card-title"
                  dangerouslySetInnerHTML={{
                    __html: resource[`title-${lang}`],
                  }}
                >
                  {/* {resource[`title-${lang}`]} */}
                </h4>
                <div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      if (!bookmarkLoading) {
                        if (isBookMarked) {
                          let arr = [...bookmarksIds]
                          arr = arr.filter((a) => a !== resource.id)
                          changeBookmarkState(arr)
                        } else {
                          let arr = [...(bookmarksIds || [])]
                          arr = arr.filter((a) => a !== resource.id)
                          changeBookmarkState([...arr, resource.id])
                          // setBookmarks([...bookmarks, resource.id])
                        }
                      }
                      setIsBookMarked(!isBookMarked)
                    }}
                    className={`bookmark-btn  ${
                      bookmarkLoading ? "disable-card" : ""
                    }`}
                  >
                    <img
                      height="24"
                      src={
                        isBookMarked // || bookmarksIds && bookmarksIds.includes(resource.id)
                          ? require("../../assets/images/bookmark_active.png")
                          : require("../../assets/images/bookmark.png")
                      }
                    />
                  </button>
                </div>
              </div>
              <div className="resources-content button-bottom-grid">
                <div className="card-Description-wrapper">
                  <p
                    className="cardDescription card-description"
                    dangerouslySetInnerHTML={{
                      __html: resource[`description-${lang}`],
                    }}
                  >
                    {/* {resource[`description-${lang}`]} */}
                  </p>
                </div>
                {/* <div className="">
                      <div className="d-flex flex-column">
                        {resource["number-address"].number && (
                          <a
                            href={`tel:${resource["number-address"].number}`}
                            target="_blank"
                          >
                            <span className="">
                              <img src={require("../../assets/images/call.svg")} />
                              <span className="pl-2 card-links">
                                {resource["number-address"].number}
                              </span>
                            </span>
                          </a>
                        )}
                        {resource.website && (
                          <a
                            href={resource.website}
                            target="_blank"
                          >
                            <span className="">
                              <img src={require("../../assets/images/Frame.svg")} />
                              {/* <span className="pl-2 card-links">{resource.website}</span>}
                              <span className="pl-2 card-links">
                                {truncate(resource.website)}
                              </span>
                            </span>
                          </a>
                        )}
                        {resource.email && (
                          <a
                            href={`mailto:${resource.email}`}
                            target="_blank"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span className="px-3">
                              <img
                                src={require("../../assets/images/Mail_green.svg")}
                              />
                              <span className="pl-2 card-links">
                                {resource.email}
                              </span>
                            </span>
                          </a>
                        )}
                      </div>
                      <div className="">
                        <span
                          className="cardTag card-tags"
                        >
                          {console.log(resource)}
                          {console.log('resource')}
                          {resource.qaulifiers.type !== undefined &&
                          resource.qaulifiers.type.resourceType !== undefined
                            ? resource.qaulifiers.type.resourceType == 'academicPapers' ? 'Article': resource.qaulifiers.type.resourceType.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
                            : ""}
                        </span>
                        <span
                          className="cardTag card-tags"
                        >
                          {disorder}
                        </span>
                      </div>
                    </div> */}
                <div className="bottom-part">
                  <div className="icon-section">
                    {/* <ul>
                      <li>
                        <img src={require('../../assets/images/phone_icon.png')} />
                        <a href="#">+10 1234567890</a>
                      </li>
                      <li>
                        <img src={require('../../assets/images/email_icon.png')} />
                        <a href="#">abcd@abc.com</a>
                      </li>
                      <li>
                        <img src={require('../../assets/images/web_icon.png')} />
                        <a target='_blank' href="#">Visit Website</a>
                      </li>
                    </ul> */}

                    {/* custom design part for inner field */}

                    {/* <ul className='d-block fullWidthData'>
                    <li>
                      <div className='icon-part'>
                          <img src={require('../../assets/images/phone_icon.png')} />
                          Phone No
                        </div>
                        <div className='contentPart'>
                          <a href="#">+10 1234567890</a>
                        </div>
                      </li>
                      <li>
                      <div className='icon-part'>
                          <img src={require('../../assets/images/email_icon.png')} />
                          Email
                        </div>
                        <div className='contentPart'>
                          <a href="#">abcd@abc.com</a>
                        </div>
                      </li>
                      <li>
                      <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Website
                        </div>
                        <div className='contentPart'>
                          <a href="#">Visit Website</a>
                        </div>
                      </li>
                      <li>
                        <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Address
                        </div>
                        <div className='contentPart'>
                          <p>Health Services: UCC Room 11</p>
                          <p>Campus Recreation: 1151 Richmond Street, London, Ontario, Canada, N6A 3K7</p>
                        </div>
                      </li>
                      <li>
                        <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Hours
                        </div>
                        <div className='contentPart'>
                          <p>September to April: Monday to Thursday: 9:00 am - 7:00 pm, Friday 9:00 am - 4:30 pm, See Saturday schedule</p>
                          <p>Summer Hours (May to August): Monday to Friday: 9:00 am - 4:00 pm</p>
                        </div>
                      </li>
                      <li>
                        <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Cost
                        </div>
                        <div className='contentPart'>
                          <div className='costPart'>
                            <label>Health services</label>
                            <p>30 minutes = $60</p>
                            <p>60 minutes = $100</p>
                          </div>
                          <div className='costPart'>
                            <label>Campus Recreation</label>
                            <p>30 minute session: $39.99 (member), $47.99 (non-member)</p>
                            <p>45 minute session: $55.99 (member), $66.99 (non-member)</p>
                            <p>60 minute session: $66.99 (member), $79.99 (non-member)</p>
                          </div>

                        </div>
                      </li>

                    </ul> */}

                    <ul>
                      {resource["number-address"].number && (
                        <li>
                          <img
                            src={require("../../assets/images/phone_icon.png")}
                          />
                          <a href={`tel:${resource["number-address"].number}`}>
                            {resource["number-address"].number}
                          </a>
                        </li>
                      )}
                      {resource.email && (
                        <li>
                          <img
                            src={require("../../assets/images/email_icon.png")}
                          />
                          <a href={`mailto:${resource.email}`}>
                            {resource.email}
                          </a>
                        </li>
                      )}
                      {resource.pdf && (
                        <li>
                          <img
                            src={require("../../assets/images/pdf_icon.png")}
                          />
                          <a href={resource.pdf}>Download PDF</a>
                        </li>
                      )}
                      {resource.website && (
                        <li>
                          <img
                            src={require("../../assets/images/web_icon.png")}
                          />
                          <a
                            target="_blank"
                            href={resource.website}
                            rel="noreferrer"
                          >
                            {resource.videoUrl
                              ? i18n.t("education.watchVideo")
                              : i18n.t("education.visitWebsite")}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="link-button-section">
                    <a className="link-btn" href="#">
                      {getResourceType(resource)}
                    </a>
                    <a className="link-btn" href="#">
                      {fromBookmark
                        ? disordersTranslation[
                            resource?.qaulifiers?.type?.disorderType || ""
                          ]
                          ? disordersTranslation[
                              resource?.qaulifiers?.type?.disorderType || ""
                            ][lang_short] ||
                            resource?.qaulifiers?.type?.disorderType ||
                            ""
                          : resource?.qaulifiers?.type?.disorderType || ""
                        : disordersTranslation[
                            resource?.qaulifiers?.type?.disorderType || disorder
                          ]
                        ? disordersTranslation[
                            resource?.qaulifiers?.type?.disorderType || disorder
                          ][lang_short] ||
                          resource?.qaulifiers?.type?.disorderType ||
                          disorder
                        : resource?.qaulifiers?.type?.disorderType || disorder}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id={`collapseOne-${resource.id}`}
            className="collapse uniqueFieldData"
            data-parent="#accordion"
          >
            <div className="card-body">
              <div className="bottom-part">
                <div className="icon-section">
                  <ul className="d-block fullWidthData">
                    {/* {console.log('I am attr ===> ', resource?.attributes, ' <=== I am attr')} */}
                    {resource?.attributes?.map((attr) => getAttribute(attr))}
                    {/* <li>
                      <div className='icon-part'>
                          <img src={require('../../assets/images/phone_icon.png')} />
                          Phone No
                        </div>
                        <div className='contentPart'>
                          <a href="#">+10 1234567890</a>
                        </div>
                      </li>
                      <li>
                      <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Website
                        </div>
                        <div className='contentPart'>
                          <a href="#">Visit Website</a>
                        </div>
                      </li>
                      <li>
                        <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Address
                        </div>
                        <div className='contentPart'>
                          <p>Health Services: UCC Room 11</p>
                          <p>Campus Recreation: 1151 Richmond Street, London, Ontario, Canada, N6A 3K7</p>
                        </div>
                      </li>
                      <li>
                        <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Hours
                        </div>
                        <div className='contentPart'>
                          <p>September to April: Monday to Thursday: 9:00 am - 7:00 pm, Friday 9:00 am - 4:30 pm, See Saturday schedule</p>
                          <p>Summer Hours (May to August): Monday to Friday: 9:00 am - 4:00 pm</p>
                        </div>
                      </li>
                      <li>
                        <div className='icon-part'>
                          <img src={require('../../assets/images/web_icon.png')} />
                          Cost
                        </div>
                        <div className='contentPart'>
                          <div className='costPart'>
                            <label>Health services</label>
                            <p>30 minutes = $60</p>
                            <p>60 minutes = $100</p>
                          </div>
                          <div className='costPart'>
                            <label>Campus Recreation</label>
                            <p>30 minute session: $39.99 (member), $47.99 (non-member)</p>
                            <p>45 minute session: $55.99 (member), $66.99 (non-member)</p>
                            <p>60 minute session: $66.99 (member), $79.99 (non-member)</p>
                          </div>

                        </div>
                      </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResourcesCard
