import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { baseUrl } from '../../lib/constants';
import '../../css/SummaryModals.css';
import { logEvent } from '../../index';
import { withTranslation } from 'react-i18next';

const DisorderTherapyModal = (therapyName) => {
  const { data, lang, t } = therapyName;
  let therapyNameAPI = '';

  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  const currentDetails = details; // && details.slice(indexOfFirstDetails, indexOfLastDetails);

  const getTherapyContent = () => {
    const cont = Array.isArray(thrapyContent?.information)
      ? thrapyContent?.information.map((info) => {
        let args = [];
        // console.log('current d info', info)
        if (Array.isArray(info.content)) {
          args = [...args, ...info.content.map((content) => (
            typeof content === 'object' && content.en ? content[lang]
              : React.createElement(content.tag || 'p', content.attr || {}, content.content[lang])
          ))];
        } else {
          args.push(info.content && info.content[lang]);
        }
        // console.log('current d args', args);
        return (
          React.createElement(info.tag || 'p', info.attr || {}, ...args)
        );
      })
      : ((typeof thrapyContent?.information === 'string' ? thrapyContent?.information : (thrapyContent?.information[lang])));

    // const pages = [];
    // const charsPerPage = 200;
    // if (typeof cont === 'string') {
    //   const words = cont.split(' ');
    //   let sum = 0;
    //   let lastIndex = 0;
    //   // console.log('current d words', words)
    //   for (let i = 0; i < words.length; i++) {
    //     sum += words[i].length;
    //     if (sum >= charsPerPage || i >= words.length - 1) {
    //       sum = 0;
    //       const onePage = words.slice(lastIndex, i + 1);
    //       lastIndex = i;
    //       pages.push(onePage.join(' '));
    //     }
    //   }
    // } else {
    //   const wordCountPerLine = {};
    //   let sum = 0;
    //   let lastId = 0;
    //   for (let i = 0; i < cont?.length || 0; i++) {
    //     console.log('current d cont', cont[i].props)
    //     const { children } = cont[i].props;
    //     let wordCount = 0;
    //     if (typeof children === 'string') {
    //       wordCount = children.split(' ').length;
    //     } else {
    //       console.log(children, ' <==== i am chipd')
    //       for (let j = 0; j < children.length; j++) {
    //         if (!wordCountPerLine[i]) {
    //           wordCount = 0;
    //         }
    //         wordCount += children[j].props.children.split(' ').length;
    //       }
    //     }

    //     sum += wordCount;
    //     // console.log('current d condition ===> ', lastId, ' - ', i, `${sum} >= ${charsPerPage} || ${i} >= ${cont.length - 1}`, sum >= charsPerPage || i >= cont.length - 1)
    //     if (sum >= charsPerPage || i >= cont.length - 1) {
    //       sum = 0;
    //       pages.push(cont.slice(lastId, i + 1));
    //       lastId = i + 1;
    //     }
    //   }
    // }
    // console.log('veridy cont pages', cont)
    return cont;
  };

  const onNext = (e) => {
    e.preventDefault();
    const current = ($('.content').css('margin-top') || '0px');
    if (parseInt(current.substring(0, current.length - 2)) >= ($('.content').height() * -1) + $('.paginate').height()) {
      $('.content').css('margin-top', `${current.substring(0, current.length - 2) - $('.paginate').height()}px`);
      // setDisablePrev(false);
    } else {
      // setDisableNext(true);
    }
  };

  const onPrev = e => {
    const current = ($('.content').css('margin-top') || '0px');
    if (parseInt(current.substring(0, current.length - 2)) != 0) {
      $('.content').css('margin-top', `${parseInt(current.substring(0, current.length - 2)) + $('.paginate').height()}px`);
      // setDisableNext(false);
    } else {
      // setDisablePrev(true);
    }
  };

  if (therapyName.therapyName.therapy === 'Cognitive Behavioural Therapy (CBT)') {
    therapyNameAPI = 'CBT';
  } else if (therapyName.therapyName.therapy === 'Acceptance and Commitment Therapy (ACT)') {
    therapyNameAPI = 'ACT';
  } else if (therapyName.therapyName.therapy === 'Biofeedback') {
    therapyNameAPI = 'Biofeedback';
  } else if (therapyName.therapyName.therapy === 'Dialectical Behaviour Therapy (DBT)') {
    therapyNameAPI = 'DBT';
  } else if (therapyName.therapyName.therapy === 'Interpersonal Therapy (IPT)') {
    therapyNameAPI = 'IPT';
  } else if (therapyName.therapyName.therapy === 'Marriage and Family Therapy') {
    therapyNameAPI = 'Familytherapy';
  } else if (therapyName.therapyName.therapy === 'Psychodynamic Therapy') {
    therapyNameAPI = 'Psychodynamic';
  } else if (therapyName.therapyName.therapy === 'Eye Movement Desensitization and Reprocessing (EMDR)') {
    therapyNameAPI = 'EMDR';
  } else if (therapyName.therapyName.therapy === 'Group Therapy') {
    therapyNameAPI = 'Grouptherapy';
  } else if (therapyName.therapyName.therapy === 'Motivational Interviewing (MI)') {
    therapyNameAPI = 'MI';
  } else if (therapyName.therapyName.therapy === 'Brief Interventions') {
    therapyNameAPI = 'Briefinterventions';
  } else if (therapyName.therapyName.therapy === 'Psychoeducation') {
    therapyNameAPI = 'Psychoeducation';
  } else if (therapyName.therapyName.therapy === 'Problem-Solving Therapy' || therapyName.therapyName.therapy === 'Problem-Solving Therapy (PST)') {
    therapyNameAPI = 'PST';
  } else if (therapyName.therapyName.therapy === 'EAP (Employee Assistance Program) Counselling') {
    therapyNameAPI = 'EAP';
  } else if (therapyName.therapyName.therapy === 'Internet-based Cognitive Behavioral Therapy (iCBT)') {
    therapyNameAPI = 'iCBT';
  } else if (therapyName.therapyName.therapy === 'Mindfulness Based Cognitive Therapy (MBCT)') {
    therapyNameAPI = 'MBCT';
  } else if (therapyName.therapyName.therapy === 'Meditation-Based Therapy') {
    therapyNameAPI = 'MBT';
  } else if (therapyName.therapyName.therapy === 'Self-Help Groups') {
    therapyNameAPI = 'SHG';
  } else if (therapyName.therapyName.therapy === 'Twelve-Step Facilitation') {
    therapyNameAPI = 'TSF';
  } else if (therapyName.therapyName.therapy === 'Attention Retraining') {
    therapyNameAPI = 'AR';
  } else if (therapyName.therapyName.therapy === 'Behavioural Activation') {
    therapyNameAPI = 'BA';
  } else if (therapyName.therapyName.therapy === 'Cognitive Behavioural Therapy for Insomnia (CBT-I)') {
    therapyNameAPI = 'CBTI';
  } else if (therapyName.therapyName.therapy === 'Exposure-Based Therapies') {
    therapyNameAPI = 'EBT';
  } else if (therapyName.therapyName.therapy === 'Mindfulness Based Stress Reduction') {
    therapyNameAPI = 'MBSR';
  } else if (therapyName.therapyName.therapy === 'Addiction Counseling') {
    therapyNameAPI = 'AC';
  } else if (therapyName.therapyName.therapy === 'Cognitive Remediation Therapy') {
    therapyNameAPI = 'CRT';
  } else {
    therapyNameAPI = 'test';
  }

  // therapyNameAPI = "Biofeedback"

  useEffect(() => {
    $('.content').css('margin-top', `0px`);
    if (data.loading) {
      setLoading(true);
      return () => {}
    }
    if (data.completed && data.error) {
      const fetchDetails = async () => {
        setLoading(true);
        try {
          const res = await fetch(`${baseUrl}/education/${therapyNameAPI}`, {
            credentials: 'include',
            mode: 'cors',
            method: 'GET',
          });

          if (res.ok) {
            const education = await res.json();
            logEvent('useEffect /education called');
            setDetails(education);
          } else {
            setDetails([]);
          }
        } catch (error) {
          setDetails([]);
          console.log('file: =========================== useEffect === src/Modules/summary/DisorderTherapyModal.jsx ==============');
          console.log(error);
        }

        setLoading(false);
      };
      fetchDetails();
    } else {
      setLoading(false);
      // console.log(data, ' <=== i am data');
      setDetails(data.data[therapyNameAPI]);
    }
  }, [therapyNameAPI, data]);

  const DisorderTherapyTitle = ({ details, loading }) => {
    if (loading) { return <></>; }
    console.log('details : ', details)
    return (
      <div>
        {details.map((details) => (
          <div key={details._id}>
            {details.title[lang]}
          </div>
        ))}
      </div>
    );
  };

  const [thrapyContent] = currentDetails || [];
  return (
    <div
      className="modal fade"
      id="therapyModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog therapyDialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header therapyHeader">
            <h5 className="therapyTitle">
              <DisorderTherapyTitle
                details={currentDetails}
                loading={loading}
              />
            </h5>
          </div>
          <div className="modal-body therapyBody therapy-modal">
            <div className="paginate">
              <div className="content" style={{ lineHeight: 'normal' }}>
                {getTherapyContent()}
              </div>
            </div>
          </div>
          <div style={{ zIndex: '0' }}>
            <img
              src={require('../../assets/images/rightEllipse.png')}
              className="right-ellipse-send-action-plan"
            />
            <img
              src={require('../../assets/images/leftEllipse.png')}
              className="left-ellipse-send-action-plan"
            />
          </div>
          <div
            className="d-flex align-items-center mt-3"
            style={{ borderTop: 'none', zIndex: 1 }}
          >
            {/* <button type="button" className="startbtn therapystartbtn mr-3">
              LEARN MORE
            </button> */}
            <button
              type="button"
              className="closebtn therapyclosebtn"
              data-dismiss="modal"
              onClick={() => $('.content').css('margin-top', '0px')}
            >
              {t('summaryPage.tilesInfoModal.close')}
            </button>
            <nav className="paginationNav">
              <ul className="text-pagination craftyslide pagination mb-0">
                <li className="number-circle prev" onClick={onPrev}><a href="#" className={!disablePrev && "activeButton currentNumber"} onClick={e => e.preventDefault()}>{'<'}</a></li>
                <li className="number-circle next" onClick={onNext}><a href="#" className={!disableNext && "activeButton currentNumber"} onClick={e => e.preventDefault()}>{'>'}</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(DisorderTherapyModal);
