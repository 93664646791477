import React, { useContext, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const EButton = styled.button`
  background: #ccc;

  border-top-left-radius: ${(props) => (props.isFirst ? "0.4em" : "0")};
  border-bottom-left-radius: ${(props) => (props.isFirst ? "0.4em" : "0")};
  border-top-right-radius: ${(props) => (props.isLast ? "0.4em" : "0")};
  border-bottom-right-radius: ${(props) => (props.isLast ? "0.4em" : "0")};

  cursor: pointer;
  display: flex;
  height: 30px;
  padding: 0;
  margin: 0;
  transition: all 0.1s;
  font-weight: ${(props) => (props.enabled ? "600" : "400")};
  background-color: ${(props) => (props.enabled ? "#33CA8F" : "#F9F9F9")};
  color: ${(props) => (props.enabled ? "#fff" : "#69696F")};

  border-left: ${(props) =>
    props.enabled ? "1px solid #33CA8F" : "1px solid #d8d8d8"};

  border-top: ${(props) =>
    props.enabled ? "1px solid #33CA8F" : "1px solid #d8d8d8"};

  border-bottom: ${(props) =>
    props.enabled ? "1px solid #33CA8F" : "1px solid #d8d8d8"};

  min-height: 2.2em;

  border-right: ${(props) =>
    props.isLast
      ? props.enabled
        ? "1px solid #33CA8F"
        : "1px solid #d8d8d8"
      : "0"};

  display: flex;
  align-items: center;
  justify-content: center;
  top: ${(props) => (props.isFirst ? "0.4em" : "0")};

  :focus {
    outline: none;
  }
`

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export const Weekdays = ({ value, onChange }) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    `}
  >
    {days.map((i, index, arr) => (
      <EButton
        key={`${i}weekday`}
        isFirst={index === 0}
        isLast={index === -1 + arr.length}
        enabled={i === value}
        onClick={() => {
          onChange(i)
        }}
      >
        {i}
      </EButton>
    ))}
  </div>
)
