import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import _map from 'lodash/map';
import { setRandomPassword } from '../../api/Auth';
import date from "date-and-time"
import { disorderNames } from './PAPMake';
import moment from 'moment';

const store = require('store');

export const makeassessmentpdf = (detailsData, disorders, history, action, state, passwordChanged, fromEmail) => {
  if(passwordChanged) {
    setRandomPassword(detailsData?.user?.pdfPassword)
  }

  console.log('detailsData' , detailsData)
  const lang = store.get('lang') ? store.get('lang') : 'en';
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  Number.prototype.between = function (a, b) {
    const min = Math.min.apply(Math, [a, b]);
    const max = Math.max.apply(Math, [a, b]);
    return this >= min && this <= max;
  };
  const tempDate = detailsData.completed_at;
  const pdfdate = new Date(tempDate);
  const pdfmonth = pdfdate.getMonth() + 1;
  const pdfday = pdfdate.getDate();

  const getScreeningQuestions = () => {
    return {
      pageBreak: 'after',
      dontBreakRows: true,
      text: [
        { text: lang === 'en' ? "General Questions \n\n" : 'Questions générales \n\n', style: "header" },
        ...detailsData.screening_question_responses.map((question, i) => {
          return (
            {
              text: [
                {
                  text: question.question[lang].text,
                  style: "question",
                },
                { text: ` ${question.response ? (lang === 'en' ? ` Yes` : ` Oui`) : (lang === 'en'  ? ` No` : ` Non`)} \n\n`, style: "ans" },
              ],
              style: "answerList",
            }
          );
        }),
      ],
    }
  }

  const getInDepthQuestions = () => {
    return detailsData.in_deplth_response.map((indepth, i) => {
      return [{
        pageBreak: i%2 == 0 ? undefined : 'after',
        dontBreakRows: true,
        text: [
          { 
            text: lang === 'en' ?
            `\n Disorder: ${disorderNames[indepth.name] ? (disorderNames[indepth.name][lang] || indepth.name) : indepth.name} \n\n`
            :
            `\n Désordre: ${disorderNames[indepth.name] ? (disorderNames[indepth.name][lang] || indepth.name) : indepth.name} \n\n`, 
            style: "header" 
          },
          ...indepth[indepth.name].map((question, i) => {
            const responses = [
              {en: "Never", fr: "Jamais"},
              {en: "Rarely", fr: "Rarement"},
              {en: "Sometimes", fr: "quelquefois"},
              {en: "Frequently", fr: "Fréquemment"},
              {en: "All the time", fr: "Tout le temps"},
            ]
            return (
              {
                text: [
                  {
                    text: question.question[lang].text + " ",
                    style: "question",
                  },
                  { text: ` ${responses[question.response][lang]} \n\n`, style: "ans" },
                ],
                style: "answerList",
              }
            );
          }),
        ],
      }]
    })
  }

  const getHealthHistoryQuestions = () => {
    return {
      pageBreak: undefined, //'after',
      dontBreakRows: true,
      text: [
        { text: lang === 'en' ? "\n Health History \n\n" : "\n Antécédents médicaux \n\n", style: "header" },
        ...detailsData.health_history_responses.map((question, i) => {
          return (
            {
              text: [
                {
                  text: question.question[lang].text,
                  style: "question",
                },
                { text: ` ${question.response.length > 0
                  ? question.response.map(
                      (hhResponse, index) =>
                        (index != 0 ? ", " : "") + hhResponse[lang]
                    )
                  : (lang === 'en' ? "No" : "Non")} \n\n`, style: "ans" },
              ],
              style: "answerList",
            }
          );
        }),
      ],
    }
  }

  const getLifeFunctionsQuestions = () => {
    return {
      pageBreak: 'after',
      dontBreakRows: true,
      text: [
        { text: lang === 'en' ? "\n Life Functions \n\n" : "\n Fonctions vitales \n\n", style: "header" },
        ...detailsData.life_functions_responses.map((data, i) => {
          return (
            {
              text: [
                {
                  text: data.question[lang].text,
                  style: "question",
                },
                { text: ` ${data.question.question_type === "disablity"
                ? data.response === -1
                  ? (lang === 'en' ? "No" : 'Non')
                  : data.response.text[lang]
                : data.question.question_type === "percent"
                ? data.response === -1
                  ? "0/100"
                  : `${data.response}/100`
                : data.response === -1
                ? "0/60"
                : `${data.response}/60`} \n\n`, style: "ans" },
              ],
              style: "answerList",
            }
          );
        }),
      ],
    }
  }

  const getFollowUpQuestions = () => {
    return detailsData.follow_up_responses && detailsData.follow_up_responses.length > 0 ? {
      text: [
        { text: lang === 'en' ? "\n Follow Up \n\n" : "\n Suivre \n\n", style: "header" },
        ...detailsData.follow_up_responses.map((question, i) => {
          return (
            {
              text: [
                {
                  text: question.question[lang].text,
                  style: "question",
                },
                { text: ` ${!Array.isArray(question.response) ? question.response : question.response.length > 0
                  ? question.response.map(
                      (hhResponse, index) =>
                        (index != 0 ? ", " : "") + hhResponse[lang]
                    )
                  : (lang === 'en' ? "No" : "Non")} \n\n`, style: "ans" },
              ],
              style: "answerList",
            }
          );
        }),
      ],
    } : []
  }

  const docDefinition = {
    userPassword: fromEmail ? detailsData?.user?.pdfPassword : undefined,
    ownerPassword: fromEmail ? '123456' : undefined,
    pageOrientation: 'portrait',
    pageSize: 'LETTER',

    header(currentPage) {
      return { text: currentPage, margin: [767, 16, 0, 0] };
    },
    content: [
      {
        text: lang === 'en' ?
        `Assessment Details for ${date.format(new Date(detailsData.completed_at), "MMM DD, YYYY")} at ${date.format(new Date(detailsData?.completed_at), "hh:mm A")}`
        :
        `Détails de l'évaluation pour ${moment(new Date(detailsData.completed_at)).lang(lang).format("MMM DD, YYYY")} à ${moment(new Date(detailsData?.completed_at)).lang(lang).format("hh:mm A")}`,
        style: "header",
      },
      {
        style: "tableExample",
        layout: {
              hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 1 : 1;
              },
              hLineColor: function (i, node) {
                  return 'gray';
              },
              vLineColor: function (i, node) {
                  return 'gray';
              },
              hLineStyle: function (i, node) { 
                  if (i === 0 || i === node.table.body.length) {
                      return null;
                  }
                  return {dash: {length: 10, space: 4}};
              },
              vLineStyle: function (i, node) {
                  if (i === 0 || i === node.table.widths.length) {
                      return null;
                  }
                  return { margin: [10, 10, 10, 10] };
                  return {dash: {length: 2}};
              },
              // paddingLeft: function(i, node) { return 4; },
              // paddingRight: function(i, node) { return 4; },
              // paddingTop: function(i, node) { return 2; },
              // paddingBottom: function(i, node) { return 2; },
              // fillColor: function (i, node) { return null; }
          },
        table: {
          // dontBreakRows: true, 
          widths: ["*"],
          body: [
            [
              getScreeningQuestions()
            ],
            ...getInDepthQuestions(),
            [
              getHealthHistoryQuestions()
            ],
            [
              getLifeFunctionsQuestions()
            ],
            ... (detailsData.follow_up_responses && detailsData.follow_up_responses.length > 0 ? 
            [[
              getFollowUpQuestions()
            ]] : [])
          ],
        },
      },
    ],
    styles: {
      tableExample: {
        width: "100%",
      },
      header: {
        fontSize: 15,
        bold: true,
        margin: [0, 0, 0, 5],
      },
      box: {
        border: 1,
      },
      question: {
        color: "gray",
      },
      ans: {
        bold: true,
        margin: [5, 5, 5, 5],
      },
      answerList: {
        margin: [5, 5, 5, 5],
      },
    },
    info: {
      title: `${ (lang === 'en' ? 'Assessment' : 'Évaluation ') + ' '}${ pdfmonth }-${ pdfday}`,
    },
  };

  if (action == 'download') {
    pdfMake
      .createPdf(docDefinition)
      .download(`${(lang === 'en' ? 'Assessment' : 'Évaluation ') + ' '}${ pdfmonth }-${ pdfday}`);
  } else if (action == 'print') {
    pdfMake.createPdf(docDefinition).print({}, window);
  } else if (action == 'getBlob') {
    return new Promise((resolve, reject) => {
      pdfMake.createPdf(docDefinition).getBlob((blob) => resolve(blob));
    });
  }
};
