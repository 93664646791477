import React from "react"
import Slider from "react-slick"
import { withTranslation } from "react-i18next"
import ChatImg from "../../assets/images/chat.png"
import "../../css/HomeTestimonialsCarousel.scss"

const TestimonialsCarousel = (props) => {
const { t, i18n } = props
  return (
    <div className="CarouselWrapper">
      <Slider
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={8000}
        dots={false}
        arrows={true}
        nextArrow={<RightArrow />}
        prevArrow={<LeftArrow />}
      >
        <Component t={t} i18n={i18n} />
        <Component2 t={t} i18n={i18n} />
        <Component3 t={t} i18n={i18n} />
        <Component4 t={t} i18n={i18n} />
        <Component5 t={t} i18n={i18n} />
        <Component6 t={t} i18n={i18n} />
      </Slider>
    </div>
  )
}

const Component = ({t, i18n}) => (
  <div className="">
    <div className="row ml-0 mr-0 testimonials-block-two">
      <div className="fbnClient_col-6_1 wow img-block fadeInUp">
          <img src={require('../../assets/images/testimonial/img1.png')} alt="" className="man" />
        {/* <div>
          <img src={ChatImg} alt="" className="manFeed" />
        </div> */}
      </div>
      <div className="fbnClient_col-6_2 textimonial-section">
        <h4 className="wow fadeInUp">{t("testimonialCarousel.headline")}</h4>
        <h2 className="fbnClient_col-6_2_1 wow fadeInUp">
          {t("testimonialCarousel.description1")}
        </h2>
        <p className="fbnClient_col-6_2_2 relative">
          <span>
            <i className="fas fa-quote-left"></i>
          </span>
          <span className="d-inline textc wow fadeInUp">
          {t("testimonialCarousel.component1.description2")}
          </span>
        </p>
        <p className="fbnClient_col-6_2_3 homeText wow fadeInUp">
        {t("testimonialCarousel.component1.description3")}
        </p>
        {/* <div className="d-flex align-items-center">
          <button className="btn homeButtons wow fadeInUp">
            More Success Stories
          </button>
        </div> */}
      </div>
    </div>
  </div>
)

const Component2 = ({t, i18n}) => (
  <div className="">
    <div className="row ml-0 mr-0 testimonials-block-two">
      <div className="fbnClient_col-6_1 img-block wow fadeInUp">
          <img src={require('../../assets/images/testimonial/img2.png')} alt="" className="man" />
        {/* <div>
          <img src={ChatImg} alt="" className="manFeed" />
        </div> */}
      </div>
      <div className="fbnClient_col-6_2 textimonial-section">
        <h4 className="wow fadeInUp">{t("testimonialCarousel.headline")}</h4>
        <h2 className="fbnClient_col-6_2_1 wow fadeInUp">
          {t("testimonialCarousel.description1")}
        </h2>
        <p className="fbnClient_col-6_2_2 relative">
          <span>
            <i className="fas fa-quote-left"></i>
          </span>
          <span className="d-inline textc wow fadeInUp">
            "{t("testimonialCarousel.component2.description2")}"
          </span>
        </p>
        <p className="fbnClient_col-6_2_3 homeText wow fadeInUp">
        {t("testimonialCarousel.component2.description3")}
        </p>
        {/* <div className="d-flex align-items-center">
          <button className="btn homeButtons wow fadeInUp">
            More Success Stories
          </button>
        </div> */}
      </div>
    </div>
  </div>
)

const Component3 = ({t, i18n}) => (
  <div className="">
    <div className="row ml-0 mr-0 testimonials-block-two">
      <div className="fbnClient_col-6_1 wow img-block fadeInUp">
          <img src={require('../../assets/images/testimonial/img5.png')} alt="" className="man" />
        {/* <div>
          <img src={ChatImg} alt="" className="manFeed" />
        </div> */}
      </div>
      <div className="fbnClient_col-6_2 textimonial-section">
        <h4 className="wow fadeInUp">{t("testimonialCarousel.headline")}</h4>
        <h2 className="fbnClient_col-6_2_1 wow fadeInUp">
          {t("testimonialCarousel.description1")}
        </h2>
        <p className="fbnClient_col-6_2_2 relative">
          <span>
            <i className="fas fa-quote-left"></i>
          </span>
          <span className="d-inline textc wow fadeInUp">
            "{t("testimonialCarousel.component3.description2")}”
          </span>
        </p>
        <p className="fbnClient_col-6_2_3 homeText wow fadeInUp">
        {t("testimonialCarousel.component3.description3")}
        </p>
        {/* <div className="d-flex align-items-center">
          <button className="btn homeButtons wow fadeInUp">
            More Success Stories
          </button>
        </div> */}
      </div>
    </div>
  </div>
)

const Component4 = ({t, i18n}) => (
  <div className="">
    <div className="row ml-0 mr-0 testimonials-block-two">
      <div className="fbnClient_col-6_1 img-block wow fadeInUp">
          <img src={require('../../assets/images/testimonial/img6.png')} alt="" className="man" />
        {/* <div>
          <img src={ChatImg} alt="" className="manFeed" />
        </div> */}
      </div>
      <div className="fbnClient_col-6_2 textimonial-section">
        <h4 className="wow fadeInUp">{t("testimonialCarousel.headline")}</h4>
        <h2 className="fbnClient_col-6_2_1 wow fadeInUp">
          {t("testimonialCarousel.description1")}
        </h2>
        <p className="fbnClient_col-6_2_2 relative">
          <span>
            <i className="fas fa-quote-left"></i>
          </span>
          <span className="d-inline textc wow fadeInUp">
            "{t("testimonialCarousel.component4.description2")}"
          </span>
        </p>
        <p className="fbnClient_col-6_2_3 homeText wow fadeInUp">
        {t("testimonialCarousel.component4.description3")}
        </p>
        {/* <div className="d-flex align-items-center">
          <button className="btn homeButtons wow fadeInUp">
            More Success Stories
          </button>
        </div> */}
      </div>
    </div>
  </div>
)

const Component5 = ({t, i18n}) => (
  <div className="">
    <div className="row ml-0 mr-0 testimonials-block-two">
      <div className="fbnClient_col-6_1 img-block wow fadeInUp">
          <img src={require('../../assets/images/testimonial/img12.png')} alt="" className="man" />
        {/* <div>
          <img src={ChatImg} alt="" className="manFeed" />
        </div> */}
      </div>
      <div className="fbnClient_col-6_2 textimonial-section">
        <h4 className="wow fadeInUp">{t("testimonialCarousel.headline")}</h4>
        <h2 className="fbnClient_col-6_2_1 wow fadeInUp">
          {t("testimonialCarousel.description1")}
        </h2>
        <p className="fbnClient_col-6_2_2 relative">
          <span>
            <i className="fas fa-quote-left"></i>
          </span>
          <span className="d-inline textc wow fadeInUp">
          {t("testimonialCarousel.component5.description2")}
          </span>
        </p>
        <p className="fbnClient_col-6_2_3 homeText wow fadeInUp">
        {t("testimonialCarousel.component5.description3")}
        </p>
        {/* <div className="d-flex align-items-center">
          <button className="btn homeButtons wow fadeInUp">
            More Success Stories
          </button>
        </div> */}
      </div>
    </div>
  </div>
)

const Component6 = ({t, i18n}) => (
  <div className="">
    <div className="row ml-0 mr-0 testimonials-block-two">
      <div className="fbnClient_col-6_1 img-block wow fadeInUp">
          <img src={require('../../assets/images/testimonial/img4.png')} alt="" className="man" />
        {/* <div>
          <img src={ChatImg} alt="" className="manFeed" />
        </div> */}
      </div>
      <div className="fbnClient_col-6_2 textimonial-section">
        <h4 className="wow fadeInUp">{t("testimonialCarousel.headline")}</h4>
        <h2 className="fbnClient_col-6_2_1 wow fadeInUp">
          {t("testimonialCarousel.description1")}
        </h2>
        <p className="fbnClient_col-6_2_2 relative">
          <span>
            <i className="fas fa-quote-left"></i>
          </span>
          <span className="d-inline textc wow fadeInUp">
            “{t("testimonialCarousel.component6.description2")}”
          </span>
          <br></br>
          <span className="d-inline textc wow fadeInUp">
            “{t("testimonialCarousel.component6.description3")}”
          </span>
          <br></br>
          <span className="d-inline textc wow fadeInUp">
            “{t("testimonialCarousel.component6.description4")}”
          </span>
          <br></br>
          <span className="d-inline textc wow fadeInUp">
            “{t("testimonialCarousel.component6.description5")}”
          </span>
        </p>
        {/* <p className="fbnClient_col-6_2_3 homeText wow fadeInUp">
          lskfkahsfhakshfjkhasjkhfaskj
        </p> */}
        {/* <div className="d-flex align-items-center">
          <button className="btn homeButtons wow fadeInUp">
            More Success Stories
          </button>
        </div> */}
      </div>
    </div>
  </div>
)

const LeftArrow = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className="left-rounded-arrow left-rounded-arrow_left"
  ></button>
)

const RightArrow = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className="left-rounded-arrow left-rounded-arrow_right"
  ></button>
)

export default withTranslation()(TestimonialsCarousel)
