import { baseUrl } from "../lib/constants";
import moment from "moment";
import { logEvent } from "../index"

export const fetchAssessmentTimestamp = async (setTimer, viewPopup, getAllData) => {
  try {
    const response = await fetch(baseUrl + "/assessment/time-answers", {
      credentials: "include",
      mode: "cors",
    });
    const data = await response.json();

    const { time, status } = data;

    if (response.status === 200) {
      localStorage.setItem("timeStart", time);
      const timeStamp = Math.round((moment() - moment(time)) / 60000);
      if (setTimer) {
        setTimer(moment() - moment(time));
        if (status === "end") {
          viewPopup();
          getAllData(baseUrl + "/assessment");
          logEvent("fetchAssessmentTimestamp /assessment/time-answers successful")
        }
      }

      return timeStamp;
    }
  } catch (error) {
    logEvent("fetchAssessmentTimestamp /assessment/time-answers failed")
    throw error;
  }
};

export const getSettings = async (getInitialSetting) => {
  try {
    const response = await fetch(`${baseUrl}/assessment/settings`, {
      credentials: "include",
      mode: "cors",
      method: "get",
    });
    const data = await response?.json();
    data && localStorage.setItem("userEmail", data.settings?.url);
    getInitialSetting(data?.settings?.notificationFrequency, data?.settings?.url);
    logEvent("getSettings /assessment/settings successful")
  } catch (error) {
    logEvent("getSettings /assessment/settings failed")
    throw error;
  }
};

export const setSettings = async (url, notificationFrequency) => {
  try {
    await fetch(`${baseUrl}/assessment/settings`, {
      credentials: "include",
      mode: "cors",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: url,
        notificationFrequency: notificationFrequency,
      }),
    });
    logEvent("setSettings /assessment/settings successful")
  } catch (error) {
    logEvent("setSettings /assessment/settings failed")
    throw error;
  }
};
