const key = "showMobileApplinks"

export const getWhetherShowedMobileAppLinks = () => {
  if (sessionStorage.getItem("key")) {
    const obj = JSON.parse(sessionStorage.getItem("key"))

    if (obj.showedMobileAppLinks !== true) {
      return false
    } else {
      return true
    }
  }
  return false
}

export const setMobileLinksShowed = () => {
  try {
    sessionStorage.setItem(
      "key",
      JSON.stringify({ showedMobileAppLinks: true })
    )
  } catch {}
}
