import React, { useState } from 'react'
import Task from './Task'
import DeleteIcon from '../../assets/images/delete-icon.svg';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AddChipsIcon from '../../assets/images/add-chips-btn.svg';
import MinusIcon from '../../assets/images/minus.svg';

// const Plan = ({deleteTask, goal, goalIndex, planKey, i18n, updateTaskStatus, updateGoalLoading, updateTaskType, updateTaskDays, getTaskDueDate, onDateChange, updateWeekDays, deletePlan, addTask, settaskTitle, taskTitle, getTaskTitle,isActive,showTooltipOnhover}) => {
const Plan = ({ googleLogIn, validateToken, getAllEventsIn, updateTaskEventId, setUpdateGoalLoading, deleteTask, allEvents, googleLoggedin, setGoogleLoggedin, goal, goalIndex, planKey, i18n, updateTaskStatus, updateGoalLoading, updateTaskType, updateTaskDays, getTaskDueDate, onDateChange, updateWeekDays, deletePlan, addTask, settaskTitle, taskTitle, getTaskTitle, isActive, showTooltipOnhover, t , LOAD_INCENTIVES, LOAD_GOOGLE_CALENDAR}) => {

    const lang = i18n.language;
    const [coins, setCoins] = useState(false);
    const addCoins = event => {
        setCoins(current => !current)
    }
    return (
        <div className="full-box">
            <div className="header-content">
                <h5>{goal?.plans[planKey]?.title[lang]}</h5>
                <div className="right-content">
                    {LOAD_INCENTIVES &&
                        <div onClick={addCoins} className={`coins coins-tooltip-part delete-btn-tooltip ${coins ? 'addBoxshdow' : ''} ${isActive ? 'tooltip-click-show' : ''}`}>
                        <div className='emark-tooltip tooltip-left del-tooltip coins-tooltip'>You can earn coins by completing assessments on time and completing Jumpstart plans.  Coins can then be used towards lottery tickets for cash prizes or coupons. </div>
                            <h6><img src={require('../../assets/images/goal_coin.svg').default} className="icon" />c * 200</h6>
                           
                            <div onClick={e => e.stopPropagation()} className={`${coins ? 'coin-tooltip' : 'd-none'}`}>
                            <div className='coins-overlay' onClick={() => addCoins()}></div>
                                <div className="up-arrow">
                                    <p>{t('goalPage.goalPlan.coinsRemaining')}<b className='coins-count'>5000</b></p>
                                    <div className='inc-dec-part'>
                                        <button className='btn dec' disabled={updateGoalLoading}>
                                            <img src={MinusIcon} />
                                        </button>
                                        <input type='text' className='input-no' placeholder="100" />
                                        <button onClick={() => alert('you clicked...')} className='btn inc'><img src={AddChipsIcon} /></button>
                                    </div> 
                                    <span>Enter how many coins out of the total you want to allocate for this plan</span>
                                </div>
                            </div>

                            {/* <div className={coins ? 'custom-tooltip tooltip-top' : 'd-none'}
                            >
                                <div className='toltip-block'>
                                    <p>coins remaining - <b className='coins-count'>5000</b></p>
                                    <div className='inc-dec-part'>
                                        <button className='btn dec' disabled={updateGoalLoading}>
                                            <img src={MinusIcon} />
                                        </button>
                                        <input type='text' className='input-no' placeholder="100" />
                                        <button className='btn inc'><img src={AddChipsIcon} /></button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    }
                    <button className={`btn z-index-0 tooltip-part delete-btn-tooltip ${isActive ? 'tooltip-click-show' : ''}`} disabled={updateGoalLoading}  data-tut="delete-symptom-btn" onClick={() => deletePlan(goal.id, planKey)}>
                        <img src={DeleteIcon} className="icon" />
                        {t('goalPage.goalPlan.deletePlanBtn')}
                        {/* <div className='emark-tooltip tooltip-left del-tooltip'>Delete your plan</div> */}
                    </button>
                </div>
            </div>
            
                <div className="event-log-part">
                    {goal.plans[planKey]?.tasks && goal.plans[planKey]?.tasks.map((task, taskIndex) => (
                        <div key={taskIndex} id={taskIndex}>
                            <Task
                                getAllEventsIn={getAllEventsIn}
                                setUpdateGoalLoading={setUpdateGoalLoading}
                                updateTaskEventId={updateTaskEventId}
                                validateToken={validateToken}
                                allEvents={allEvents}
                                googleLoggedin={googleLoggedin}
                                setGoogleLoggedin={setGoogleLoggedin}x
                                key={taskIndex}
                                goal={goal}
                                deleteTask={deleteTask}
                                task={task}
                                goalIndex={goalIndex}
                                taskIndex={taskIndex}
                                planKey={planKey}
                                i18n={i18n}
                                updateTaskStatus={updateTaskStatus}
                                updateGoalLoading={updateGoalLoading}
                                updateTaskDays={updateTaskDays}
                                updateTaskTypeNonGoogle={updateTaskType}
                                getTaskDueDate={getTaskDueDate}
                                onDateChange={onDateChange}
                                updateWeekDays={updateWeekDays}
                                isActive={isActive}
                                showTooltipOnhover={showTooltipOnhover}
                                t={t}
                                googleLogIn={googleLogIn}
                                LOAD_GOOGLE_CALENDAR={LOAD_GOOGLE_CALENDAR}
                            />
                            {/* <Task key={taskIndex} goal={goal} deleteTask={deleteTask} task={task} goalIndex={goalIndex} taskIndex={taskIndex} planKey={planKey} i18n={i18n} updateTaskStatus={updateTaskStatus} updateGoalLoading={updateGoalLoading} updateTaskDays={updateTaskDays} updateTaskType={updateTaskType} getTaskDueDate={getTaskDueDate} onDateChange={onDateChange} updateWeekDays={updateWeekDays} isActive={isActive} showTooltipOnhover={showTooltipOnhover} /> */}
                        </div>
                    ))}
                </div>
            <div className="add-btn" data-tut="add-specific-task">
                <form onSubmit={e => addTask(e, goalIndex, planKey, goal)}>
                    <input disabled={updateGoalLoading} type="text" id="search-bar" placeholder= {t('goalPage.goalPlan.placeholder')} onChange={e => settaskTitle({ ...taskTitle, [goalIndex]: { ...taskTitle[goalIndex], [planKey]: e.target.value } })} value={getTaskTitle(goalIndex, planKey)} />
                    <a href="#" disabled={updateGoalLoading}  onClick={(e) => addTask(e, goalIndex, planKey, goal)}><span>{t('goalPage.goalPlan.addPlanBtn')}</span></a>
                </form>
            </div>
        </div>
    )
}

export default Plan
