export default {
    personalInfo: {
      firstName: "",
      lastName: "",
      gender: [
        { value: "Male", caption: "Male", selected: false },
        { value: "Female", caption: "Female", selected: false },
        { value: "transgender", caption: "Transgender", selected: false },
        { value: "nonBinary", caption: "Non-binary", selected: false },
        { value: "twoSpirited", caption: "Two-spirited", selected: false },
        { value: "preferNotToSay", caption: "Prefer not to say", selected: false },
      ],
      singleGender: "",
      preferredLanguage: [
        { value: "en", caption: "English", selected: false },
        { value: "fr", caption: "French", selected: false },
      ],
      singlePreferredLanguage: "",
      age: [
        { value: "< 15", caption: "< 15", selected: false },
        { value: "16 - 30", caption: "16 - 30", selected: false },
        { value: "31 - 45", caption: "31 - 45", selected: false },
        { value: "46 - 60", caption: "46 - 60", selected: false },
        { value: "61 - 75", caption: "61 - 75", selected: false },
        { value: "> 76", caption: "> 76", selected: false },
      ],
      singleAge: "",
      location: [
        { value: "Nunavut", caption: "Nunavut", selected: false },
        { value: "Quebec", caption: "Quebec", selected: false },
        {
          value: "Northwest Territories",
          caption: "Northwest Territories",
          selected: false,
        },
        { value: "Ontario", caption: "Ontario", selected: false },
        {
          value: "British Columbia",
          caption: "British Columbia",
          selected: false,
        },
        { value: "Alberta", caption: "Alberta", selected: false },
        { value: "Saskatchewan", caption: "Saskatchewan", selected: false },
        { value: "Manitoba", caption: "Manitoba", selected: false },
        { value: "Yukon", caption: "Yukon", selected: false },
        {
          value: "Newfoundland and Labrador",
          caption: "Newfoundland and Labrador",
          selected: false,
        },
        { value: "New Brunswick", caption: "New Brunswick", selected: false },
        { value: "Nova Scotia", caption: "Nova Scotia", selected: false },
        {
          value: "Prince Edward Island",
          caption: "Prince Edward Island",
          selected: false,
        },
      ],
      singleLocation: "",
      doctorsEmail: "",
      contact: "",
      organizationAccessCode: "",
    },
  
    notificationScheduler: {
      email: false,
      phone: false,
      textNotifications: false,
      weekday: null,
      weeksPerMonth: [
        { value: 1, caption: "1", selected: false },
        { value: 2, caption: "2", selected: false },
        { value: 4, caption: "4", selected: false },
      ],
      singleWeeksPerMonth: "",
    },
  
    securityQuestions: {
      questionsAndAnswers: [
        {
          caption: "Security question 1: ",
          name: "question1",
          questions: [
            "Your childhood hero?",
            "Your favorite book?",
            "Sibling's current city?",
            "First pet's name?",
            "Your favorite movie?",
          ],
          selectedQuestion: null,
          answer: "",
        },
        {
          caption: "Security question 2: ",
          name: "question2",
          questions: [
            "Your favorite newspaper?",
            "Your first music album?",
            "Your first job location?",
            "Hospital you were born in?",
            "Favorite cousin's name?",
          ],
          selectedQuestion: null,
          answer: "",
        },
        {
          caption: "Security question 3: ",
          name: "question3",
          questions: [
            "Favourite childhood food?",
            "Your favorite teacher?",
            "Toughest school subject?",
            "Favourite family vacation?",
            "Your childhood nickname?",
          ],
          selectedQuestion: null,
          answer: "",
        },
      ],
    },
  
    demographicQuestions: {
      questions: [
        {
          name: "demquestion1",
          caption: "Which option describes you?",
          questions: ["Male", "Female", "Other", "Prefer not to answer"],
          answer: null,
        },
        {
          name: "demquestion2",
          caption: "What region do you live in?",
          questions: [
            "Atlantic (NB, PE, NS, NS)",
            "Quebec",
            "Ontario",
            "Prairies (MB/SK)",
            "West (BC/SK)",
            "Other",
            "Prefer not to answer",
          ],
          answer: null,
        },
        {
          caption: "What age range are you in (years)?",
          name: "demquestion3",
          questions: [
            "< 24",
            "25 - 39",
            "40 - 54",
            "> 55",
            "Prefer not to answer",
          ],
          answer: null,
        },
        {
          caption: "What best describes your Force Type?",
          name: "demquestion4",
          questions: [
            "Army",
            "Navy",
            "Air Force",
            "Family Member",
            "Prefer not to answer",
          ],
          answer: null,
        },
  
        {
          caption: "What best describes your Rank Range?",
          name: "demquestion5",
          questions: [
            "Pte-MCpl/OS-MS",
            "Sgt-CWO/PO2-CPO1",
            "Lt-Capt/SLt-Lt(N)",
            "Maj-Col/LCdr-Capt(N)",
            "BGen-Gen/Cmdre-Adm",
            "Prefer not to answer",
          ],
          answer: null,
        },
      ],
    },
}