import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Footer from "../footer/Footer"
import "../../css/AboutUs.css"
import Header from "../navbar/Header"
import "../../css/PrivacyPolicyPage.css"
import "../../css/privacyPolicy.css"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../error/ErrorScreen"
import { useTranslation } from 'react-i18next';
const store = require('store')

const PrivacyPolicyPage = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(store.get('lang') || 'en')
    window.scrollTo(0, 0);
  }, []);
  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <>
        <div className="privacy-policy-background">
        <Header />
        <div className="privacy-policy-text" style={{background:"#dffff3"}}>
        <h2 style={{textDecoration:"underline"}}>{t('footerSection.policy')}</h2>
        <br />
        <h2 className="header">{t('termsPage.section1.mainTitle')}</h2>
                        
        <br /> {t('termsPage.section1.description1')}
        <br /> 
        <br /> {t('termsPage.section1.description2')}
        <br /> 
        <br /> {t('termsPage.section1.description3')}
        <br /> 
        <p className="paragraph">
        {t("privacyPolicyPage.privacyPolicyStatement.p1")}
          <br />
          <a
            className="link"
            target="_blank"
            href="https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/r_o_p/"
          >
            https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/r_o_p/
          </a>
          .
        </p>
        <p className="paragraph">{t("privacyPolicyPage.privacyPolicyStatement.p2")}</p>
        <p className="paragraph">{t("privacyPolicyPage.privacyPolicyStatement.p3")}</p>
        <p className="paragraph">
        {t("privacyPolicyPage.privacyPolicyStatement.p4")}
        </p>

        <ol>
          <li>
            <strong>{t("privacyPolicyPage.accountability.title")}</strong> {t("privacyPolicyPage.accountability.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.identifyingPurposes.title")}</strong> {t("privacyPolicyPage.identifyingPurposes.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.consent.title")}</strong>{t("privacyPolicyPage.consent.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.limitingCollection.title")}</strong>{t("privacyPolicyPage.limitingCollection.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.limitingDisclosureRetention.title")}</strong>{t("privacyPolicyPage.limitingDisclosureRetention.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.accuracy.title")}</strong>{t("privacyPolicyPage.accuracy.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.safeguards.title")}</strong> {t("privacyPolicyPage.safeguards.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.openness.title")}</strong> {t("privacyPolicyPage.openness.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.individualAccess.title")}</strong> {t("privacyPolicyPage.individualAccess.description")}
          </li>
          <li>
            <strong>{t("privacyPolicyPage.challengingCompliance.title")}</strong> {t("privacyPolicyPage.challengingCompliance.description")}
          </li>
        </ol>

        <h3 className="header">{t("privacyPolicyPage.websiteUse.title")}</h3>

        <p className="paragraph">
        {t("privacyPolicyPage.websiteUse.description")}
        </p>

        <h3 className="header">{t("privacyPolicyPage.exitingWebsite.title")}</h3>

        <p className="paragraph">
        {t("privacyPolicyPage.exitingWebsite.description")}
        </p>

        <h3 className="header">{t("privacyPolicyPage.safeguardsDetails.title")}</h3>

        <p className="paragraph">
        {t("privacyPolicyPage.safeguardsDetails.description")}
        </p>

        <ul>
          <li>{t("privacyPolicyPage.safeguardsDetails.list1")}</li>
          <li>{t("privacyPolicyPage.safeguardsDetails.list2")}</li>
          <li>{t("privacyPolicyPage.safeguardsDetails.list3")}</li>
          <li>{t("privacyPolicyPage.safeguardsDetails.list4")}</li>
          <li>{t("privacyPolicyPage.safeguardsDetails.list5")}</li>
          <li>{t("privacyPolicyPage.safeguardsDetails.list6")}</li>
          <li>{t("privacyPolicyPage.safeguardsDetails.list7")}</li>
        </ul>

        <p className="paragraph">&nbsp;</p>
        <p className="paragraph">{t("privacyPolicyPage.deleteAccount.text")} <a href="mailto:help@mensante.com" style={{ color: '#33ca8d' }}>help@mensante.com</a>{t('privacyPolicyPage.deleteAccount.text2')}</p>
        <h3 className="header">{t("privacyPolicyPage.updatesPrivacyPolicy.title")}</h3>

        <p className="paragraph">
        {t("privacyPolicyPage.updatesPrivacyPolicy.description")}
        </p>
        </div>
        <Footer/>
        </div>
      </>
    </ErrorBoundary>
  )
}

export default PrivacyPolicyPage
