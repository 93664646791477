import React, { useState } from "react"
import { Link } from "react-router-dom"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { withTranslation } from "react-i18next"

const TopAlert = ({params, t, i18n, disorderState}) => {

  const { selectedDisorder: [selectedDisorder, setSelectedDisorder] } = {
    selectedDisorder: useState(""),
    ...(disorderState || {})
  };

  return (
  <div
    className="alert position-relative d-flex justify-content-center rounded-0 alert-dismissible covid-19_alert m-0 p-1 align-items-center"
    role="alert"
    css={css`
      @media (max-width: 640px) {
        display: none !important;
      }
    `}
  >
   
    <Link
      className="CovidLink"
      css={css`
        fontsize: 16;
        color: #e0e0e0;
      `}
      onClick={()=>setSelectedDisorder("Covid")}
      to={{pathname: `/Education/${params}`, state: { from: 'covid-bar' }}}
    >
      {t("resourceToSupport.title")}
    </Link>
    <button
      type="button"
      className="close pt-2"
      data-dismiss="alert"
      aria-label="Close"
      style={{ marginTop: "-3px" }}
    >
      <span aria-hidden="true" style={{ color: "#e0e0e0" }}>
        &times;
      </span>
    </button>
  </div>
)}

export default withTranslation()(TopAlert);