import React, { useState, useEffect } from "react"
import { baseUrl } from "../../lib/constants"

// import downloadLogo from "../assets/images/download.svg"
import { DownloadLogo } from "../../assets/images/downloadLogo"
// import sendLogo from "../assets/images/copy.svg"
import { SendLogo } from "../../assets/images/copy"
// import reviewLogo from "../assets/images/comment.svg"
import { ReviewLogo } from "../../assets/images/comment"
// import notificationLogo from "../assets/images/notification.svg"
import { NotificationLogo } from "../../assets/images/notification"
import { CloseIcon } from "../../assets/images/close"
import OutsideClickHandler from "react-outside-click-handler"
import "../../css/NotificationScheduler.scss"

import "../../css/popup.scss"
import { logEvent } from "../../index"

export const ProgressTracker = ({ closeCheckList, assessment_id }) => {
  const [isHovered, setHovered] = useState(false)
  const [submittedData, setSubmittedData] = useState({})

  useEffect(() => {
    fetch(baseUrl + `/progress-tracker/${assessment_id}`, {
      credentials: "include",
      mode: "cors",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        logEvent("ProgressTracker /progress-tracker/accesscode successful")
        res.json()
      })
      .then((res) => setSubmittedData(res))
      .catch((err) => {
        logEvent("ProgressTracker /progress-tracker/accesscode failed")
        console.log(err)
      })
  }, [assessment_id])

  const reviewClickHandler = () => {
    closeCheckList()
    document.getElementById("summaryMainAccordion0").scrollIntoView({
      behavior: "smooth",
    })
  }
  const downloadClickHandler = () => {
    closeCheckList()
    document.getElementById("downloadActionPlan").scrollIntoView({
      behavior: "smooth",
    })
  }
  const sendActionPlanClickHandler = () => {
    closeCheckList()
    document.getElementById("sendActionPlan").scrollIntoView({
      behavior: "smooth",
    })
  }
  const signUpClickHandler = () => {
    closeCheckList()
    document.getElementById("signUp").scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <>
      <div className="modal-background" />
      <div className="modal-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => {
            closeCheckList()
          }}
        >
          <div className="checklist">
            <button
              className="close_button"
              onClick={() => closeCheckList(false)}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <CloseIcon isHovered={isHovered} />
            </button>

            <div className="checklist__menu">
              <div className="checklist__menu__element">
                <div
                  className={`checklist__menu__element__logo ${
                    submittedData.downloadActionPlan ? " completed" : ""
                  }`}
                >
                  <DownloadLogo
                    isDownloaded={submittedData.downloadActionPlan}
                  />
                </div>
                <div className="checklist__menu__element__description">
                  <h3
                    className="checklist__menu__element__description__header"
                    onClick={downloadClickHandler}
                  >
                    Download Action Plan
                  </h3>
                  <p className="checklist__menu__element__description__text">
                    Lorem ipsum dolor sit amet amarj adipisicing
                  </p>
                </div>
              </div>
              <div className="checklist__menu__element">
                <div
                  className={`checklist__menu__element__logo ${
                    submittedData.sendActionPlan ? " completed" : ""
                  }`}
                >
                  <SendLogo isSent={submittedData.sendActionPlan} />
                </div>
                <div className="checklist__menu__element__description">
                  <h3
                    className="checklist__menu__element__description__header"
                    onClick={sendActionPlanClickHandler}
                  >
                    Send Action Plan
                  </h3>
                  <p className="checklist__menu__element__description__text">
                    Lorem ipsum dolor sit amet amarj adipisicing
                  </p>
                </div>
              </div>
              <div className="checklist__menu__element">
                <div
                  className={`checklist__menu__element__logo ${
                    submittedData.reviewed ? " completed" : ""
                  }`}
                >
                  <ReviewLogo isReviewed={submittedData.reviewed} />
                </div>
                <div
                  className="checklist__menu__element__description"
                  onClick={reviewClickHandler}
                >
                  <h3 className="checklist__menu__element__description__header">
                    Review Outcomes
                  </h3>
                  <p className="checklist__menu__element__description__text">
                    Lorem ipsum dolor sit amet amarj adipisicing
                  </p>
                </div>
              </div>
              <div className="checklist__menu__element">
                <div
                  className={`checklist__menu__element__logo ${
                    submittedData.signedUp ? " completed" : ""
                  }`}
                >
                  <NotificationLogo isSignedUp={submittedData.signedUp} />
                </div>
                <div className="checklist__menu__element__description">
                  <h3
                    className="checklist__menu__element__description__header"
                    onClick={signUpClickHandler}
                  >
                    Sign Up For Notifications
                  </h3>
                  <p className="checklist__menu__element__description__text">
                    Lorem ipsum dolor sit amet amarj adipisicing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}
