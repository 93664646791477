import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'react-bootstrap';
import SlidingPanel from 'react-sliding-side-panel';
import { ErrorScreen } from '../error/ErrorScreen';
import { jsx, css } from "@emotion/react"
import 'react-sliding-side-panel/lib/index.css';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import SucessIcon from '../../assets/images/success.png'

class ReactSlidingSidePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPanel: false,
      numberOfPieces: 400
    };
  }

  setOpenPanel = (val) => {
    this.setState({
      openPanel: val,
    });
  };


  componentDidUpdate() {
    if (this.state.openPanel) {
      setTimeout(() => {
        this.setState({ numberOfPieces: 0 })
      }, 2000)
    }
  }

  render() {
    const { openPanel } = this.state;
    const { width, height } = this.props
    const sgColors = [
      "#9ce8c2",
      "#60d399",
      "#b9e2fe",
      "#4fb3f6",
      "#bdc7fb",
      "#6d83f3",
      "#ff7968",
      "#ffe8e5",
      "#fedd8e",
      "#fbbe2e"
    ];
    return (
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <div>
          <div className="bottom-bar-position mobile-devices-bottom-part">
            <div className="tab-container">
              <ul className="tabs-custom clearfix customSlider-tab-part" >
                <li className=" nav-item " onClick={() => this.setOpenPanel(true)}>
                  <a className='nav-link'>Need Help?</a>
                </li>
                {/* <li  onClick={() => this.setOpenPanel(true)}>
                <a >2000 Point Earned!</a>
              </li> */}
              </ul>
            </div>
            {/* <div
              className='ml-5'
              onClick={() => this.setOpenPanel(true)}
              css={css`
                filter: drop-shadow( 1px 2px 8px hsl(147deg 50% 77% / 0.3) ) drop-shadow( 2px 4px 16px hsl(147deg 50% 77% / 0.3) ) drop-shadow( 4px 8px 32px hsl(147deg 50% 77% / 0.3) );
                border-style: solid;
                border-color: #cfcfcf50;
                border-width: 2px;
              `}
              style={{
                background: 'transparent',
                'border-bottom': ' 1px solid rgba(0, 0, 0, 0.1)',
                position: 'fixed',
                bottom: '0px',
                // width: '100%',
                outline: 'none',
                border: 0,
                zIndex: 10,
                cursor: 'pointer'
      }}
            >
              <div className="row" className="footer-content p-0" style={{
                background: 'rgb(245, 245, 245)'
              }}>
                <div className="col-12 py-2">
                  <div style={{
                    width: '100%',
                    background: 'rgb(245, 245, 245)',
                  }}>
                    <p style={{ fontSize: 17 }}>Find out more </p>
                  </div>
                </div>
                {/* <div className="col-6">
                  <div className="d-flex flex-row justify-content-end align-items-start">
                    <button
                      type="button"
                      style={{
                        width: '60px',
                        height: '60px',
                        border: '0',
                        marginTop: '10px',
                        background: 'rgb(245, 245, 245)',
                        outline: '0px',
                      }}
                      onClick={() => this.setOpenPanel(true)}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </button>
                  </div>
                </div> *}
              </div>
            </div> */}
          </div>
          <SlidingPanel
            type="bottom"
            isOpen={openPanel}
            size={50}
            panelContainerClassName=""
            backdropClicked={() => this.setOpenPanel(false)}
          >

            <div className="tab-container">
              <ul className="tabs-custom clearfix customSlider-tab-part inner-heading-tab" style={{ marginLeft: 50 }}>
                <li className="active nav-item" onClick={() => this.setOpenPanel(false)}>
                  <a className='nav-link'>Need Help?</a>
                </li>
                {/* <li  onClick={() => this.setOpenPanel(true)}>
                <a >2000 Point Earned!</a>
              </li> */}
              </ul>
            </div>
            <div className='bg-white'>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      {/* <button
                        type="button"
                        style={{
  width: '60px', height: '6px', border: '0', marginTop: '10px', background: '#D2D2D2', borderRadius: '100px', outline: '0px',
  }}
                        onClick={() => this.setOpenPanel(false)}
                      >
  &nbsp;
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='ribbion-animation'>
                <Confetti
                  width={width}
                  height={height}
                  colors={sgColors}
                  numberOfPieces={this.state.numberOfPieces}
                />
                <div className="py-4 panel-content-part">
                  <div className='container-fluid demo-conteiner congratulation-slider'>
                    <div className='row table-trophy-part'>
                      <div className='col-xl-3 col-lg-12 col-md-12'>
                        <div className='throphy-section'>
                          <div className='sucess-part'>
                            <img src={SucessIcon} alt="throfy" />
                          </div>
                          <div className='month-avg-part'>
                            <div className='user-information'>
                              <p>Completed Plans</p>
                              <b>3</b>
                            </div>
                            <div className='user-information'>
                              <p>Earned Coins</p>
                              <b>3000</b>
                            </div>
                            <div className='user-information'>
                              <p>Month completion Rate</p>
                              <b>2</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-4 col-lg-6 col-md-6 small-spacing'>
                        <div className='sucess-text-part'>
                          <h5>Congratulations!</h5>
                          <p>You have won <b>5000</b> coins. You can use your coins to purchase raffle tickets.  You have <b>5000</b> coins total, and can purchase <b>5</b> raffle tickets.  Each raffle ticket costs 1000 coins.</p>
                        </div>
                        <div className='button-part'>
                          <button className='btn btn-primary'>View more</button>
                        </div>
                      </div>
                      <div className='col-xl-5 col-lg-6 col-md-6 small-spacing'>

                        <table className='coins-table'>
                          <thead>
                            <tr>
                              <th>
                                plan name
                              </th>
                              <th>
                                point value
                              </th>
                              <th>
                                completion date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Crisis Plan</td>
                              <td>2000</td>
                              <td>21/11/2022</td>
                            </tr>
                            <tr>
                              <td>Create Crisis Plan</td>
                              <td>2000</td>
                              <td>21/11/2022</td>
                            </tr>
                            <tr>
                              <td>Crisis Plan</td>
                              <td>2000</td>
                              <td>21/11/2022</td>
                            </tr>
                            <tr>
                              <td>Create Crisis Plan</td>
                              <td>2000</td>
                              <td>21/11/2022</td>
                            </tr>
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                  {/* <div className='container demo-conteiner congratulation-slider'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='sucess-part'>
                          <img src={SucessIcon} alt="throfy" />
                        </div>
                      </div>
                      <div className='col-md-6'>
                          <div className='sucess-text-part'>
                            <h5>Congratulations!</h5>
                            <p>You have won <b>5000</b> coins. You can use your coins to purchase raffle tickets.  You have <b>5000</b> coins total, and can purchase <b>5</b> raffle tickets.  Each raffle ticket costs 1000 coins.</p>
                          </div>
                          <div className='button-part'>
                            <button className='btn btn-primary'>View more</button>
                          </div>
                        </div>
                    </div>
                  </div> */}
                  {/* <div className='container demo-conteiner congratulation-slider'>
                    <div className='sucess-part'>
                      <img src={SucessIcon} alt="throfy" />
                    </div>
                    <div className='sucess-text-part'>
                      <h5>Congratulations!</h5>
                      <p>You have won <b>5000</b> coins. You can use your coins to purchase raffle tickets.  You have <b>5000</b> coins total, and can purchase <b>5</b> raffle tickets.  Each raffle ticket costs 1000 coins.</p>
                    </div>
                    <div className='button-part'>
                      <button className='btn btn-primary'>View more</button>
                    </div>
                  </div> */}
                  {/* <div className="container demo-container">
                    <div></div>
                    <div className="right-up-arrow">
                    <button
                        className="right-arrow"
                        type="button"
                        onClick={() => this.setOpenPanel(false)}
                      >
                        <i className="fa fa-chevron-up"></i>
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6" id="border-line">
                        <div className="d-flex flex-row mb-3 mobile-text-up">
                          <div style={{ width: '14%' }}>
                            <img
                              alt=""
                              src={require('../../assets/images/icons/badge-icon.png')}
                            />
                          </div>
                          <div style={{ width: '86%' }}>
                            <h3>
                              TASK CLEANER
                            </h3>
                            <span style={{ color: 'var(--green)' }}>0-100 Coins Earned</span>
                          </div>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                          \Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                          irure dolor in reprehenderit in voluptate velit esse cillum dolore
                          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                        <div className="link-button-section">
                          {[...Array(5)].map((x, i) => (
                            <a className="link-btn" style={{ color: '#33ca8f', padding: '10px 20px', 'border-radius': '20px' }} href="javascript:void(0)" key={i}>
                              tag
                              {' '}
                              {i}
                            </a>
  ))}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="row">
                          <div className="col-12">
                            <Button className="float-right" style={{ backgroundColor: 'var(--green)', borderColor: 'var(--green)', borderRadius: '100px' }}>
                              200 Users earned
                            </Button>
                          </div>
                          <div className="col-12 mt-4">
                            <div className="p-4" style={{ backgroundColor: '#E9FDFF', 'border-radius': '12px', marginBottom:'50px' }}>
                              <span className="font-bold" style={{ color: 'var(--green)' }}>PRO TIPS</span>
                              <ul className="ml-3 mt-2" style={{ 'list-style-type': 'decimal' }}>
                                <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                <li> Lorem Ipsum is simply dummy text. </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </SlidingPanel>
        </div>
      </ErrorBoundary>
    );
  }
}

function ReactSlidingSidePanelFun(props) {
  const { width, height } = useWindowSize()
  return <ReactSlidingSidePanel {...props} height={height} width={width} />
}

export default withTranslation()(ReactSlidingSidePanelFun);
