import React, { Component, createRef } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import "../../css/Home.scss"
import "../../css/newsCarousal.scss"
import { baseUrl } from "../../lib/constants"
import { withTranslation } from "react-i18next"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../../components/error/ErrorScreen"
import { logEvent } from "../../index"
import axios from "axios"
import '../..'
import { DemoClientAccessid } from "../../components/popups/accessCode/DemoClientAccessid"


class Home extends Component {
     constructor(props) {
          super(props)
          this.modelRef = createRef()
          this.state = {
               auth: false,
               showClientAccessPopUp: true,
               inProgress: false,
               email: '',
               error: '',
               loading: false,
               success: false,
               hasClient: false,
               mainLoader: true,
               showAssessmentGapPopup: false,
          }
     }

     setshowAssessmentGapPopup = (flag) => {
          this.setState({
               showAssessmentGapPopup: flag
          })
     }

     closeAssessmentGapPopup = () => {
          this.setshowAssessmentGapPopup(false)
     }


     componentDidMount() {

     }

     // useEffect(() => {
     //   if (!getWhetherShowedMobileAppLinks() && (isAndroid || isIOS)) {
     //     window.location.replace(`https://mensante-assessment.ca/DownloadApp`)
     //   }
     // }, [])

     logOut = () => {
          fetch(baseUrl + "/logout", { credentials: "include", mode: "cors" }).then(
               (response) => {
                    if (response) {
                         this.setState({ auth: false })
                         this.props.history.push("/")
                         logEvent("logOut /logOut successful")
                    } else logEvent("logOut /logout failed")
               }
          ).catch((err) => {
               console.log("Home.jsx", err);
          })
     }

     checkAssessmentInProgress = async () => {
          fetch(baseUrl + "/assessment/inprogress", {
               credentials: "include",
               mode: "cors",
               method: "GET",
          }).then((res) => res.json())
               .then(res => {
                    if (res == "") {
                         this.setState({ inProgress: false })
                         logEvent("checkAssessmentInProgress /assessment/inprogress failed")
                    } else {
                         this.setState({ inProgress: true })
                         logEvent("checkAssessmentInProgress /assessment/inprogress successful")
                    }
               })
               .catch((err) => {
                    console.log("Home.jsx", err);
               })
     }

     checkIsUserAuthorized() {
          return fetch(baseUrl, { credentials: "include", mode: "cors" })
               .then((response) => {
                    logEvent("checkIsUserAuthorized / successful")
                    if (response.status === 200) {
                         this.setState({ auth: true })
                         this.checkClientID()
                    } else this.setState({ auth: false })
               })
               .catch((err) => {
                    console.log("Home.jsx", err);
                    logEvent("checkIsUserAuthorized / failed")
                    this.setState({ auth: false })
               })
     }

     handleClosePopup = () => {
          this.setState({ showClientAccessPopUp: false })
     }

     checkClientID = async () => {
          try {
               const res = await fetch(baseUrl + "/user/accesscode", {
                    credentials: "include",
                    mode: "cors",
                    method: "GET",
               })
               const assementCode = await res.json()
               if (!assementCode || (typeof assementCode === 'object' && Object.keys(assementCode).length === 0)) {
                    logEvent("checkClientID /user/accesscode successful")
                    this.setState({ showClientAccessPopUp: true, hasClient: false })
               } else {
                    this.setState({ hasClient: true })
                    logEvent("checkClientID /user/accesscode failed")
               }
          } catch (err) {
               console.log("Home.jsx", err);
          }

     }

     submitNewsletter = async (e) => {
          e.preventDefault()
          if (!this.state.email) {
               return this.setState({ error: 'Please Enter Email', success: '' })
          }
          const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          if (!emailRegexp.test(this.state.email)) {
               return this.setState({ error: 'Please Enter Valid Email' })
          }
          this.setState({ loading: true, error: '', success: '' });
          try {
               let response = await axios.post(`${baseUrl}/email/newsletter`, { email: this.state.email });
               response = response.data;
               this.setState({ success: response?.message || 'Successfully subscribed to our newsletter', loading: false, error: '', email: '' });
          } catch (error) {
               console.log(error, ' <=== newsletter error...');
               this.setState({ error: error?.response?.data?.message || error.message, loading: false, success: '', email: '' });
          }
     }

     showClientPopUp = () => {
          this.setState({ showClientAccessPopUp: true })
     }

     setshowLoginPopup = (flag) => {
          this.setState({ showLoginPopup: flag })
     }
     closeLoginPopup = () => {
          this.setshowLoginPopup(false)
     }

     render() {
          const { showClientAccessPopUp } = this.state
          const { t, i18n } = this.props
          return (
               <ErrorBoundary FallbackComponent={ErrorScreen}>
                    <div className="HomePG">
                         <DemoClientAccessid
                              i18n={i18n}
                              t={t}
                              show={true}
                              handleClose={this.handleClosePopup}
                              onHide={this.handleClosePopup}
                         />
                         <div className="home-banner-section" style={{ height: '100vh', filter: 'blur(10px)' }}>
                              <div className="container">
                                   {/* <img
              src={require("../../assets/images/banner1.png")}
              alt=""
              style={{ width: "100%" }}
            /> */}
                                   {/* <Header fromHome={true} inProgress={this.state.inProgress} hasClient={this.state.hasClient} showClientPopUp={this.showClientPopUp} /> */}
                                   <div className="fbnIntro-fbnIntro-home">
                                        <p className="homeHeadline wow fadeInUp">
                                             <span> {t("fbnIntro.headline")}</span>
                                        </p>
                                        <p className="homeText">
                                             <span className="dsblText">{t("fbnIntro.description")}</span>
                                        </p>
                                        <button
                                             className="btn homeButtons"
                                        >
                                             {t("fbnIntro.buttonText2")}
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </ErrorBoundary>
          )
     }
}

function NewHome(props) {
     return <Home {...props} />
}

export default withTranslation()(NewHome)