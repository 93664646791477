import React, { useContext, useState, useEffect } from "react"
import "../../css/App.css"
import { DefaultButton } from "../../components/buttons/Button"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { baseUrl } from "../../lib/constants"
import { Element } from "react-scroll"
import _get from "lodash/get"
import axios from "axios"
import { StateContext } from "../../store/state/contexts"
import AssessmentCard from "./AssessmentCard"
import ScrollToID from "./ScrollToCard"
import { logEvent } from "../../index"
import { withTranslation } from "react-i18next"

const options = {
  withCredentials: true,
  mode: "cors",
}

const animatedComponents = makeAnimated()

const getDefaultList = (arr, lang) => {
  if (!arr) {
    return []
  }

  return arr.map((i) => ({
    value: i[lang],
    label: i[lang],
    ...i,
  }))
}

const HealthHistory = ({
  lang,
  data,
  onQuestionIsNotPending,
  progressBarMethod,
  onQuestionIsPending,
  firstUnansweredQuestionId,
  highlightFirstUnansweredQuestion,
  answerHealth,
  t,
  i18n
}) => {
  const { state, dispatch } = useContext(StateContext)
  const language = i18n.language === "en" ? "en" : 'french';
  const [selectedHealthHistoryOptions, setSelectedHealthHistoryOptions] =
    useState(getDefaultList(data.response, lang))
  const [healthHistoryFree_text, setHealthHistoryFree_text] = useState("")
  const [loadSubmitBtn, setLoadSubmitBtn] = useState(false)
  const [openCard, setOpenCard] = useState(false)
  const [openInputs, setOpenInputs] = useState(false)
  useEffect(() => {
    if (data.response && data.response.length) setOpenInputs(true)
  }, [data.response])
  useEffect(() => {
    if (firstUnansweredQuestionId === data.id) {
      setOpenCard(true)
      // ScrollToID(data.id)
    }
  }, [
    firstUnansweredQuestionId,
    highlightFirstUnansweredQuestion,
    // JSON.stringify(state.questions),
  ])

  const yesClick = (e) => {
    e.stopPropagation()
    setOpenCard(true)
    setOpenInputs(true)
  }

  const noClick = (e) => {
    e.stopPropagation()

    onQuestionIsPending()
    setOpenInputs(false)
    setLoadSubmitBtn(true)
    setHealthHistoryFree_text("")
    setSelectedHealthHistoryOptions([])
    var dataPostHealthHistory = {
      id: data.id,
      response: [],
    }
    axios
      .post(
        `${baseUrl}/assessment/health-history`,
        dataPostHealthHistory,
        options
      )
      .then((res) => {
        logEvent("noClick /assessment/health-history successful")
        if (res) {
          progressBarMethod()
        }
        answerHealth([])
        onQuestionIsNotPending()
        setOpenCard(false)
        setOpenInputs(false)
        setLoadSubmitBtn(false)
      })
      .catch(() => {
        logEvent("noClick /assessment/health-history failed")
        alert(
          "Something Went Wrong, Please resubmit the question or check your Internet Connection"
        )
        onQuestionIsNotPending()
        setOpenCard(false)
        setLoadSubmitBtn(false)
      })
  }

  const select = (selectedHealthHistoryOptions) => {
    setSelectedHealthHistoryOptions(selectedHealthHistoryOptions)
  }
  const validateInput = (input) => {
    const alphanumerics =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const specialChars = " -,()"
    const validChars = alphanumerics.concat(specialChars)
    var isEmpty = true
    if (input === "") {
      alert("Please type a custom option in the text box before clicking add.")
      return false
    }
    if (input.length > 45) {
      alert("Custom options can only be 45 characters long.")
      return false
    }
    for (var i of input) {
      if (validChars.indexOf(i) === -1) {
        alert(
          "Please only use letters, numbers, and the following characters: - , ( )"
        )
        return false
      }
      if (alphanumerics.indexOf(i) !== -1) isEmpty = false
    }

    if (isEmpty) {
      alert("Responses must have some alphanumerics.")
      return false
    }
    if (
      selectedHealthHistoryOptions.findIndex((item) => input === item.value) !==
      -1
    ) {
      alert("Option already selected.")
      return false
    }
    return true
  }
  const submit_healthHistoryResponse = () => {
    if (
      selectedHealthHistoryOptions === null ||
      (selectedHealthHistoryOptions.length === 0 &&
        healthHistoryFree_text.length === 0)
    ) {
      alert("Please select options")
      return
    }
    if (healthHistoryFree_text !== "" && !validateInput(healthHistoryFree_text))
      return

    var prepSelectedHealthHistoryOptions = selectedHealthHistoryOptions
      ? selectedHealthHistoryOptions.map((i) => ({ en: i.en, fr: i.fr }))
      : []

    if (healthHistoryFree_text.length !== 0) {
      prepSelectedHealthHistoryOptions.push({
        en: healthHistoryFree_text,
        fr: healthHistoryFree_text,
      })
      addFreeText()
    }

    onQuestionIsPending()

    setLoadSubmitBtn(true)

    axios
      .post(
        `${baseUrl}/assessment/health-history`,
        {
          id: data.id,
          response: prepSelectedHealthHistoryOptions,
        },
        options
      )
      .then((res) => {
        if (res) {
          setLoadSubmitBtn(false)
        }
        answerHealth(prepSelectedHealthHistoryOptions)
        onQuestionIsNotPending()
        progressBarMethod()
        setOpenCard(false)
        setLoadSubmitBtn(false)
      })
      .catch(() => {
        alert(
          "Something Went Wrong, Please resubmit the question or check your Internet Connection"
        )
        onQuestionIsNotPending()
        progressBarMethod()
        setOpenCard(false)
        setLoadSubmitBtn(false)
        setOpenInputs(false)
      })
  }

  const health_history_optionsF = () => {
    return data.options !== undefined
      ? data.options.map((i) => {
        return {
          value: i.text[lang],
          label: i.text[lang],
          ...i.text,
        }
      })
      : null
  }

  const addFreeText = () => {
    if (!validateInput(healthHistoryFree_text)) return
    var healthHistoryFree_array = healthHistoryFree_text
      .split(",")
      .map((s) => s.trim())
    const _selectedHealthHistoryOptions = selectedHealthHistoryOptions.slice()
    for (var i = 0; i < healthHistoryFree_array.length; i++) {
      // _selectedHealthHistoryOptions.push(formatFreeText(healthHistoryFree_text))
      if (healthHistoryFree_array[i] !== "")
        _selectedHealthHistoryOptions.push(
          formatFreeText(healthHistoryFree_array[i])
        )
    }
    setSelectedHealthHistoryOptions(_selectedHealthHistoryOptions)
    setHealthHistoryFree_text("")
  }
  const formatFreeText = (text) => ({
    en: text,
    fr: text,
    value: text,
    label: text,
  })
  const health_history_options2 = health_history_optionsF()

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      addFreeText()
    } else if (e.key === "Escape") {
      setHealthHistoryFree_text("")
    }
  }
  const formattedResponse = () => {
    if (data.response.length === 0) return "None"
    return data.response.map(
      (item, index) => (index === 0 ? "" : ", ") + item[lang]
    )
  }
  const redHighlight =
    firstUnansweredQuestionId === data.id && highlightFirstUnansweredQuestion

  return (
    <AssessmentCard
      question={data[lang].text}
      answer={data.isAnswered ? formattedResponse() : ""}
      answered={data.isAnswered}
      open={openCard}
      setOpen={setOpenCard}
      redHighlight={redHighlight}
      loading={loadSubmitBtn}
      id={data.id}
    >
      <DefaultButton
        bgColor={openInputs ? "var(--green)" : "var(--white_300)"}
        btnText={t("healthHistory.yes")}
        onClick={yesClick}
      />
      <DefaultButton
        bgColor={
          !openInputs && data.response ? "var(--green)" : "var(--white_300)"
        }
        btnText={t("healthHistory.no")}
        onClick={noClick}
      />
      {openInputs && (
        <div style={{ marginTop: "1.5rem" }}>
          <Select
            // components={animatedComponents}
            isMulti
            options={health_history_options2}
            value={selectedHealthHistoryOptions}
            onChange={select}
            closeMenuOnSelect={true}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              onKeyDown={onKeyDown}
              type="text"
              className="form-control mt-2 mb-2"
              placeholder={t('followUp.otherPleaseType')}
              name="healthHistoryFree_text"
              value={healthHistoryFree_text}
              onChange={(e) => {
                setHealthHistoryFree_text(e.target.value)
              }}
            />
            <button
              className="btn mt-2 mb-2 ml-1"
              style={{ backgroundColor: "var(--green)", color: "white" }}
              onClick={addFreeText}
            >
              {t("healthHistory.addButton")}
            </button>
          </div>

          <button
            className="btn SubmitAnswerBtn"
            style={(language === "french") ? { width: "165px" } : {}}
            onClick={submit_healthHistoryResponse}
          >
            {t("healthHistory.submit")}
          </button>
        </div>
      )}
    </AssessmentCard>
  )
}

const HealthHistoryModule = (props) => {
  const { state } = useContext(StateContext)
  let hh = null
  try {
    hh = state.questions[props.id]
  } catch (err) {
    console.log({ err })
  }
  return (
    <HealthHistory
      firstUnansweredQuestionId={
        state.firstUnansweredQuestion && state.firstUnansweredQuestion.id
      }
      {...props}
      data={hh}
      answerIsPending={state.answerIsPending}
      key={JSON.stringify({ id: props.id })}
    />
  )
}

export default withTranslation()(HealthHistoryModule);
