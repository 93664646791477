import React from "react"
import {
  SharedSummaryResponsesList,
  SharedSummaryResponsesBlock,
  SharedSummaryProgressBar,
  SharedSummaryProgressBarInnerLine,
} from "../../utils/shared"
import { withTranslation } from "react-i18next"

const SummaryLifeFunctionsEle = ({ life_functions_responses, lang, t }) => (
  <SharedSummaryResponsesList>
    {life_functions_responses
      .map((i) => {
        if (i.id === "lf_2") {
          return {
            ...i,
            percentResponse: Math.trunc((i.response / 60) * 100),
          }
        }
        return { ...i, percentResponse: i.response }
      })
      .map((i) => {
        return (
          <SharedSummaryResponsesBlock key={`${i.question[lang].text}sumlf`}>
            <h3>{i.question[lang].text}</h3>

            {i.question.question_type === "disablity" && (
              <p>
                {i.response !== -1 && i.response.text[lang]}
                {i.response === -1 && <>{t("papPage.none")}</>}
              </p>
            )}

            {i.question.question_type !== "disablity" && (
              <>
                {i.response !== -1 && (
                  <>
                    <SharedSummaryProgressBar>
                      <SharedSummaryProgressBarInnerLine
                        width={i.percentResponse}
                      />
                    </SharedSummaryProgressBar>

                    {i.id === "lf_2" && (
                      <p className="pr-2">
                        {i.response && i.response === -1
                          ? `0 ${t("papPage.days")}`
                          : `${i.response} ${t("papPage.days")}`}
                      </p>
                    )}

                    {i.id !== "lf_2" && (
                      <p className="pr-2">
                        {i.response && i.response === -1
                          ? "0%"
                          : i.percentResponse + "%"}
                      </p>
                    )}
                  </>
                )}

                {i.response === -1 && <>{t("papPage.none")}</>}
              </>
            )}
          </SharedSummaryResponsesBlock>
        )
      })}
  </SharedSummaryResponsesList>
)

export const SummaryLifeFunctions = withTranslation()(SummaryLifeFunctionsEle);