import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";

function LoadingScreen({
  loadingIn,
  loadingOut,
  setLoadingOut,
  minTime,
  t
}) {
  const [timerDone, setTimerDone] = useState(false);
  useEffect(() => {
    if (loadingIn && !timerDone) {
      setLoadingOut(true);
      setTimeout(() => {
        setTimerDone(true);
        console.log("timer done");
      }, minTime);
    } else if (timerDone && !loadingIn) {
      setTimerDone(false);
      setLoadingOut(false);
    }
  }, [loadingIn, timerDone]);

  return (
    <div>
      {loadingOut && (
        <div className="text-center my-5">
          <div className="spinner-border assessmentSpinner" role="status"></div>
          <span className="h1 ml-2">{t('summaryPage.loading')}</span>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(LoadingScreen)