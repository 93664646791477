import React, { useContext, useEffect, useState } from "react"
import LifeFunction from "../LifeFunction"
import LifeFunctionOptions from "../LifeFunctionOptions"
import { StateContext } from "../../../store/state/contexts"

export default function LifeFunctionsTab(props) {
  const { state, answerLife } = useContext(StateContext)

  const [questions,setQuestions] = useState([])
  useEffect(()=> {
    try {
      let _questions = state.questionsToShow.questions.life_functions.questions
      setQuestions(_questions)
    } catch (err) {}
  },[JSON.stringify(state.questionsToShow)])

  return (
    <div>
      {questions.map((i,index) => {
          console.log(i)
        if (i.options) {
          return (
            <LifeFunctionOptions
              answerLife={(choice)=>answerLife(index,choice)}
              key={i.id}
              id={i.id}
              lang={props.lang}
              progressBarMethod={props.progressBarMethod}
              onFetchAttempt={props.onFetchAttempt}
              onQuestionIsNotPending={props.onQuestionIsNotPending}
              onQuestionIsPending={props.onQuestionIsPending}
            />
          )
        } else {
          return (
            <LifeFunction
              answerLife={(choice)=>answerLife(index,choice)}
              key={i.id}
              id={i.id}
              lang={props.lang}
              progressBarMethod={props.progressBarMethod}
              onFetchAttempt={props.onFetchAttempt}
              onQuestionIsNotPending={props.onQuestionIsNotPending}
              onQuestionIsPending={props.onQuestionIsPending}
            />
          )
        }
      })}
    </div>
  )
}
