import React, { Component } from "react"
import Masonry from "react-masonry-css"

import { baseUrl } from "../../lib/constants"
import "../../css/SupportResources.css"
import { checkdevice } from "../../utils/checkdevice"
import Navbar from "../../components/navbar/Navbar"
import "../../css/main.css"
import Footer from "../../components/footer/Footer"
import { logEvent } from "../../index"

var store = require("store")

export default class SupportResources extends Component {
  constructor(props) {
    super(props)
    this.state = {
      auth: true,
      supportResourcesData: [],
      lang: store.get("lang") ? store.get("lang") : "en",
    }
  }
  componentDidMount() {
    checkdevice(this.props)
    console.log("props ", this.props)
    console.log("req ", this)
    this.CheckIsAuthenticate()
    if (this.props.match.params.assessmentId) {
      this.getSupportResources()
    } else {
      this.getCovidSupportResources()
    }
  }

  getSupportResources() {
    fetch(
      baseUrl + `/support-resources/${this.props.match.params.assessmentId}`,
      {
        credentials: "include",
        mode: "cors",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => (response.status == 200 ? response.json() : []))
      .then((data) => {
        logEvent("getSupportResources /support-resources successful")
        this.setState({ supportResourcesData: data })
      })
      .catch((err) => {
        logEvent("getSupportResources /support-resources failed")
        console.log(err)
      })
  }
  getCovidSupportResources() {
    fetch(
      baseUrl + `/support-resources/COVID`,
      // "http://localhost:5000/api/v1" + `/support-resources/COVID`,
      {
        credentials: "include",
        mode: "cors",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => (response.status == 200 ? response.json() : []))
      .then((data) => {
        logEvent("getCovidSupportResources /support-resources/COVID successful")
        this.setState({ supportResourcesData: data })
      })
      .catch((err) => {
        logEvent(
          "getCovidSupportResources /support-resources/COVID failed"
        )
        console.log(err)
      })
  }
  changeLanguage = () => {
    this.props.i18n.changeLanguage(this.state.lang === "en" ? "fr" : "en")
    this.setState({ lang: this.state.lang === "en" ? "fr" : "en" })
    store.set("lang", this.state.lang === "en" ? "fr" : "en")
  }

  CheckIsAuthenticate() {
    fetch(baseUrl, {
      credentials: "include",
      mode: "cors",
    })
      .then((response) => {
        logEvent("CheckIsAuthenticate / successful")
        if (response.status !== 200) {
          this.props.history.push("/home")
          this.setState({ auth: false })
        }
      })
      .catch((err) => {
        console.log(err);
        logEvent("CheckIsAuthenticate / failed")
        this.setState({ auth: false })
        alert("You seem like unauthorised, Please Login")
        this.props.history.push("/home")
      })
  }

  breakPointsColumnsObj = {
    default: 2,
    1200: 1,
    992: 1,
    768: 1,
  }

  render() {
    const props = this.props
  console.log(this.state.lang)

    return (
      <div className="bgImage">
        {props.match.params.assessmentId ? (
          <Navbar
            auth={this.state.auth}
            params={props.match.params.assessmentId}
            changeLanguage={this.changeLanguage}
            history={props.history}
          />
        ) : (
          <Navbar
            auth={this.state.auth}
            params="COVID"
            history={props.history}
            changeLanguage={this.changeLanguage}
          />
        )}
        <div className="main-container">
          <div className="supportResourcesCard">
            <div className="card mb-5 radius">
              <div className="container-fluid">
                <h1 className="text-center mb-5 mt-5">Recommended Resources</h1>
                <div className="row">
                  <div className="col-md-12">
                    <div className="container">
                      <Masonry
                        breakpointCols={this.breakPointsColumnsObj}
                        className="masonry-grid"
                        columnClassName="masonry-grid_column"
                      >
                        {this.state.supportResourcesData?.map((sr, i) => {
                          return (
                            <div key={i} className="row">
                              <div className="col-md-12 px-0">
                                <div className="card rounded-sm overflow-hidden p-3">
                                  <h5>
                                    {!!sr.title ? sr.title : sr["title-french"]}
                                  </h5>
                                  <p style={{ fontSize: 14 }}>
                                    {!!sr.description
                                      ? sr.description
                                      : sr["description-french"]}
                                  </p>
                                  <div>
                                    {(!!sr["number-address"] ||
                                      !!sr.person) && (
                                      <p style={{ fontSize: 14 }}>
                                        <i className="fal fa-phone-alt pr-2"></i>
                                        {/* <span>
                                          {!!sr["number-address"]
                                            ? sr["number-address"].number
                                            : sr.person.phone}
                                        </span> */}
                                        <span>
                                          {!!sr["number-address"] ? (
                                            <a
                                              href={
                                                "tel:" +
                                                sr["number-address"].number
                                              }
                                              target="_blank"
                                            >
                                              {sr["number-address"].number}
                                            </a>
                                          ) : (
                                            <a
                                              href={"tel:" + sr.person.phone}
                                              target="_blank"
                                            >
                                              {sr.person.phone}
                                            </a>
                                          )}
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                  {sr.website && (
                                    <p style={{ fontSize: 14 }}>
                                      <i className="fal fa-globe pr-2"></i>
                                      {/* <span>{sr.website}</span> */}
                                      <a href={sr.website} target="_blank">
                                        {sr.website}
                                      </a>
                                    </p>
                                  )}

                                  {(!!sr.fax || !!sr["number-address-2"]) && (
                                    <p style={{ fontSize: 14 }}>
                                      <i className="fal fa-fax pr-2"></i>
                                      <span>
                                        {!!sr.fax
                                          ? sr.fax
                                          : sr["number-address-2"].number}
                                      </span>
                                    </p>
                                  )}

                                  {(!!sr.email || !!sr.person) && (
                                    <p style={{ fontSize: 14 }}>
                                      <i className="fal fa-envelope pr-2"></i>
                                      {/* <span>
                                        {!!sr.email
                                          ? sr.email
                                          : sr.person.email}
                                      </span> */}
                                      <span>
                                        {!!sr.email ? (
                                          <a
                                            href={"mailto:" + sr.email}
                                            target="_blank"
                                          >
                                            {sr.email}
                                          </a>
                                        ) : (
                                          <a
                                            href={"mailto:" + sr.person.email}
                                            target="_blank"
                                          >
                                            {sr.person.email}
                                          </a>
                                        )}
                                      </span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Masonry>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
