import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { useBoolean } from "react-hookedup"
import { useInput, useFocus } from "react-hookedup"
import { CompaniesDropDown } from "../../dropdown/CompaniesDropDown"
import * as JsSearch from "js-search"
import OutsideClickHandler from "react-outside-click-handler"
import { SupportedOrganizations } from "../../../lib/constants"
import { stemmer } from "porter-stemmer"
import BuildingSVG from "../../../assets/images/building.svg"
import _get from "lodash/get"

const EInput = styled.input`
  display: flex;
  align-items: center;
  color: #495057;
  background-color: transparent;
  z-index: 111;
  background: white;
  width: 100%;
  letter-spacing: 1px;
  border: 0;
  height: 2.64em;
  outline: none;
  padding-left: 2.4em;
  font-size: 1.2em;
  border-radius: 0.4em;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: #c8c8c8;
  }
`

const EInputWrapper = styled.div`
  border: 1px solid #d1d1d1;
  border-radius: 0.4em;
  overflow: hidden;
`

const a = new JsSearch.Search("name")

a.tokenizer = new JsSearch.StemmingTokenizer(
  stemmer,
  new JsSearch.SimpleTokenizer()
)
a.addIndex("name")

const SupportedOrganizationsPrep = SupportedOrganizations.map((i) => ({
  name: i,
}))

export const ClientAccessIdCompany = ({ setCompanyName }) => {
  const [myJsSearch] = useState(a)

  const [availableCompanies, setAvailableCompanies] = useState([])
  const [allCompaniesList] = useState(SupportedOrganizationsPrep)

  const [company, setCompany] = useState(null)
  const companyInput = useInput("")
  const companiesDDOpen = useBoolean(false)
  const inputFocus = useFocus()

  useEffect(() => {
    handleCompanyClick(company)
  }, [JSON.stringify(company)])

  useEffect(() => {
    myJsSearch.addDocuments(allCompaniesList)
  }, [allCompaniesList])

  useEffect(() => {
    setAvailableCompanies(allCompaniesList)

    if (!!company) {
      handleCompanyClick(company)
    }
  }, [])

  const handleChange = (e) => {
    companyInput.onChange(e)

    const res = myJsSearch.search(e.target.value)

    if (e.target.value.length !== 0) {
      setAvailableCompanies(res)
    } else {
      setAvailableCompanies(allCompaniesList)
    }
  }

  const handleCompanyClick = (country) => {
    companyInput.setValue("")
    companiesDDOpen.setFalse()
    setCompany(country)
    setCompanyName(_get(country, "name"))
  }

  const handleInputClick = () => {
    setAvailableCompanies(allCompaniesList)
    companyInput.clear()
    companiesDDOpen.setTrue()
  }

  const inputValue = company
    ? inputFocus.focused
      ? companyInput.value
      : company.name
    : companyInput.value

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          companiesDDOpen.setFalse()
          companyInput.clear()
        }}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          <EInputWrapper>
            <img
              src={BuildingSVG}
              css={css`
                width: 1.3em;
                justify-self: center;
                position: absolute;
                left: 0.7em;
                font-size: 1.1em;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 1;
              `}
            />

            <EInput
              value={inputValue}
              onChange={handleChange}
              onClick={handleInputClick}
              onFocus={inputFocus.bind.onFocus}
              onBlur={inputFocus.bind.onBlur}
              placeholder="Company"
            />
          </EInputWrapper>
        </div>
        {companiesDDOpen && companiesDDOpen.value && (
          <CompaniesDropDown
            open={companiesDDOpen.value}
            onCompanyClick={handleCompanyClick}
            availableCompanies={availableCompanies}
          />
        )}
      </OutsideClickHandler>
    </div>
  )
}
