import React, { useContext, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import OutsideClickHandler from "react-outside-click-handler"
import { withTranslation } from "react-i18next"

export const EButton = styled.button`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  color: ${(props) => (props.selected ? "#383940" : "#afafb1")};
  font-weight: 400;
  min-height: 2.7em;
  outline: 0;
  padding: 0 1em;
  position: relative;
  text-align: initial;
  transition: all 0.2s;
  width: 100%;
  :focus {
    outline: 0;
  }
`

export const EBtnElement = styled.button`
  background-color: ${(props) => (props.selected ? "#e4e4e4" : "#fff")};
  border-radius: 3px;
  border: 0;
  color: #383940;
  font-weight: 400;
  min-height: 2.7em;
  outline: 0;
  padding: 0 1em;
  position: relative;
  text-align: initial;
  transition: all 0.2s;
  width: 100%;
  :hover {
    background-color: ${(props) =>
      props.selected ? "#e4e4e4" : "rgb(241, 241, 241)"};
  }
  :focus {
    outline: 0;
  }
`

export const EAngle = styled.div`
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid #b7b7b7;
  bottom: 0px;
  display: block;
  height: 0px;
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  right: 1em;
  top: 0px;
  transform: ${(props) => (props.isOpen ? "rotate(-180deg)" : "rotate(0deg)")};
  transition: all 0.2s;
  width: 0px;
`

export const EDropDownWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #d1d1d1;
  box-shadow: rgb(0 0 0 / 15%) -10px 10px 25px;
  left: -1px;
  max-height: 12em;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: calc(100% + 1px);
  z-index: 10;
`

const DropDownInputModule = ({
  list,
  onChange,
  t,
  defaultPlaceholder = "Please select one",
}) => {
  const [isOpen, setOpen] = useState(false)
  const tranlationObject = {
    "Male": "dropDownItems.male",
    "Female":"dropDownItems.female",
    "Transgender":"dropDownItems.transgender",
    "Non-binary":"dropDownItems.nonBinary",
    "Two-spirited": "dropDownItems.twoSpirited",
    "Prefer not to say": "dropDownItems.preferNotToSay",
    "Nunavut":"dropDownItems.nunavut",
    "Quebec":"dropDownItems.quebec",
    "Northwest Territories":"dropDownItems.northwestTerritories",
    "Ontario":"dropDownItems.ontario",
    "British Columbia":"dropDownItems.britishColumbia",
    "Alberta":"dropDownItems.alberta",
    "Saskatchewan":"dropDownItems.saskatchewan",
    "Manitoba":"dropDownItems.manitoba",
    "Yukon":"dropDownItems.yukon",
    "Newfoundland and Labrador":"dropDownItems.newfoundlandAndLabrador",
    "New Brunswick":"dropDownItems.newBrunswick",
    "Nova Scotia":"dropDownItems.novaScotia",
    "Prince Edward Island":"dropDownItems.princeEdwardIsland"
  }

  const handleClick = (value) => {
    onChange(
      list.map((i) => ({ ...i, selected: i.value === value ? true : false }))
    )
  }

  const selectedStr =
    list.find((i) => !!i.selected) && list.find((i) => !!i.selected).caption

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpen(false)
      }}
    >
      <EButton
        onClick={() => {
          setOpen(!isOpen)
        }}
        selected={!!selectedStr}
      >
        <EAngle isOpen={isOpen} />

        {selectedStr ? selectedStr : defaultPlaceholder}
        {isOpen && (
          <EDropDownWrapper>
            {list.map((i) => (
              <EBtnElement
                key={`${i.caption}btnkey`}
                onClick={(e) => {
                  e.stopPropagation()
                  handleClick(i.value)
                  setOpen(false)
                }}
                selected={i.selected}
              >
                {tranlationObject[i.caption] ? t(tranlationObject[i.caption]) : i.caption}
              </EBtnElement>
            ))}
          </EDropDownWrapper>
        )}
      </EButton>
    </OutsideClickHandler>
  )
}
export const DropDownInput = withTranslation()(DropDownInputModule); 
