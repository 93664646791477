import React, { useContext, useEffect, useState } from "react"
import HealthHistory from "../HealthHistory"
import { StateContext } from "../../../store/state/contexts"

export default function HealthHistoryTab(props) {
  const { state, answerHealth } = useContext(StateContext)

  const [questions,setQuestions] = useState([])
  useEffect(()=> {
    try {
      let _questions = state.questionsToShow.questions.health_history.questions
      setQuestions(_questions)
    } catch (err) {}
  },[JSON.stringify(state.questionsToShow)])

  return (
    <div>
      {questions.map((data, index) => (
        <HealthHistory
          answerHealth={(choice)=>answerHealth(index,choice)}
          key={data.id}
          id={data.id}
          index={index + 1}
          lang={props.lang}
          progressBarMethod={props.progressBarMethod} 
          onQuestionIsNotPending={props.onQuestionIsNotPending}
          onQuestionIsPending={props.onQuestionIsPending}
          firstUnansweredQuestion={state.firstUnansweredQuestion}
          highlightFirstUnansweredQuestion={
            state.highlightFirstUnansweredQuestion
          }
        />
      ))}
    </div>
  )
}
